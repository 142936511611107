import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner, Form } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons"
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "../../Common/Skeleton";
import { getAllCategories } from '../../Common/Categories';
import Pagination from '../../Common/Pagination';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import ClearData from '../../Common/ClearData';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey, setImgVerifyReport } from '../../Common/ActiveKeySlice';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';

const Category = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);

    const [loader, setLoader] = useState(false)
    const [listLoader, setListLoader] = useState(false)
    const [error, setErrors] = useState({});
    const [isdelete, setIsDelete] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);

    const [deleteId, setDeleteId] = useState("");
    const [category, setCotegory] = useState("")
    const [searchField, setSearchField] = useState("");

    const [getAllCategory, setGetAllCategory] = useState([])

    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    const categoryTypes = [
        {
            label: "Course",
            value: "course",
        },
        {
            label: "Event",
            value: "event",
        },
        {
            label: "Product",
            value: "product",
        },
        {
            label: "Feed",
            value: "feed",
        },
        {
            label: "Article",
            value: "article",
        },
        {
            label: "Blog",
            value: "blog",
        },
        {
            label: "Video",
            value: "video",
        },
        {
            label: "Recipe",
            value: "recipe",
        },
    ]


    useEffect(() => {
        getCategory()
    }, [docPerPage, pageNo])

    const getCategory = async () => {
        setListLoader(true)
        const payload = {
            categoryType: category ? category : "",
            documentsPerPage: docPerPage,
            page: pageNo,
            title: searchField ? searchField : ""
        }
        await fetch(process.env.REACT_APP_BASE_URL + "/cms/getallcategory/filter", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.message === "Authorization failed / Forbidden") {
                    setListLoader(true);
                    dispatch(setLogout(null));
                    dispatch(setActiveKey(null));
                    dispatch(setImgVerifyReport(null));
                    localStorage.removeItem('persist:root');
                    navigate('/');
                } else {
                    setGetAllCategory(response?.data)
                    setNoOfPages(response?.data?.noOfPages);
                    setListLoader(false)
                }

            })
            .catch((error) => {
                setListLoader(false)
                console.log("error", error);
            })
    }

    // for custome hook call
    const dependencies = [category ? category : "", searchField ? searchField : ""];
    useDebouncedApiCall(getCategory, dependencies);

    const deleteCategory = async () => {
        const payload = {
            categoryId: deleteId
        }
        await fetch(process.env.REACT_APP_BASE_URL + "/category/deleteCat", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.message === "Data deleted succussfully") {
                    toast.error("Category deleted successfully.")
                    setIsDelete(false)
                    getCategory()
                }
            }).catch((error) => {
                console.log("error", error);
            })
    }

    return (
        <>
            <div className="outletPadding">
                <Container>
                    <Row className="d-flex justify-content-between">
                        <Col md={4} className='mb-3 mb-md-0'>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search here"
                                name="search"
                                value={searchField}
                                onChange={(e) => {
                                    if (e.target.value.trim()) {
                                        setSearchField(e.target.value)
                                        setPageNo(1);
                                    } else if (e.target.value.length === 0) {
                                        setSearchField(e.target.value)
                                        setPageNo(1);
                                    }
                                }}
                            />
                        </Col>

                        <Col md={4} className="d-flex justify-content-end align-items-center">
                            <div className='d-flex flex-row'>
                                <Form.Group className="w-100 me-4">
                                    <Form.Select
                                        value={category}
                                        onChange={(e) => { setCotegory(e.target.value) }}>
                                        <option value="" selected>All Category</option>
                                        {categoryTypes.map((itm) => (
                                            <option key={itm.value} value={itm.value}>{itm.label}</option>
                                        ))}
                                    </Form.Select>
                                    {error.categoryType && <div className="errMsg">{error.categoryType}</div>}
                                </Form.Group>

                                <Button className="primaryBtn w-100 my-auto" onClick={() => navigate("/dashboard/add-category")}>
                                    <Icon.PlusCircle className="me-2" size={15} />
                                    Add Category
                                </Button>
                            </div>
                        </Col>

                    </Row>

                    {/* category List Table */}
                    <div className="outer-wrapper mx-auto mt-4">
                        <div className="table-wrapper" style={{ maxHeight: "68vh" }}>
                            <table>
                                <thead>
                                    <th>Sr. No.</th>
                                    <th>Category Icon</th>
                                    <th>Category Name</th>
                                    <th>Category Type</th>
                                    <th>Action</th>
                                </thead>
                                {listLoader ? (
                                    <Skeleton rows={10} cols={5} />
                                ) : getAllCategory?.data?.length > 0 && getAllCategory?.data !== null && getAllCategory?.data !== undefined ? (
                                    getAllCategory?.data?.map((itm, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                                                <td><img src={itm?.iconURL} alt="Category Icon" width={40} /></td>
                                                <td style={{ width: "18vw" }}>{itm?.categoryName}</td>
                                                <td>{itm?.categoryType}</td>
                                                <td className="d-flex">
                                                    <img src={View} alt="View" className="icon me-3"
                                                        onClick={() => navigate("/dashboard/view-category", {
                                                            state: { category: itm }
                                                        })
                                                        } />

                                                    <img src={Edit} alt="Edit" className="icon me-3"
                                                        onClick={() => navigate("/dashboard/edit-category", { state: { item: itm } })}
                                                    />

                                                    <img src={Delete} alt="Delete" className='icon'
                                                        onClick={() => {
                                                            setIsDelete(true);
                                                            setDeleteId(itm?.categoryId);
                                                        }} />
                                                </td>

                                            </tr>
                                        );
                                    })
                                ) : (
                                    <p className="noDataFound">No Data Found</p>
                                )}
                            </table>
                        </div>
                    </div>

                    <Row>
                        <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                            <h6 className='text-start mb-0'>
                                Total - <strong>{getAllCategory?.count}</strong>
                            </h6>
                        </Col>
                        <Col md={8} className='d-flex justify-content-end'>
                            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                                onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
                        </Col>
                    </Row>
                </Container>

                {/* Delete Article */}
                <Modal
                    size="md"
                    show={isdelete}
                    onHide={() => setIsDelete(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        {" "}
                        <h5 className="mb-0">
                            <Icon.TrashFill size={20} className="me-2" />
                            Delete Category
                        </h5>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <div data-aos='zoom-in'>
                            <Icon.XCircleFill color='red' width={80} height={80} />
                            <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this category ?</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            apiLoader ? <Spinner variant='primary' /> :
                                <>
                                    <Button className='primaryBtn me-3' onClick={() => deleteCategory()}>
                                        <Icon.HandThumbsUp className='me-1' />Yes</Button>
                                    <Button className='secondaryBtn' onClick={() => setIsDelete(false)}>
                                        <Icon.HandThumbsDown className='me-1' />No</Button>
                                </>
                        }

                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Category
