import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Navigate } from "react-router-dom";

const PrivateRoutes = ({ children }) => {
    const userState = useSelector(state => state?.cmsLogin?.userData?.data);
    return userState ? children : <Navigate to="/" />;
};

export default PrivateRoutes;
