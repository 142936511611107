import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryList: []
}

export const getAllCategories = createAsyncThunk("getAllCategoriesPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getallcategory/filter', {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${data.token}` },
        body: JSON.stringify(data.payload)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

const getAllCategoriesSlice = createSlice({
    name: 'allCategories',
    initialState,
    extraReducers: {
        [getAllCategories.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllCategories.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllCategories.fulfilled]: (s, a) => {
            s.categoryList = a.payload;
            s.loading = 'success';
        },

    },
});

export default getAllCategoriesSlice.reducer;