import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Modal, Button, Card, Form } from 'react-bootstrap';
import { ReactCrop, makeAspectCrop, centerCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import S3 from "react-aws-s3";
import imageCompression from "browser-image-compression";;
window.Buffer = window.Buffer || require("buffer").Buffer;


const ReactS3Ultra = ({ fileEvent, fileType, directory, onFileUpload, type }) => {

  console.log("type", type);

  const [show, setShow] = useState(false);

  const [selectedImg, setSelectedImg] = useState('');
  const [crop, setCrop] = useState('');
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgBlob, setImgBlob] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState('');
  const [cropKey, setCropKey] = useState(Date.now());



  const config = {
    bucketName: process.env.REACT_APP_BUCKET,
    dirName: directory,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEY,
    secretAccessKey: process.env.REACT_APP_SECRETKEY,
  };

  //---- Setting up aws S3 Client -----------
  const ReactS3Client = new S3(config);

  useEffect(() => {
    console.log("type", type);
    handleImgEvent();
    if (type === "bannerImages") {
      setAspectRatio(2 / 1);
    }
    else if(type === "courseBigImage"){
      setAspectRatio(4 / 3);
    }
    else {
      setAspectRatio(1 / 1);
    }
  }, [fileEvent])


  useEffect(() => {
    setCropKey(prevKey => prevKey + Date.now());
  }, [aspectRatio])

  const handleImgEvent = async () => {

    setImgBlob(null);
    const file = fileEvent?.target?.files[0];

    try {

      let S3Link;
      // Checking uploading right file ------------- 
      if (file.type.split("/")[0] !== fileType) {
        toast.error(`Please select valid ${fileType} file`, { toastId: '1234567890' });
      }

      // ------- Check file is video ot not --------------
      else if (file?.type === "video/mp4") {

        let size = 3;
        let kb = 1024;
        let mb = kb * 1024;
        let fileSize = file.size / (1024 * 1024);
        // if (fileSize < 3) {
        let fileNameWithExt = file.name.replace(/ /g, '_');
        let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();

        await ReactS3Client.uploadFile(file, newFileName)
          .then((res) => {
            if (res.status === 204) {
              S3Link = res?.location;
            } else {
              console.log("AWS Error");
            }
          })
          .catch((err) => {
            console.error("Err while uploading img on S3", err);
          });
        // } else {
        //   toast.error("Please Upload Video size less than 3 mb", {toastId:'12345678'});
        // }
      }

      //--------- Else crop and upload image ---------------
      else if (file.type.split("/")[0] === "image") {
        const reader = new FileReader();
        reader.onload = (fileEvent) => {
          setSelectedImg(fileEvent.target.result);
        };
        reader.onerror = (err) => {
          console.log(`Error reading image: ${err.message}`);
          toast.error('Err while reading image');
        };
        reader.readAsDataURL(file);
        setShow(true);
      }

      //----------------- Else uploadDocs Like PDF, Word, Txt ------------------------
      else {
        let size = 3;
        let kb = 1024;
        let mb = kb * 1024;
        if (size < mb * 3) {
          let fileNameWithExt = file.name.replace(/ /g, '_');
          let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();

          await ReactS3Client.uploadFile(file, newFileName)
            .then((res) => {
              if (res.status === 204) {
                S3Link = res?.location;
                toast.success("Document Uploded successfully", { toastId: '123456987' });
              } else {
                console.log("AWS Error while uploading document");
                toast.error("Error while uploading, Please try again");
              }
            })
            .catch((err) => {
              console.error("Err while uploading img on S3", err);
            });
        } else {
          toast.error("Upload document less than 3 MB");
        }
      }
      onFileUpload(S3Link);
    } catch (error) {
      throw error;
    }
  }


  function onImageload(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const cropWidthInPercent = (150 / width) * 100;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: cropWidthInPercent,
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop);
  }


  const setCanvasPreview = (image, canvas, crop) => {
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error("No 2d Context");
    }
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
    canvas.toBlob(blob => {
      setImgBlob(blob)
    }, 'image/png');
    // handleSaveImage(canvas);
  }


  // ------ Upload Image ----------------------------------
  const handleUpload = async () => {
    setUploading(true);
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imgBlob, options);
      let fileNameWithExt = fileEvent.target.files[0].name.replace(/ /g, '_');
      let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();

      await ReactS3Client.uploadFile(compressedFile, newFileName)
        .then((res) => {
          if (res.status === 204) {
            onFileUpload(res?.location)
          } else {
            console.log("AWS Error");
          }
        })
        .catch((err) => {
          console.error("Err while uploading img on S3", err);
        });
      toast.success('File uploaded successfully', { toastId: Date.now() });
      setShow(false)
    } catch (error) {
      toast.error('Error uploading file, Please try again');
    } finally {
      setUploading(false);
    }
  };


  return (
    <Modal show={show} size='lg' keyboard={false} backdrop={false} centered onHide={() => setShow(false)} >
      <Modal.Header closeButton>
        <Modal.Title> <h4 className='mb-0'>
          <Icon.CloudUpload size={20} className='me-2' />React S3 Ultra Cropper & Uploader</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={6}>

            <Form.Select disabled={type === "bannerImages" || type==="courseBigImage"}
              className='w-75 mb-3' value={aspectRatio} onChange={(e) => setAspectRatio(e.target.value)}>
              <option value={1 / 1}>1/1</option>
              <option value={16 / 9}>16/9</option>
              <option value={2 / 1}>2/1</option>
              <option value={4 / 3}>4/3</option>
              <option value="">Free Crop</option>

            </Form.Select>

            <h6>Crop image here</h6>
            <ReactCrop key={cropKey} crop={crop} aspect={aspectRatio} minWidth={150}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}>
              <img
                src={selectedImg}
                ref={imgRef}
                alt="Crop Image"
                onLoad={onImageload}
                style={{ width: '300px' }} />
            </ReactCrop>
          </Col>

          <Col md={6}>
            <h6>Cropped Image</h6>
            <canvas ref={previewCanvasRef}
              className='m-auto' style={{
                border: "1px solid black",
                objectFit: "contain",
                width: 300,
                height: 300
              }} />
          </Col>

          <Col md={12} className='text-center mt-2'>
            <Button id="uploadBtn" onClick={() => {
              setCanvasPreview(
                imgRef.current, previewCanvasRef.current,
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                ))
            }}>
              <Icon.Crop className='me-2' />click here to crop
            </Button>
          </Col>
        </Row>
      </Modal.Body>


      <Modal.Footer>
        {uploading ? 'Uploading...' :
          <Button className='primaryBtn' disabled={imgBlob === null ? true : false} onClick={handleUpload}>
            <Icon.CloudArrowUp className='me-2' size={20} />Upload Image
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default ReactS3Ultra