import React, { useEffect, useState } from "react";
import { Container, Row, Button, Spinner, Col, Card, ButtonGroup, ToggleButton, } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import moment from "moment";

const ViewTagList = () => {
    const location = useLocation();
    const tagData = location.state.tagData;
    const navigate = useNavigate()

    return (
        <div className="outletPadding">
            <Container>
                <Button className="backBtn" onClick={() => navigate(-1)}>
                    <Icon.ArrowLeft className="me-2" />
                    Back
                </Button>
                <Row className="mb-2 mt-4">
                    <h4 className="mb-0 text-center">
                        <Icon.Person className="me-1" /> Tags Details
                    </h4>
                </Row>
                <Row className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Tag Name
                                    </th>
                                    <td>{tagData?.tag ? tagData?.tag : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        TagCategoryName
                                    </th>
                                    <td>{tagData?.tagCategoryName ? tagData?.tagCategoryName : "-"}</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ViewTagList;
