import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import Select from 'react-select';
import ReactS3 from '../../../Utils/ReactS3';
import ReactQuill from 'react-quill';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../Common/Categories';
import { useNavigate, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import imageUploading from '../../../Assets/ImageUploading.json';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';


const EditShoppee = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);
  const productCategories = useSelector(state => state?.categories?.categoryList?.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state;


  const [editLoader, setEditLoader] = useState(false);
  const [name, setName] = useState(product.name);
  const [img, setImg] = useState(product.imageURL.map((image) => { return image }));
  const [marketPlaceURL, setMarketPlaceURL] = useState(product.marketPlaceURL);
  const [category, setCategory] = useState({ label: product.category, value: product.category });
  const [productType, setProductType] = useState({ label: product.productType, value: product.productType });
  const [tagCategory, setTagCategory] = useState(product?.tagsCategory?.map((itm) => ({
    label: itm,
    value: itm
  }))) || [];
  const [tags, setTags] = useState(
    product?.tags?.map((tag) => ({ label: tag, value: tag })) || []
  );
  const [descriptionEn, setDescriptionEn] = useState(product.descriptionLang.en);
  const [descriptionMr, setDescriptionMr] = useState(product.descriptionLang?.mr);
  const [descriptionHn, setDescriptionHn] = useState(product.descriptionLang?.hin);
  const [shortdescription, setShortDescription] = useState(product?.shortDescriptionLang?.en);
  const [shortdescriptionMr, setShortDescriptionMr] = useState(product?.shortDescriptionLang?.mr);
  const [shortdescriptionHn, setShortDescriptionHn] = useState(product?.shortDescriptionLang?.hn);
  const [bannerImages, setBannerImages] = useState(product?.bigImageURL);
  const [fileEventBanner, setFileEventBanner] = useState('');
  const [fileEvent, setFileEvent] = useState('');

  const [error, setErrors] = useState({});

  const [tagCatgoriesList, setTagCategoriesList] = useState([]);
  const [tagList, setTagsList] = useState([]);

  const productTypeList = [
    { label: "Product", value: "product" },
    { label: "Spiritual", value: "spiritual" },
    { label: "Magazine", value: "magazine" }
  ]

  useEffect(() => {
    getTagCategories();
    dispatch(getAllCategories({ payload: { categoryType: "product" }, token: userState.token }))
  }, [])

  useEffect(() => {
    if (tagCategory) {
      getTagList();
    }
  }, [tagCategory])

  // get category data
  const getShoppyCategory = productCategories?.data !== undefined && productCategories?.data !== null && productCategories?.data?.length > 0 ? productCategories?.data?.map((itm) => {
    return {
      label: itm.categoryName,
      value: itm.categoryName
    };
  }) : [];


  const getTagCategories = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTagCat', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
      });
      const responseData = await response.json();
      let arr = [];
      responseData?.data?.map((item) => {
        arr.push({
          ...item,
          label: item.tagCategoryName,
          value: item.tagCategoryId,
        });
      });
      setTagCategoriesList(arr);
    }
    catch (Err) {
      console.log("Err while getting tag categories", Err);
    }
  }

  const getTagList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + `/cms/getAllTags?tagCatId=${tagCategory?.value}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
      });
      const responseData = await response.json();
      let arr = [];
      responseData?.data?.map((item) => {
        arr.push({
          ...item,
          label: item.tag,
          value: item.tagId,
        });
      });
      setTagsList(arr);
    }
    catch (Err) {
      console.log("Err while getting tag categories", Err);
    }
  }



  const editValidate = () => {
    let newErrors = {};

    if (!name) {
      newErrors.name = "*Product name is Required";
    }

    if (!img) {
      newErrors.img = "*Please upload prodcut image";
    }

    if (!bannerImages) {
      newErrors.bannerImages = "*Select Atleast one  one image";
    }

    if (!category) {
      newErrors.category = "*Product category is required";
    }

    if (!marketPlaceURL) {
      newErrors.marketPlaceURL = "*MarketPlace URL is Required";
    }

    if (!productType) {
      newErrors.productType = "*Product type is required";
    }

    if (!tagCategory) {
      newErrors.tagCategory = "*Tag category is required";
    }

    if (tags.length === 0) {
      newErrors.tags = "*Tags are Required";
    }

    if (!descriptionEn.trim() || descriptionEn.trim() === '<p><br></p>') {
      newErrors.descriptionEn = "*Description in english is Required";
    }

    if (!descriptionMr.trim() || descriptionMr.trim() === '<p><br></p>') {
      newErrors.descriptionMr = "*Description in marathi is Required";
    }

    //  if (!descriptionHn) {
    //   newErrors.descriptionHn = "*Description in hindi is Required";
    // }


    if (!shortdescription.trim() || shortdescription.trim() === '<p><br></p>') {
      newErrors.shortdescription = "* Short Description is Required";
    }

    if (!shortdescriptionMr.trim() || shortdescriptionMr.trim() === '<p><br></p>') {
      newErrors.shortdescriptionMr = "* Short Description in Marathi is Required"
    }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  const editShoppee = async () => {
    const isValid = editValidate();
    if (isValid) {
      setEditLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/shoppee/updateShoppee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState.token}`
        },
        body: JSON.stringify({
          shopeeId: product.shopeeId,
          name: name,
          imageURL: img,
          bigImageURL: bannerImages,
          marketPlaceURL: marketPlaceURL,
          category: category.label,
          productType: productType?.value,
          priorityNo: 100,
          tags: tags.map((row) => row.label),
          tagsCategory: tagCategory?.label,
          descrption: descriptionMr,
          descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
          shortDescription: shortdescriptionMr,
          shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
          isHome: true,
          createdBy: userState?.data?.user?.name,
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "Updated Successfully") {
          toast.success("Updated Successfully");
          setEditLoader(false);
          navigate(-1);
        }
        else if (response.message === "shopee already exist") {
          toast.error("Product already exist");
          setEditLoader(false);
        }
      }).catch((Err) => {
        console.log("Error While Updating Product", Err);
        setEditLoader(false);
      })
    }
  }

  const handleReactS3UltraRes = (url) => {
    setImg(url);
  }

  const handleReactS3UltraResBanner = (url) => {
    setBannerImages(url)
  }




  return (

    <div className='AddShoppee outletPadding'>
      <ToastContainer />
      <Container>
        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-2" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.BagPlus size={20} className='me-2' />Edit Product
        </h3>

        <Row className="mt-4">
          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Product Name<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter Name" id="name" value={name} onChange={(e) => {
                if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                  e.target.value.trim()
                ) {
                  setName(e.target.value);
                } else if (e.target.value.length === 0) {
                  setName(e.target.value);
                }
              }} />
              {error.name && <p className="errMsg">{error.name}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3" id="img">
              <Form.Label>Image<span>*</span></Form.Label> <br />
              {!img ?
                <>
                  <label htmlFor="productImg" id="uploadBtn">
                    <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    id="productImg"
                    style={{ display: "none" }}
                    onChange={(event) => setFileEvent(event)} />
                  <p id='imgNote'>Recommanded image resolution - 1:1</p>
                  {error.img && <p className="errMsg">{error.img}</p>}
                </>
                :
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  <Card className="uploadedFile m-1">
                    <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                    <Icon.XCircleFill className='removeIcon' size={18}
                      onClick={() => setImg('')} />
                  </Card>
                </div>
              }
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Banner Image<span>*</span></Form.Label> <br />
              <>
                {!bannerImages ?
                  <>
                    <label htmlFor="fileInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEventBanner(event)} />
                    <p id='imgNote'>Recommanded image resolution -320*256px(2:1)</p>
                    {error.bannerImages && <p className="error">{error.bannerImages}</p>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={bannerImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setBannerImages('')} />
                    </Card>
                  </div>
                }
              </>
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Product Category<span>*</span></Form.Label>
              <Select id="category"
                value={category}
                options={getShoppyCategory}
                onChange={(option) => setCategory(option)}
              />
              {error.category && <p className="errMsg">{error.category}</p>}
            </Form.Group>
          </Col>


          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>MarketPlace URL<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter marketplace URL" id="marketPlaceURL" value={marketPlaceURL} onChange={(e) => {
                setMarketPlaceURL(e.target.value)
              }} />
              {error.marketPlaceURL && <p className="errMsg">{error.marketPlaceURL}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Product Type<span>*</span></Form.Label>
              <Select id="productType"
                value={productType}
                options={productTypeList}
                onChange={(option) => setProductType(option)}
              />
              {error.productType && <p className="errMsg">{error.productType}</p>}
            </Form.Group>
          </Col>


          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Tag Category<span className='errMsg'>*</span></Form.Label>
              <Select id="tagCategory" isMulti={false} options={tagCatgoriesList} isSearchable={true} value={tagCategory}
                placeholder={<div style={{ fontSize: '15px' }}>Select Tag Category</div>}
                onChange={(option) => { setTagCategory(option); setTags([]) }} />
              {error.tagCategory && <p className="errMsg">{error.tagCategory}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Tags<span className='errMsg'>*</span></Form.Label>
              <Select id="tags" isDisabled={tagCategory === ""} isMulti={true} options={tagList} isSearchable={true} value={tags}
                closeMenuOnSelect={false} placeholder={<div style={{ fontSize: '15px' }}>Select Tags</div>}
                onChange={(option) => { setTags(option) }} />
              {error.tags && <p className="errMsg">{error.tags}</p>}
            </Form.Group>
          </Col>

          <Col md={12} >
            <Form.Group className="mb-3">
              <Form.Label>Short Description in English<span>*</span></Form.Label>
              <ReactQuill
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={shortdescription}
                onChange={(e) => setShortDescription(e)}
              />
              <br />
              <br />
              <br />
              {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
            </Form.Group>
          </Col>

          <Col md={12} >
            <Form.Group className="mb-3">
              <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
              <ReactQuill
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={shortdescriptionMr}
                onChange={(e) => setShortDescriptionMr(e)}
              />
              <br />
              <br />
              <br />
              {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
            </Form.Group>
          </Col>

          <Col md={12} className='off'>
            <Form.Group className="mb-5">
              <Form.Label>Short Description in Hindi</Form.Label>
              <ReactQuill
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={shortdescriptionHn}
                onChange={(e) => setShortDescriptionHn(e)}
              />
              <br />
              <br />
              <br />
            </Form.Group>
          </Col>


          <Col md={12}>
            <Form.Group className='mb-3'>
              <Form.Label>Full Description in English<span className='errMsg'>*</span></Form.Label>
              <ReactQuill
                id="descriptionEn"
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={descriptionEn}
                onChange={(itm) => { setDescriptionEn(itm) }}
              />
              <br />
              <br />
              <br className='d-block d-md-none' />
              <br className='d-block d-md-none' />
              {error.descriptionEn && <p className="errMsg">{error.descriptionEn}</p>}
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className='mb-3'>
              <Form.Label>Full Description in Marathi<span className='errMsg'>*</span></Form.Label>
              <ReactQuill
                id="descriptionMr"
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={descriptionMr}
                onChange={(itm) => { setDescriptionMr(itm) }}
              />
              <br />
              <br />
              <br className='d-block d-md-none' />
              <br className='d-block d-md-none' />
              {error.descriptionMr && <p className="errMsg">{error.descriptionMr}</p>}
            </Form.Group>
          </Col>

          <Col md={6} className='off'>
            <Form.Group className='mb-3'>
              <Form.Label>Full Description in Hindi<span className='errMsg'>*</span></Form.Label>
              <ReactQuill
                id="descriptionHn"
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={descriptionHn}
                onChange={(itm) => { setDescriptionHn(itm) }}
              />
              <br />
              <br />
              <br className='d-block d-md-none' />
              <br className='d-block d-md-none' />
            </Form.Group>
          </Col>


          <Row className='mb-5'>
            {
              editLoader ? <center><Spinner variant='primary' /></center> :
                <>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                    <Button className='primaryBtn w-auto mx-auto' onClick={() => editShoppee()}>
                      <Icon.Clipboard2Plus className='me-2' />Edit Product
                    </Button>
                  </div>
                </>
            }
          </Row>
        </Row>
      </Container>

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
          directory="productImages" onFileUpload={handleReactS3UltraRes} />
      }


      {
        fileEventBanner &&
        <ReactS3Ultra fileEvent={fileEventBanner} fileType={process.env.REACT_APP_IMAGE}
          directory="banner" onFileUpload={handleReactS3UltraResBanner} type="bannerImages" />
      }



    </div>
  )
}

export default EditShoppee