export const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [ 'bold', 'italic', 'underline', 'strike', 'blockquote', 'link','image',],
      [ { list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      [{ 'color': [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ["clean"],
    ],
  };
  
  export const quillFormats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 
    'bullet', 'indent', 'link', 'image', 'align', 'color'
  ];