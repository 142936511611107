import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Modal,
  InputGroup,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Common/Pagination";
import Skeleton from "../../Common/Skeleton";
import S3 from "react-aws-s3";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import ClearData from "../../Common/ClearData";
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey, setImgVerifyReport } from "../../Common/ActiveKeySlice";
import PublishModel from "../../Common/Model/PublishModel";
import UnPublishModel from "../../Common/Model/UnPublishModel";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";
window.Buffer = window.Buffer || require("buffer").Buffer;

const EventList = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [eventList, setEventList] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchField, setSearchField] = useState("");
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishEventId, setPublishEventId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  // Function to handle opening the publish modal
  const openPublishModal = (event_id) => {
    setPublishEventId(event_id);
    setIsPublishModalOpen(true);
  };

  // Function to handle closing the publish modal
  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (event_id) => {
    setUnpublishId(event_id);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };
  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };


  useEffect(() => {
    getEventList()
  }, [docPerPage, pageNo])

  const getEventList = async () => {
    setApiLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/priority/titleFilter",
      {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          documentsPerPage: docPerPage,
          page: pageNo,
          type: "Event",
          title: searchField ? searchField : "",

        })
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Authorization failed / Forbidden") {
          setApiLoader(true);
          dispatch(setLogout(null));
          dispatch(setActiveKey(null));
          dispatch(setImgVerifyReport(null));
          localStorage.removeItem('persist:root');
          navigate('/');
        } else if (response.status === 200) {
          setApiLoader(false);
          setEventList(response);
          setNoOfPages(response?.noOfPages);
        } else {
          setApiLoader(false)
          setEventList([])
        }
      })
      .catch((error) => {
        setApiLoader(false);
      });
  };

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getEventList, dependencies);

  const deleteEvent = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/eventSystem/deleteEventSystem", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        event_id: deleteId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsDelete(false);
        getEventList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for toggle
  const handlePublishAction = async (event_id, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/eventSystem/publishEventSystem", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          event_id: event_id,
          isPublish: isPublish
        }),
      });
      const response = await result.json();
      if (response) {
        getEventList();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishEventId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };


  return (
    <div className="outletPadding">
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Eventlist"
        apiLoader={apiLoader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Eventlist"
        apiLoader={apiLoader}
      />
      <Container>
        <Row className="justify-content-between">
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>

          <Col
            md={2}
            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
          >
            <Button
              className="primaryBtn"
              onClick={() => navigate("/dashboard/add-event")}
            >
              <Icon.CalendarPlus className="me-2" size={15} />
              Add Event
            </Button>
          </Col>
        </Row>

        {/* Event List */}

        {/* Article List Table */}
        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Title</th>
                <th>Venue</th>
                <th>Event Media</th>
                <th>Date</th>
                <th>Event Category</th>
                <th>City</th>
                <th>Publish</th>
                <th>Action</th>
              </thead>
              {apiLoader ? (
                <Skeleton rows={10} cols={9} />
              ) : eventList?.data?.length > 0 && eventList?.data !== null && eventList?.data !== undefined ? (
                eventList?.data?.map((itm, index) => {
                  return (
                    <tr key={index}>
                      <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                      <td style={{ width: "300px" }}>{itm?.event_title}</td>
                      <td>{itm?.event_venue}</td>
                      <td>{itm?.event_media}</td>
                      <td>{moment(itm?.event_date).format("DD-MM-YY") + ' To ' + moment(itm?.event_end_date).format("DD-MM-YY")}</td>

                      <td>{itm?.event_category}</td>
                      <td>{itm?.cities.toString()}</td>
                      <td style={{ width: '120px' }}>
                        {itm.isPublish ? (
                          <div
                            style={{
                              backgroundColor: "#8FBC8F",
                              borderRadius: "8px",
                              padding: "4px 8px",
                              color: "white",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                            onClick={() => openUnpublishModal(itm.event_id)}
                          >
                            Published
                          </div>
                        ) : (
                          <div
                            className="switch"
                            onClick={() => openPublishModal(itm.event_id)}
                          >
                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                            <span className="slider round" onClick={() => openPublishModal(itm.event_id)}></span>
                          </div>
                        )}

                      </td>

                      <td className="d-flex">
                        <img src={View} alt="View" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/view-event", {
                              state: {
                                event: itm,
                              },
                            })} />

                        <img src={Edit} alt="Edit" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/edit-event", {
                              state: {
                                event: itm,
                              },
                            })} />

                        <img src={Delete} alt="Delete" className='icon'
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(itm?.event_id);
                          }} />
                      </td>

                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>
        </div>
        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
              Total - <strong>{eventList?.count}</strong>
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>
      </Container>

      {/* for toggle model */}
      <Modal
        size="md"
        show={isPublishModalOpen}
        onHide={closePublishModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Publish</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to publish this Article?
        </Modal.Body>
        <Modal.Footer>
          {apiLoader ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button className='primaryBtn me-3' onClick={handlePublishModalConfirm} >
                <Icon.HandThumbsUp className='me-1' />Yes</Button>
              <Button className='secondaryBtn' onClick={handlePublishModalCancel} >
                <Icon.HandThumbsDown className='me-1' />No</Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      {/* Delete Article */}
      <Modal
        size="md"
        show={isdelete}
        onHide={() => setIsDelete(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h4 className="mb-0">
            <Icon.TrashFill size={20} className="me-2" />
            Delete Event
          </h4>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Icon.TrashFill color='red' width={80} height={80} />
          <h4 className="mt-3">Are sure about deleting the Event ?</h4>
        </Modal.Body>
        <Modal.Footer>
          {apiLoader ? (
            <Spinner variant="primary" />
          ) : (
            <>
              <Button className="primaryBtn me-2" onClick={() => deleteEvent()}>
                <Icon.HandThumbsUp className='me-1' />Yes</Button>

              <Button className="secondaryBtn" onClick={() => setIsDelete(false)}>
                <Icon.HandThumbsDown className='me-1' />No</Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EventList;
