import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Features/Common/ScrollToTop";
import Login from "./Features/Auth/Login";
import CommonLayout from "./Layout/CommonLayout";
import Profile from "./Features/Common/Profile";
import Home from "./Features/Common/Home";
import PageNotFound from "./Features/Common/PageNotFound"
import PrivacyPolicy from "./Features/Common/PrivacyPolicy";
import ManageUsers from "./Features/Admin/ManageUsers";
import ArticleList from "./Features/Admin/Article/ArticleList";
import { ToastContainer } from "react-toastify";
import EventList from "./Features/Admin/Event/EventList";

import Activities from "./Features/Admin/Activity/Activities";
import AddActivity from "./Features/Admin/Activity/AddActivity";
import ViewActivities from "./Features/Admin/Activity/ViewActivities";


import Shoppee from "./Features/Admin/Product/Shoppee";
import AddShoppee from "./Features/Admin/Product/AddShoppee";
import ViewShopee from "./Features/Admin//Product/ViewShopee";


import ViewArticle from "./Features/Admin/Article/ViewArticle";
// import ContentList from "./Features/Admin/Video/VideoList";
import HelpAndSupport from "./Features/Admin/HelpAndSupport";
import ViewUser from "./Features/Admin/ViewUser";
import ViewEventList from "./Features/Admin/Event/ViewEventList";
import ViewTagList from "./Features/Admin/ViewTagList";


// import VideoList from "./Features/Admin/Video/VideoList";
// import AddVideoList from "./Features/Admin/Video/AddVideoList";
import Category from "./Features/Admin/Category/Category";
import ViewCategory from "./Features/Admin/Category/ViewCategory";
import AddCategory from "./Features/Admin/Category/AddCategory";
import AddArticle from "./Features/Admin/Article/AddArticle";

import AddEvent from "./Features/Admin/Event/AddEvent";

import Course from "./Features/Admin/Course/Course";
import CourseLesson from "./Features/Admin/Course/CourseLesson";
import CourseTopic from "./Features/Admin/Course/CourseTopic";
import ViewCourseTopic from "./Features/Admin/Course/ViewCourseTopic";
import ViewVideo from "./Features/Admin/Video/ViewVideo";
import AddVideo from "./Features/Admin/Video/AddVideo";
import Video from "./Features/Admin/Video/Video";
import EditVideo from "./Features/Admin/Video/EditVideo";
import Tags from "./Features/Admin/Tags/Tags";
import AddCourse from "./Features/Admin/Course/AddCourse";
import AddLesson from "./Features/Admin/Course/AddLesson";
import AddTopic from "./Features/Admin/Course/AddTopic";
import Teachers from "./Features/Admin/Teacher/Teachers";
import ViewTeacher from "./Features/Admin/Teacher/ViewTeacher";
import AddTeacher from "./Features/Admin/Teacher/AddTeacher";
import EditArticle from "./Features/Admin/Article/EditArticle";
import GuruAndQuotes from "./Features/Admin/GuruAndQuotes/GuruAndQuotes";
import EditTopic from "./Features/Admin/Course/EditTopic";
import EditCourse from "./Features/Admin/Course/EditCourse";
import EditCourseLesson from "./Features/Admin/Course/EditCourseLesson";
import EditCategory from "./Features/Admin/Category/EditCategory";
import EditEvent from "./Features/Admin/Event/EditEvent";
import EditActivity from "./Features/Admin/Activity/EditActivity";
import EditShoppee from "./Features/Admin/Product/EditShoppee";
import EditTeacher from "./Features/Admin/Teacher/EditTeacher";
import TopicAndTaskList from "./Features/Admin/Course/TopicAndTaskList";
import AddTask from "./Features/Admin/Course/AddTask";
import Priority from "./Features/Admin/Priority/Priority";
import EditTask from "./Features/Admin/Course/EditTask";
import ViewCoursetask from "./Features/Admin/Course/ViewCourseTask";
import Transactions from "./Features/Admin/Transactions/Transactions";
import CloudeFlareVideoUpload from "./Utils/CloudeFlareVideoUpload";
import PrivateRoutes from "./Features/Auth/PrivateRoutes";
import SubscriptionUser from "./Features/Admin/Subscription/SubscriptionUser";
import SubscriptionUnsubscription from "./Features/Admin/Subscription/SubscriptionUnsubscription";
import ViewSubscriptionUser from "./Features/Admin/Subscription/ViewSubscriptionUser";
import AuditLog from "./Analytics/AuditLog";
import ViewNotSubscriptionUser from "./Features/Admin/Subscription/ViewNotSubscriptionUser";

function App() {
  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const roles = ['admin', 'superadmin']
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/video-upload" element={<CloudeFlareVideoUpload />} />
          {

            <Route path='/dashboard' element={<PrivateRoutes><CommonLayout /></PrivateRoutes>}>
              {roles.includes(userState?.data?.user?.userType) &&
                <>
                  <Route path='/dashboard' element={<PrivateRoutes><Home /></PrivateRoutes>} />
                  <Route path='/dashboard/priority' element={<PrivateRoutes><Priority /></PrivateRoutes>} />
                  {/* Article */}
                  <Route path="/dashboard/articleList" element={<PrivateRoutes><ArticleList /></PrivateRoutes>} />
                  <Route path="/dashboard/view-article" element={<PrivateRoutes><ViewArticle /></PrivateRoutes>} />
                  <Route path="/dashboard/add-article" element={<PrivateRoutes><AddArticle /></PrivateRoutes>} />
                  <Route path="/dashboard/edit-article" element={<PrivateRoutes><EditArticle /></PrivateRoutes>} />

                  <Route path="/dashboard/eventList" element={<PrivateRoutes><EventList /></PrivateRoutes>} />
                  <Route path="/dashboard/add-event" element={<PrivateRoutes><AddEvent /></PrivateRoutes>} />
                  <Route path="/dashboard/edit-event" element={<PrivateRoutes><EditEvent /></PrivateRoutes>} />
                  <Route path="/dashboard/help-and-support" element={<PrivateRoutes><HelpAndSupport /></PrivateRoutes>} />

                  {/* ----------- Activity ----------------- */}
                  <Route path="/dashboard/activities" element={<PrivateRoutes><Activities /></PrivateRoutes>} />
                  <Route path="/dashboard/add-activity" element={<PrivateRoutes><AddActivity /></PrivateRoutes>} />
                  <Route path="/dashboard/edit-activity" element={<PrivateRoutes><EditActivity /></PrivateRoutes>} />


                  {/* ----------- Shoppee / Product ----------------- */}
                  <Route path="/dashboard/shoppee" element={<PrivateRoutes><Shoppee /></PrivateRoutes>} />
                  <Route path="/dashboard/view-shoppee" element={<PrivateRoutes><ViewShopee /></PrivateRoutes>} />
                  <Route path="/dashboard/add-shoppee" element={<PrivateRoutes><AddShoppee /></PrivateRoutes>} />
                  <Route path="/dashboard/edit-shoppee" element={<PrivateRoutes><EditShoppee /></PrivateRoutes>} />

                  <Route path="/dashboard/view-event" element={<PrivateRoutes><ViewEventList /></PrivateRoutes>} />
                  <Route path="/dashboard/view-activities" element={<PrivateRoutes><ViewActivities /></PrivateRoutes>} />

                  <Route path='/dashboard/profile' element={<PrivateRoutes><Profile /></PrivateRoutes>} />

                  {/* category */}
                  <Route path='/dashboard/category' element={<PrivateRoutes><Category /></PrivateRoutes>} />
                  <Route path='/dashboard/add-category' element={<PrivateRoutes><AddCategory /></PrivateRoutes>} />
                  <Route path='/dashboard/view-category' element={<PrivateRoutes><ViewCategory /></PrivateRoutes>} />
                  <Route path='/dashboard/edit-category' element={<PrivateRoutes><EditCategory /></PrivateRoutes>} />
                  <Route path="/dashboard/viewtaglist" element={<PrivateRoutes><ViewTagList /></PrivateRoutes>} />
                  <Route path='/dashboard/profile' element={<PrivateRoutes><Profile /></PrivateRoutes>} />

                  {/* Video */}
                  <Route path="/dashboard/content-list" element={<PrivateRoutes><Video /></PrivateRoutes>} />
                  <Route path="/dashboard/add-content" element={<PrivateRoutes><AddVideo /></PrivateRoutes>} />
                  <Route path="/dashboard/viewcontentlist" element={<PrivateRoutes><ViewVideo /></PrivateRoutes>} />
                  <Route path="/dashboard/editcontentlist" element={<PrivateRoutes><EditVideo /></PrivateRoutes>} />

                  {/* Tags */}
                  <Route path="/dashboard/tags" element={<PrivateRoutes><Tags /></PrivateRoutes>} />
                  <Route path="/dashboard/viewtaglist" element={<PrivateRoutes><ViewTagList /></PrivateRoutes>} />

                  {/*-------- Teacher --------- */}
                  <Route path="/dashboard/teacher" element={<PrivateRoutes><Teachers /></PrivateRoutes>} />
                  <Route path="/dashboard/view-teacher" element={<PrivateRoutes><ViewTeacher /></PrivateRoutes>} />
                  <Route path="/dashboard/add-teacher" element={<PrivateRoutes><AddTeacher /></PrivateRoutes>} />
                  <Route path="/dashboard/edit-teacher" element={<PrivateRoutes><EditTeacher /></PrivateRoutes>} />


                  {/* ---------- Guru Quote ------------- */}
                  <Route path="/dashboard/guru-and-quote" element={<PrivateRoutes><GuruAndQuotes /></PrivateRoutes>} />


                  {/* course */}
                  <Route path="/dashboard/course" element={<PrivateRoutes><Course /></PrivateRoutes>} />
                  <Route path="/dashboard/Addcourse" element={<PrivateRoutes><AddCourse /></PrivateRoutes>} />
                  <Route path="/dashboard/editcourse" element={<PrivateRoutes><EditCourse /></PrivateRoutes>} />
                  <Route path="/dashboard/courselesson" element={<PrivateRoutes><CourseLesson /></PrivateRoutes>} />
                  <Route path="/dashboard/addlesson" element={<PrivateRoutes><AddLesson /></PrivateRoutes>} />
                  <Route path="/dashboard/editlesson" element={<PrivateRoutes><EditCourseLesson /></PrivateRoutes>} />
                  <Route path="/dashboard/addtopic" element={<PrivateRoutes><AddTopic /></PrivateRoutes>} />
                  <Route path="/dashboard/coursetopic" element={<PrivateRoutes><TopicAndTaskList /></PrivateRoutes>} />
                  <Route path="/dashboard/viewcoursetopic" element={<PrivateRoutes><ViewCourseTopic /></PrivateRoutes>} />
                  <Route path="/dashboard/viewcoursetask" element={<PrivateRoutes><ViewCoursetask /></PrivateRoutes>} />
                  <Route path="/dashboard/edittopic" element={<PrivateRoutes><EditTopic /></PrivateRoutes>} />
                  <Route path="/dashboard/addTask" element={<PrivateRoutes><AddTask /></PrivateRoutes>} />
                  <Route path="/dashboard/edittask" element={<PrivateRoutes><EditTask /></PrivateRoutes>} />
                </>
              }
              {
                userState?.data?.user?.userType === "superadmin" &&
                <>
                  <Route path='/dashboard/manage-users' element={<PrivateRoutes><ManageUsers /></PrivateRoutes>} />
                  <Route path='/dashboard/view-users' element={<PrivateRoutes><ViewUser /></PrivateRoutes>} />
                  <Route path="/dashboard/transaction" element={<PrivateRoutes><Transactions /></PrivateRoutes>} />
                  <Route path='/dashboard/subscription-user' element={<PrivateRoutes><SubscriptionUnsubscription /></PrivateRoutes>} />
                  <Route path='/dashboard/view-subscription-user' element={<PrivateRoutes><ViewSubscriptionUser /></PrivateRoutes>} />
                  <Route path='/dashboard/view-notsubscription-user' element={<PrivateRoutes><ViewNotSubscriptionUser /></PrivateRoutes>} />
                  <Route path='/dashboard/auditlog' element={<PrivateRoutes><AuditLog /></PrivateRoutes>} />
                </>

              }
            </Route>
          }

          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path="*" element={<PrivateRoutes><PageNotFound /></PrivateRoutes>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;