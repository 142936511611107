import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import useGetAllEntity from '../Features/Common/Reuse/useGetAllEntity';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import LineChart from '../Graph/LineChart';

const AuditLog = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    // call custom hook
    const { allentityoptions } = useGetAllEntity();

    // use state for graph
    const [StartDate, setStartDate] = useState("")
    const [EndDate, setEndDate] = useState("")
    const [entitytype, setEntityType] = useState();
    const [labels, setLabels] = useState([])
    const [loader, setLoader] = useState(false);
    const [countdata, setCountData] = useState([])

    useEffect(() => {
        if (allentityoptions.length > 0) {
            setEntityType(allentityoptions[0]?.value || '');
        }
    }, [allentityoptions]);

    // graph api calling
    const getCountData = async (StartDate, EndDate) => {
        try {
            setLoader(true);
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/auditLogs/auditLogGraph', {
                method: 'POST',
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    entityType: entitytype,
                    fromDate: StartDate,
                    toDate: EndDate,
                }),

            })
            const responseData = await response.json();
            setCountData(responseData?.data?.clicks || []);
            setLabels(responseData?.data?.dates || []);
            setLoader(false);

        } catch (Err) {
            console.log("Err while getting activites", Err);
            setLoader(false);
        }
    }

    useEffect(() => {
        getCountData()
    }, [entitytype])

    return (
        <div className='Transactions outletPadding'>
            <Container>
                <Row className='d-flex justify-content-between'>
                    <Col md={4} className='mb-3 mb-md-0'>
                        <Form.Select
                            value={entitytype}
                            onChange={(e) => { setEntityType(e.target.value); }}>
                            {allentityoptions.map((itm) => (
                                <option key={itm.value} value={itm.value}>{itm.label}</option>
                            ))}
                        </Form.Select>
                    </Col>

                    <Col md={8} className='d-flex justify-content-end align-items-center'>
                        <div className='d-flex flex-row'>
                            <input
                                type="date"
                                className="form-control me-2"
                                placeholder="Search here"
                                name="fromDate"
                                value={StartDate ? new Date(StartDate).toISOString().split('T')[0] : ''}
                                onChange={(e) => {
                                    setStartDate(e.target.value);
                                }}
                                max={EndDate}
                            />
                            <input
                                type="date"
                                className="form-control me-2"
                                placeholder="Search here"
                                name="toDate"
                                value={EndDate ? new Date(EndDate).toISOString().split('T')[0] : ''}
                                min={StartDate}
                                disabled={!StartDate}
                                onChange={(e) => {
                                    setEndDate(e.target.value)
                                }}
                            />
                            <Button className='primaryBtn' onClick={() => getCountData(StartDate, EndDate)}>
                                <Icon.Search size={20} />
                            </Button>
                            <Button className='primaryBtn ms-2' onClick={() => getCountData(setStartDate(""), setEndDate(""))}>
                                <Icon.ArrowClockwise size={20} />
                            </Button>

                        </div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col sm={12} md={12} lg={11} className="mx-auto ">
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            style={{
                                backgroundColor: 'rgba(240, 243, 244, 0.5)',
                                height: '100%',
                                width: '100%',
                                borderRadius: '12px',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                padding: '10px',
                                margin: '20px 0px'
                            }}
                        >
                            {loader ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                <LineChart labels={labels} data={countdata} height='auto' />
                            )}
                        </motion.div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AuditLog;
