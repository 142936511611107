import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";

const ViewNotSubscriptionUser = () => {
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData);

    const location = useLocation()
    const data = location?.state?.user

    const addSubscriptions = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/updateUserSubscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.data?.token}` },
            body: JSON.stringify({
                userId: data?.userId
            })
        })
            .then((response) => response.json())
            .then((res) => {
                if (res?.message == "Update User Subscription successfully ") {
                    toast.success("User subscription updated !")
                }
            })
            .catch((error) => {
                console.log("error", error);
            })
    }

    return (
        <>
            <div style={{ marginTop: "80px" }}>
                <ToastContainer />
                <Button className="backBtn" onClick={() => navigate(-1)}>
                    <Icon.ArrowLeft className="me-2" />
                    Back
                </Button>
                <Button className='primaryBtn mt-3' onClick={addSubscriptions}>Add Subscription</Button>
            </div>
        </>
    )
}

export default ViewNotSubscriptionUser
