import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useGetAllEntity = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);
    const [allentityoptions, setAllEntityOptions] = useState([]);

    const fetchEntityData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + "/auditLogs/getUniqueEntityTypes", {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
            });
            const data = await response.json();
            const filterOption = data?.data?.length > 0 && data?.data?.map((itm) => {
                return ({ value: itm.value, label: itm.label });
            });

            if (filterOption?.length > 0) {
                setAllEntityOptions(filterOption);
            }
        } catch (error) {
            console.log("Error", error);
        }
    };

    useEffect(() => {
        fetchEntityData();
    }, [])

    return { allentityoptions };
};

export default useGetAllEntity;
