import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row, Form, Spinner, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";

const ViewSubscriptionUser = () => {
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const location = useLocation()
    const userId = location?.state?.userId

    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);

    const addSubscriptions = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/updateUserSubscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.data?.token}` },
            body: JSON.stringify({
                userId: userId
            })
        })
            .then((response) => response.json())
            .then((res) => {
                if (res?.message == "Update User Subscription successfully ") {
                    toast.success("User subscription updated !")
                }
            })
            .catch((error) => {
                console.log("error", error);
            })
    }

    const singleUserDetails = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getUserSubscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.data?.token}` },
            body: JSON.stringify({
                userId: userId
            })
        })
            .then((response) => response.json())
            .then((res) => {
                console.log("response", res);

            })
            .catch((error) => {
                console.log("error", error);
            })
    }

    useEffect(() => {
        singleUserDetails()
    }, [])

    return (
        <div className='profile outletPadding' >
            <Container>
                <div className='d-flex justify-content-between'>
                    <Button className="backBtn" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />
                        Back
                    </Button>
                    <Button className='primaryBtn w-auto' onClick={() => setShow(true)}><Icon.Lock className='me-1' />Add Subscription</Button>
                </div>

                <Row className='mt-3'>
                    <Col md={5}>
                        <h5>User Subscription Details</h5>
                        <Card>
                            <div>
                                <h6><Icon.PersonFill className='me-2' />FullName -</h6>
                                <p></p>
                            </div>

                            <div>
                                <h6><Icon.BriefcaseFill className='me-2' />Role - </h6>
                                <p></p>
                            </div>

                            <div>
                                <h6><Icon.TelephoneFill className='me-2' />Mobile - </h6>
                                <p></p>
                            </div>

                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* {/* <Button className='primaryBtn mt-3' onClick={addSubscriptions}>Add Subscription</Button> */}
        </div>
    )
}

export default ViewSubscriptionUser

