import React, { useEffect, useState } from "react";
import { Container, Row, Button, Spinner, Col, Card, ButtonGroup, ToggleButton, } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import moment from "moment";

const ViewUser = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const user = location?.state?.user;

  return (
    <div className="outletPadding">
      <Container>
        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-2" />Back
          </Button>
        </Row>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Person className="me-1" /> User Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    User Name
                  </th>
                  <td>{user?.fullName ? user?.fullName : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Mobile Number
                  </th>
                  <td>{user?.mobile ? user?.mobile : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Email
                  </th>
                  <td>{user?.email ? user?.email : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Role
                  </th>
                  <td>{user?.userType ? user?.userType : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Status
                  </th>
                  <td>{user?.userState ? user?.userState : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Registered Date
                  </th>
                  <td>{user?.registrationDate ? moment(user.registrationDate).format('Do MMMM YYYY') : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewUser;
