import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Spinner, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../../Common/Skeleton';
import Pagination from '../../Common/Pagination';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';
import ReactQuill from 'react-quill';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey, setImgVerifyReport } from '../../Common/ActiveKeySlice';
import ClearData from '../../Common/ClearData';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';

const GuruQuote = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAdd, setShowAdd] = useState(false);
  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [loader, setLoader] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const [data, setData] = useState([]);

  const [guru, setGuru] = useState('');
  const [guruQuoteId, setGuruQuoteId] = useState('');
  const [quoteEn, setQuoteEn] = useState('');
  const [quoteMr, setQuoteMr] = useState('');
  const [quoteHn, setQuoteHn] = useState('');
  const [date, setDate] = useState('');
  const [guruId, setGuruId] = useState('');
  const [guruFirstName, setGuruFirstName] = useState('');
  const [guruLastName, setGuruLastName] = useState('');
  const [guruImageURL, setGuruImageURL] = useState('');
  const [guruOptions, setGuruOptions] = useState([]);
  const [searchField, setSearchField] = useState("");

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [guruQuoteID, setGuruQuoteID] = useState(null);
  // Unpublish Article variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");


  const [error, setErrors] = useState({});


  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    getGuruList();
  }, []);

  // Function to handle opening the publish modal
  const openPublishModal = (guruQuoteId) => {
    setGuruQuoteID(guruQuoteId);
    setIsPublishModalOpen(true);
  };


  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (guruQuoteID) => {
    setUnpublishId(guruQuoteID);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };


  const getGuruList = async () => {
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllGuru', {
        method: 'GET',
        headers: { Authorization: `Bearer ${userState?.token}` }
      })
      const response = await result.json();
      if (response.status === 200) {
        let arr = [];
        response.data.map((row) => {
          arr.push({
            ...row,
            label: row.firstName + ' ' + row.lastName, value: row.guruId,
          });
        });
        setGuruOptions(arr);
      }
    } catch (Err) {
      console.log("Err while getting  guru list", Err);
    }
  }

  useEffect(() => {
    getAllQuotes()
  }, [pageNo, docPerPage])

  // ------- Get All Activities ----------------------
  const getAllQuotes = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${userState?.token}`
        },
        body: JSON.stringify({
          courseTeacherId: '',
          courseCategories: '',
          type: "Guru Quote",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      if (responseData.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));
        dispatch(setImgVerifyReport(null));
        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(responseData);
        setNoOfPages(responseData?.noOfPages);
        setLoader(false);
      }

    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);
    }
  }

  // for debounding
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllQuotes, dependencies);

  const addValidate = () => {
    let newErrors = {};

    if (!guru) {
      newErrors.guru = "*Please select guru";
    }

    if (!date) {
      newErrors.date = "*Please enter guru quote date";
    }

    if (!quoteEn) {
      newErrors.quoteEn = "*Please enter guru quote in english";
    }

    if (!quoteMr) {
      newErrors.quoteMr = "*Please enter guru quote in marathi";
    }

    //  if (!quoteHn) {
    //   newErrors.quoteHn = "*Please enter guru quote in hindi";
    // }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  const editValidate = () => {
    let newErrors = {};

    if (!date) {
      newErrors.date = "*Please enter guru quote date";
    }

    //  if (!quoteEn) {
    //   newErrors.quoteEn = "*Please enter guru quote in english";
    // }

    if (!quoteMr.trim() || quoteMr.trim() === '<p><br></p>') {
      newErrors.quoteMr = "*Please enter guru quote in marathi";
    }

    //  if (!quoteHn) {
    //   newErrors.quoteHn = "*Please enter guru quote in hindi";
    // }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }


  const addGuruQuote = async (status) => {
    const isValid = addValidate();
    if (isValid) {
      try {
        setCrudLoader(true);
        const result = await fetch(process.env.REACT_APP_BASE_URL + '/guruQuote/createGuruQuote', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
          body: JSON.stringify({
            guruFirstName: guru?.firstNameLang?.mr,
            guruLastName: guru?.lastNameLang?.mr,
            guruFirstNameLang: { en: guru?.firstNameLang?.en, mr: guru?.firstNameLang?.mr, hin: guru?.firstNameLang?.hin },
            guruLastNameLang: { en: guru?.lastNameLang?.en, mr: guru?.lastNameLang?.mr, hin: guru?.lastNameLang?.hin },
            guruImageURL: guru?.imgURL,
            quote: quoteMr,
            quoteLang: { en: quoteEn, mr: quoteMr, hin: quoteHn },
            guruId: guru?.guruId,
            date: date,
            createdBy: userState?.user?.name,
            isPublish: status
          })
        });

        const response = await result.json();
        if (response?.message === "guruQuote Created Successfully") {
          toast.success("Guru Created Successfully");
          setCrudLoader(false);
          closeModal();
          getAllQuotes();
        }
        else if (response?.message === "Guru Qoute Limit Exceeded") {
          toast.error("Guru Qoute Limit Exceeded");
          setCrudLoader(false);
          closeModal();
          getAllQuotes();
        }
        else {
          toast.error("Failed to add guru, please try again");
          setCrudLoader(false);
          closeModal();
        }
      } catch (Err) {
        console.log("Error  in adding Guru", Err);
        setCrudLoader(false);
        closeModal();
      }
    }
  }
  const setUpdateData = (itm) => {

    setShowEdit(true)
    const name = itm.guruFirstName + " " + itm.guruLastName;
    setGuru({ label: name, value: name });
    setGuruId(itm.guruId);
    setDate(moment(itm.date).format("YYYY-MM-DD"));
    setQuoteEn(itm.quoteLang.en);
    setQuoteMr(itm.quoteLang.mr ? itm.quoteLang.mr : "");
    setQuoteHn(itm.quoteLang.hin ? itm.quoteLang.hin : "");
    setGuruQuoteId(itm.guruQuoteId);
    setGuruFirstName(itm.guruFirstName);
    setGuruLastName(itm.guruLastName);
    setGuruImageURL(itm.guruImageURL);


  }

  const editGuruQuote = async () => {
    const isValid = editValidate();
    if (isValid) {
      try {
        setCrudLoader(true);
        const result = await fetch(process.env.REACT_APP_BASE_URL + '/guruQuote/updateGuruQuote', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
          body: JSON.stringify({
            guruQuoteId: guruQuoteId,
            quote: quoteMr,
            quoteLang: { en: quoteEn, mr: quoteMr, hin: quoteHn },
            guruId: guruId,
            guruFirstName: guruFirstName,
            guruLastName: guruLastName,
            guruImageURL: guruImageURL,
            date: date,
            createdBy: userState?.user?.name,
            updatedBy: userState?.user?.name
          })
        });

        const response = await result.json();
        if (response?.message === "Updated Successfully") {
          toast.success("Guru Quote Updated Successfully");
          setCrudLoader(false);
          closeModal();
          getAllQuotes();
        }
        else if (response?.message === "Guru Qoute Limit Exceeded") {
          toast.error("Guru Qoute Limit Exceeded");
          setCrudLoader(false);
          closeModal();
          getAllQuotes();
        }
        else {
          toast.error("Failed to edit guru quote, please try again");
          setCrudLoader(false);
          closeModal();
        }
      } catch (Err) {
        console.log("Error in updating Guru quote", Err);
        setCrudLoader(false);
        closeModal();
      }
    }
  }

  const deleteGuru = async () => {
    setCrudLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/guruQuote/deleteGuruQuote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState.token}`
        },
        body: JSON.stringify({ guruQuoteId: delId })
      });
      const response = await result.json();
      if (response.message === 'Data deleted succussfully') {
        toast.success('Guru Quote Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setCrudLoader(false);
        getAllQuotes();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setCrudLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting guru quote", Err);
      setCrudLoader(false);
    }
  }


  //----- Setting up Closing modal & Clearing Values ----//
  const closeModal = () => {
    setGuru('');
    setDate('');
    setQuoteEn('');
    setQuoteMr('');
    setQuoteHn('');
    setShowAdd(false);
    setShowEdit(false);
  }


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }


  // Function to handle confirming publish or unpublish modal
  const handlePublishAction = async (guruQuoteId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/guruQuote/publishGuruQuote", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
        body: JSON.stringify({
          guruQuoteId: guruQuoteId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getAllQuotes();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async (status) => {
    await handlePublishAction(guruQuoteID, status);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async (status) => {
    await handlePublishAction(unpublishId, status);
  };

  return (
    <div className='GuruQuote'>
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="GuruQuote"
        apiLoader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="GuruQuote"
        apiLoader={loader}
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>
          <Col md={3} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => setShowAdd(true)}>
              <Icon.ChatQuote className='me-2' size={16} />Add Guru Quote
            </Button>
          </Col>
        </Row>


        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '65vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Name</th>
                <th>Quote</th>
                <th>Date</th>
                <th>Publish</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={10} cols={6} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { guruQuoteId, guruImageURL, guruFirstName, guruLastName, quote, status, date } = itm
                    return (

                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>
                            {' '}
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </>
                        ) : (
                          <>{index + 1}</>
                        )}</td>
                        <td>{guruFirstName + ' ' + guruLastName}</td>
                        <td style={{ width: '300px' }}><div
                          dangerouslySetInnerHTML={{
                            __html: quote,
                          }}
                        ></div></td>
                        <td>{moment(date).format('DD-MM-YYYY')}</td>
                        <td style={{ width: '120px' }}>
                          {itm.isPublish ? (
                            <div
                              style={{
                                backgroundColor: "#8FBC8F",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={() => openUnpublishModal(itm.guruQuoteId)}
                            >
                              Published
                            </div>
                          ) : (
                            <div
                              className="switch"
                              onClick={() => openPublishModal(itm.guruQuoteId)}
                            >
                              <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                              <span className="slider round" onClick={() => openPublishModal(itm.guruQuoteId)}></span>
                            </div>
                          )}

                        </td>

                        <td className="d-flex">
                          {/* <img src={View} alt="View" className='icon me-3'/> */}
                          <img src={Edit} alt="Edit" className='icon me-3'
                            onClick={() => setUpdateData(itm)} />
                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); setDelId(guruQuoteId); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
              Total - <strong>{data?.count}</strong>
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>
      </Container>



      {/*  ------------------- Add Guru Modal ------------------------ */}
      <Modal size='xl' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> <h4 className='mb-0'>
            <Icon.ChatQuote size={20} className='me-2' />Add Guru Quote</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className='justify-content-center'>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Guru<span>*</span></Form.Label>
                <Select
                  isMulti={false}
                  id="guru"
                  value={guru}
                  onChange={setGuru}
                  options={guruOptions}
                  placeholder="Select Guru"
                />
                {error.guru && <p className="errMsg">{error.guru}</p>}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Guru Quote Date<span className='errMsg'>*</span></Form.Label>
                <Form.Control id="date" type='date' value={date} onChange={(e) => {
                  setDate(e.target.value)
                }} />
                {error.date && <p className="errMsg">{error.date}</p>}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className='mb-3'>
                <Form.Label>Guru quote in english<span className='errMsg'>*</span></Form.Label>
                <ReactQuill
                  id="quoteEn"
                  style={{ height: 200 }}
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormats}
                  value={quoteEn}
                  onChange={setQuoteEn} />
                <br />
                <br />
                <br className='d-block d-md-none' />
                <br className='d-block d-md-none' />
                {error.quoteEn && <p className="errMsg">{error.quoteEn}</p>}
              </Form.Group>
            </Col>


            <Col md={12}>
              <Form.Group className='mb-3'>
                <Form.Label>Guru quote in marathi<span className='errMsg'>*</span></Form.Label>
                <ReactQuill
                  id="quoteMr"
                  style={{ height: 200 }}
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormats}
                  value={quoteMr}
                  onChange={setQuoteMr} />
                <br />
                <br />
                <br className='d-block d-md-none' />
                <br className='d-block d-md-none' />
                {error.quoteMr && <p className="errMsg">{error.quoteMr}</p>}
              </Form.Group>
            </Col>

            <Col md={4} className='off'>
              <Form.Group className='mb-3'>
                <Form.Label>Guru quote in hindi<span className='errMsg'>*</span></Form.Label>
                <ReactQuill
                  id="quoteHn"
                  style={{ height: 200 }}
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormats}
                  value={quoteHn}
                  onChange={setQuoteHn} />
                <br />
                <br />
                <br className='d-block d-md-none' />
                <br className='d-block d-md-none' />
                {error.quoteHn && <p className="errMsg">{error.quoteHn}</p>}
              </Form.Group>
            </Col>



          </Row>
        </Modal.Body>

        <Modal.Footer>
          {
            crudLoader ? <Spinner variant='primary' /> :
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button className="primaryBtn me-4" onClick={() => addGuruQuote(false)}>
                  <Icon.Save className="me-1" />
                  Save
                </Button>

                <Button className="primaryBtn" onClick={() => addGuruQuote(true)}>
                  <Icon.JournalArrowUp className="me-1" />
                  Save & Publish
                </Button>
              </div>
            // <Button className='primaryBtn' onClick={addGuruQuote}>
            //   <Icon.ChatQuote className='me-1' />Add</Button>
          }
        </Modal.Footer>
      </Modal>

      {/*  ------------------- Edit Guru Modal ------------------------ */}
      <Modal size='xl' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> <h4 className='mb-0'>
            <Icon.ChatQuote size={20} className='me-2' />Edit Guru Qoute</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className='justify-content-center'>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Guru<span>*</span></Form.Label>
                <Select
                  isMulti={false}
                  id="guru"
                  value={guru}
                  isDisabled={true}
                  onChange={setGuru}
                  options={guruOptions}
                  placeholder="Select Guru"
                />
                {error.guru && <p className="errMsg">{error.guru}</p>}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label><span className='errMsg'>*</span>Guru Quote Date</Form.Label>
                <Form.Control id="date" type='date' value={date} onChange={(e) => {
                  setDate(e.target.value)
                }} />
                {error.date && <p className="errMsg">{error.date}</p>}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className='mb-3'>
                <Form.Label>Guru quote in english<span className='errMsg'>*</span></Form.Label>
                <ReactQuill
                  id="quoteEn"
                  style={{ height: 200 }}
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormats}
                  value={quoteEn}
                  onChange={setQuoteEn} />
                <br />
                <br />
                <br className='d-block d-md-none' />
                <br className='d-block d-md-none' />
                {error.quoteEn && <p className="errMsg">{error.quoteEn}</p>}
              </Form.Group>
            </Col>


            <Col md={12}>
              <Form.Group className='mb-3'>
                <Form.Label>Guru quote in marathi<span className='errMsg'>*</span></Form.Label>
                <ReactQuill
                  id="quoteMr"
                  style={{ height: 200 }}
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormats}
                  value={quoteMr}
                  onChange={setQuoteMr} />
                <br />
                <br />
                <br className='d-block d-md-none' />
                <br className='d-block d-md-none' />
                {error.quoteMr && <p className="errMsg">{error.quoteMr}</p>}
              </Form.Group>
            </Col>

            <Col md={12} className='off'>
              <Form.Group className='mb-3'>
                <Form.Label>Guru quote in hindi<span className='errMsg'>*</span></Form.Label>
                <ReactQuill
                  id="quoteHn"
                  style={{ height: 200 }}
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormats}
                  value={quoteHn}
                  onChange={setQuoteHn} />
                <br />
                <br />
                <br className='d-block d-md-none' />
                <br className='d-block d-md-none' />
                {error.quoteHn && <p className="errMsg">{error.quoteHn}</p>}
              </Form.Group>
            </Col>



          </Row>
        </Modal.Body>

        <Modal.Footer>
          {
            crudLoader ? <Spinner variant='primary' /> :
              <Button className='primaryBtn' onClick={editGuruQuote}>
                <Icon.ChatQuote className='me-1' />Update</Button>
          }
        </Modal.Footer>
      </Modal>

      {/* ------------------------------ DELETE Guru Modal ----------------------------------------------------------- */}
      <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => { setShowDel(false); setDelId(''); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='mb-0'>
              <Icon.ChatQuote size={20} className='me-2' />Delete Guru Quote
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='text-center'>
          <div data-aos='zoom-in'>
            <Icon.XCircleFill color='red' width={80} height={80} />
            <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this guru quote ?</h4>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {
            crudLoader ? <Spinner variant='primary' /> :
              <>
                <Button className='primaryBtn me-3' onClick={deleteGuru}>
                  <Icon.HandThumbsUp className='me-1' />Yes</Button>
                <Button className='secondaryBtn' onClick={() => { setShowDel(false); setDelId(''); }}>
                  <Icon.HandThumbsDown className='me-1' />No</Button>
              </>
          }
        </Modal.Footer>
      </Modal>


    </div>
  )
}

export default GuruQuote;