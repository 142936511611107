import React from 'react';
import { Container, Row} from 'react-bootstrap';
import PCMC from '../../Assets/PCMC-LOGO.png'

const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicy mt-5'>
        <Container>
            <Row>
                <img src={PCMC} alt="PCMC" style={{width:'100px', margin:'auto'}}/>
                <h3 className='mb-4'>Privacy Policy</h3>
                <p><strong>Intent :</strong> We know that you care how information about you is used and shared and we appreciate your trust in us to do that carefully and sensibly.</p>
                <p>This Privacy Policy explains how we, Pimpri Chinchwad Municipal Corporation (referred as “PCMC”, “we” or “us” or “our” in this document), collect, process and use information of our users (hereinafter addressed as “you”, “your”, “yourself”). We are associated mobile application and a provider of a range of services thereto. The website provides Information from various departments, projects and services rendered by Pimri Chinchwad Municipal Corporation. The contact details of PCMC Offices are also available here. Apart from this, we provide a platform where we may list offers for local services, goods and travel municipal service benefits which are made available by us or other sellers (collectively: “Sellers”). The content of the site is the result of collaborative effort of various departments of PCMC. It is our endeavor to continue the enhancement and enrichment of this site in terms of its content, coverage, design and technology on a regular basis. This Privacy Policy applies to information that we collect through our website, mobile application, electronic communications or services (collectively, the “Site”).</p>
                <p>We will routinely update this Privacy Policy to improve our practices and to reflect new or different privacy practices, such as when we add new services, functionality or features to the Site. You can determine when this version of the Privacy Policy was adopted by referring to the “Effective Date” above.</p>
                <p>By visiting this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree, please do not use or access our Website.</p>
                <p>By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.</p>

                <ul>
                    <li>Sensitive Personal Data and Information (SPDI)</li>
                    <p>Pursuant to the Information Technology Act, 2000, we are required to give a disclosure of the SPDI collected by us and how do we use it. We may collect and retain the following information from and about you if you interact with us through the Site:</p>
                    <ol type='1'>
                        <li>Your email address and Site password;</li>
                        <li>Your payment details, billing and delivery addresses, a credit / debit card number and a credit / debit card expiration date and/ or other payment instrument details;</li>
                        <li>Your phone/ mobile numbers;</li>
                        <li>Your location, website browsing patterns (e.g., through cookies) and purchase history;</li>
                        <li>Other information you actively submit to us or we can determine about you based on your interactions with our Site and services.</li>
                        <li>Register, subscribe, authorize the transfer of, or create an account with us;</li>
                        <li>Open or respond to emails;</li>
                        <li>Provide information to enroll or participate in programs provided on behalf of, or together with other Sellers, merchants, co-marketers, distributors, resellers and other business partners, with your consent or as necessary to provide services you have requested;</li>
                        <li>Visit any page online that displays our ads or content;</li>
                        <li>Purchase products or services on or through the Site;</li>
                        <li>Interact or connect with or link to the Site via integrated social networking tools; and</li>
                        <li>Post comments to the Site.</li>
                    </ol>
                </ul>
                <li>Managing User Choices</li>
                <ol type='1' className='ps-5'>
                    <li>You can manage the types of personal data you provide to us and can limit how we communicate with you.</li>
                    <li>You can manage your email and notice preferences by logging into your account through the Site or by adjusting the settings in our mobile application.</li>
                    <li>You can also manage your subscriptions by following subscription management instructions contained in any commercial emails that we send you.</li>
                    <li>You may update your subscription preferences at any time. Please note that even if you decide not to subscribe to, or to unsubscribe, from promotional email messages, we may still need to contact you with important transactional information related to your account and your purchases. For example, even if you have unsubscribed from our promotional email messages, we will still send you confirmations when you make purchases on the Site.</li>
                    <li>You hereby consent to receive communications by SMS or calls from us or our affiliates or its partners with regard to the services provided by us or as facilitated by our partners.</li>
                    <li>You may manage how your browser handles cookies by adjusting its privacy and security settings. Browsers are different, so refer to instructions related to your browser to learn about cookie-related and other privacy and security settings that may be available. Please refer to Paragraph 11 of this Policy for information of Cookies for more information.</li>
                    <li>You may also manage how your mobile device and mobile browser share information on and about your devices with us, as well as how your mobile browser handles cookies by adjusting the privacy and security settings on your mobile device. Please refer to instructions provided by your mobile service provider and the manufacturer of your device to learn how to adjust your settings.</li>
                    <li>You may also manage the sharing of certain personal data with us when you connect with us through social networking platforms or applications. Please refer to Paragraph 9 below and also the privacy policy and settings of the social networking websites or applications to determine how you may adjust our permissions and manage the interactivity between us and your social networking account or your mobile device.</li>
                    <li>If you register for customized email newsletters containing offers for local services, goods and travel, municipal services etc, we will work to make information more relevant for you and customize newsletters based on information you share with us, your location, website browsing preferences (for example, through cookies), purchase history or based on other attributes of your relationship with us. You can reject and delete cookies and unsubscribe from newsletters at any time by clicking links in each newsletter you wish to unsubscribe from.</li>
                </ol>

                <li>Use of Information</li>
                <ul>
                    <p className='ps-3 mb-0'>We process personal data for the following purposes:</p>
                    <ol type='1'>
                        <li>Operate, maintain and improve the Site;</li>
                        <li>Provide you with personalized information and updates related to city events, direct marketing initiatives via email and direct marketing offers;</li>
                        <li>Facilitate and process orders – for example, for vouchers and other goods and services;</li>
                        <li>Facilitate table reservation process;</li>
                        <li>Determine your eligibility for certain types of offers, products or services that may be of interest to you, and analyze advertising effectiveness;</li>
                        <li>Answer your questions and respond to your requests;</li>
                        <li>To establish and analyze individual and group profiles and customer behavior;</li>
                        <li>Communicate and provide additional information that may be of interest to you about us, the Sellers and our business partners;</li>
                        <li>Send you reminders, technical notices, updates, security alerts, support and administrative messages, service bulletins, marketing messages, and requested information, including on behalf of business partners;</li>
                        <li>Administer rewards, surveys, contests, or other promotional activities or events;</li>
                    </ol>

                    <li>Disclosure of Information</li>
                    <p className='ps-3 mb-0'>We are not in the business of selling or renting personal data. We will not share your personal data, except in the following manner:</p>
                    <ol type='1'>
                        <li>With the Sellers, so they can sell and deliver to you and provide such other ancillary services such as table reservation to serve you better;</li>
                        <li>To report or collect on payments owed to Sellers, merchants or other business partners;</li>
                        <li>As necessary to perform contractual obligations towards you with business partners to the extent you have purchased or redeemed our voucher, goods or services offered by a business partner or participated in an offer, rewards, contest or other activity or program sponsored or offered through us or the Sellers on behalf of a business partner;</li>
                        <li>To comply with legal orders and government requests, or as needed to support auditing, compliance;</li>
                        <li>To combat fraud or criminal activity, and to protect our rights or those of our affiliates, business partners and users, or as part of legal proceedings affecting us;</li>
                        <li>In response to a legal process, including to law enforcement agencies, regulators, and courts; or with your consent or as otherwise required or permitted by law.</li>
                    </ol>

                    <li>Security of Personal Information</li>
                    <p className='ps-3 mb-2'>We use strict security measures to ensure the security, integrity and privacy of Your Personal Information and to protect your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. For this purpose, we have adopted internal reviews of the data collection, storage and processing practices and security measures, including offering the use of a secure server to guard against unauthorized access to systems where we store your personal data.</p>
                    <p className='ps-3 mb-0'>We shall, however, not be responsible for any breach of security or for any actions of any third parties that receive Your Personal Information. The Website is also linked to many other sites and we are not/shall be not responsible for their privacy policies or practices as it is beyond our control.</p>

                    <li>User’s Rights in relation to Personal Data: By keeping your personal data updated you can help us to ensure that we can respect your preferences and offer you the goods and services that are most relevant to you.</li>
                    <p className='ps-3 mb-2'>While we are ready to assist you in managing your subscriptions, closing your account, and removing your active profile, we cannot always delete records of past interactions and transactions. For example, we may be required to retain records relating to previous purchases on the Site for financial reporting and compliance reasons.</p>

                    <li>Retention of Personal Data</li>
                    <p className='ps-3 mb-2'>You can at any time unsubscribe from our services, newsletters, e-mailers. We will, however, retain your personal data for as long as your account is active or as needed to provide you services and to maintain a record of your transactions for financial reporting or
                        other compliance purposes. We will also retain your personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

                    <li>Social Site Features</li>  
                    <ol type='1'>
                        <li>The Site may contain connections to areas where you may be able to publicly post information, communicate with others such as discussion boards or blogs, review products and merchants, and otherwise submit your own original content. Prior to posting in these areas, please read our Terms of Use carefully. All the information you post may be accessible to anyone with Internet access, and any information you include in your posting may be read, collected, and used by others.</li>
                        <li>Connecting through Social Networks: We offer social networking users the opportunity to interact with friends and to share on social networks. If you are logged into both the Site and a social network, when you use the Site’s social networking connection functions, you may connect your social network account with our account (this happens automatically, if the email addresses match). If the email addresses do not match, we ask you if you want to link them and you must validate that you control the accounts. If you are already logged into the Site but not logged into your social network site, when you use the Site’s social network connection functions, you will be prompted to enter your social network website credentials or to sign up for the social network.</li>
                        <li>If you are not currently registered as our user and you use the Site’s social network connection functions, you will first be asked to enter your social network credentials and then be given the option to register and join us. Once you register with us and connect with the social network, you will be able to automatically post our recent activity back to your social network. Please refer to the privacy settings in your social network account to manage the data that is shared through your account.</li>
                        <li>By using the Site’s social network connection function, you will grant us permission to access all of the elements of your social network profile information that you have made available to be shared (as per the settings chosen by you in your social network profile) and to use it in accordance with the social network’s terms of use and this Privacy Policy.</li>
                    </ol>

                    <li>Privacy Policies of Third Parties</li>
                    <p className='ps-3 mb-2'>This Privacy Policy only addresses the collection, processing and use (including disclosure) of information by us through your interaction with the Site. Other websites that may be accessible through links from the Site may have their own privacy policies and personal information collection, processing, use, and disclosure practices. Our business partners, may also have their own privacy policies. We encourage you to familiarize yourself with the privacy policies provided by these other parties prior to providing them with information or taking advantage of a sponsored offer or promotion.</p>    

                    <li>Cookies</li> 
                    <p className='ps-3 mb-2'>
                    Consent: By using our website or mobile app (“Site”), using services provided through the Site, or receiving our emails, you consent to the use of cookies in accordance with this “Cookies Policy”.</p>      
                    <p className='ps-3 mb-2'>Intent: This Privacy Policy sets out how we, and third parties we authorize, use cookies when you interact with our Site or the services offered via the Site. Our Privacy Policy explains what we do (and don't do) with the information, including any personal data we may collect about you. Please read them both.</p> 
                    <p className='ps-3 mb-2'>Meaning: Cookies are unique identifiers, usually made up of small bits of text or code. Cookies are usually stored on your device or in your web browser and they send certain information back to the party who served the cookie. When we say cookies we are actually referring to a broader range of technologies than just cookies, including web beacons, clear gifs, pixels and other similar technologies. When we say first-party cookies we mean cookies that are served by us. When we say third party cookies we mean cookies that are served by parties other than us.</p> 
                    <p className='ps-3 mb-2'>Use of Cookies: Cookies are used in order to better understand how applications and websites work and to optimize online and mobile experiences. Cookies do this by allowing the party serving the cookie to analyze a website’s or mobile app’s performance, to identify certain users anonymously and remember their preferences, to better understand whether a computer or device (and probably its user) has visited a website previously and to serve personalized advertisements.</p>
                </ul>
            </Row>
        </Container>
    </div>
  )
}

export default PrivacyPolicy;