import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import Select from 'react-select';
import ReactS3 from '../../../Utils/ReactS3';
import ReactQuill from 'react-quill';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import imageUploading from '../../../Assets/ImageUploading.json';
import { getAllCategories } from '../../Common/Categories';
import { useLocation, useNavigate } from "react-router-dom";
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';

const EditTeacher = () => {

    const userState = useSelector((state) => state?.cmsLogin?.userData?.data);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCategories({ payload: { categoryType: "course" }, token: userState?.token }))
    }, [])

    const getCategory = useSelector(state => state?.categories?.categoryList?.data);

    const categoryOptions = getCategory?.data?.length > 0 &&
        getCategory?.data !== null &&
        getCategory?.data !== undefined ?
        getCategory.data.map(itm =>
            ({ label: itm?.categoryName, value: itm?.categoryName })) : [];

    const convertArrayToSelectOptions = (array) => {
        if (!Array.isArray(array)) {
            console.error('Input is not an array');
            return [];
        }

        return array.map(item => ({ value: item, label: item }));
    };


    const navigate = useNavigate();

    const parseDateFromApi = (dateString) => {
        return dateString ? dateString.split('T')[0] : '';
    };


    const [createLoader, setCreateLoader] = useState(false);
    const [uploading, setUploading] = useState(false);

    const location = useLocation();
    const teacher = location.state;

    const [firstName, setFirstName] = useState(teacher.firstName);
    const [middleName, setMiddleName] = useState(teacher.middleName);
    const [lastName, setLastName] = useState(teacher.lastName);
    const [img, setImg] = useState(teacher.imageURL);
    const [qualification, setQualification] = useState(teacher.qualification);
    const [dob, setDob] = useState(parseDateFromApi(teacher.dob));
    const [courseCategory, setCourseCategory] = useState(convertArrayToSelectOptions(teacher.courseCategories));
    const [summary, setSummary] = useState(teacher.summary);
    const [fileEvent, setFileEvent] = useState('');
    const [error, setErrors] = useState({});

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imageUploading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const [s3Data, setS3Data] = useState({
        accessKey: "AKIA4GWLDQ2LFZGE32BJ",
        secretKey: "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",
        bucket: "familyguide",
        region: "ap-south-1",
    });

    // -- upload image
    const uploadImage = async (e, directory) => {
        if (e.target.files.length > 0) {
            setUploading(true);
            const file = e.target.files[0];
            let result = await ReactS3(file, s3Data, directory);
            setImg(result);
            setUploading(false);
        }
    }



    const addValidate = () => {
        let newErrors = {};

        if (!firstName) {
            newErrors.firstName = "*First name is required";
        }

        if (!lastName) {
            newErrors.lastName = "*lastName name is required";
        }

        if (!img) {
            newErrors.img = "*Please upload image";
        }

        if (!qualification) {
            newErrors.qualification = "*qualification is required";
        }

        if (!dob) {
            newErrors.dob = "*DOB required";
        }

        if (courseCategory.length === 0) {
            newErrors.courseCategory = "*Select at least one course category";
        }

        if (!summary.trim() || summary.trim() === '<p><br></p>') {
            newErrors.summary = "*Summary is required";
        }

        setErrors(newErrors);
        if (newErrors) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    }

    // update teacher async call
    const updateTeacher = async () => {
        const isValid = addValidate();
        if (isValid) {
            setCreateLoader(true);
            await fetch(process.env.REACT_APP_BASE_URL + '/course/updateTeacher', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify({
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    dob: dob,
                    qualification: qualification,
                    imageURL: img,
                    courseCategories: courseCategory?.map((itm) => itm.value),
                    summary: summary,
                    updatedBy: userState?.data?.user?.name,
                    courseTeacherId: teacher.courseTeacherId
                })
            }).then((res) => res.json()).then((response) => {
                if (response.message === "Teacher Updated Successfully") {
                    toast.success("Teacher Updated Successfully");
                    setCreateLoader(false);
                    navigate(-1);
                }
                setCreateLoader(false);
            }).catch((err) => {
                console.log("Error while updating teacher", err);
                setCreateLoader(false)
            })
        }
    }


    const handleReactS3UltraRes = (url) => {
        setImg(url);
    }


    return (
        <div className="AddTeacher outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className='backBtn w-auto' onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className='me-2' />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center'>
                    <Icon.PersonWorkspace size={20} className='me-2' />Update Teacher
                </h3>
                <Row className="mt-4">
                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>First Name<span className='errMsg'>*</span></Form.Label>
                            <Form.Control placeholder="Enter First Name" id="firstName" value={firstName}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) && e.target.value.trim()) {
                                        setFirstName(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setFirstName(e.target.value);
                                    }
                                }} />

                            {error.firstName && <p className="errMsg">{error.firstName}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control placeholder="Enter Middle Name" id="middleName" value={middleName}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) && e.target.value.trim()) {
                                        setMiddleName(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setMiddleName(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Last Name<span className='errMsg'>*</span></Form.Label>
                            <Form.Control placeholder="Enter last name" id="lastName" value={lastName}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) && e.target.value.trim()) {
                                        setLastName(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setLastName(e.target.value);
                                    }
                                }} />

                            {error.lastName && <p className="errMsg">{error.lastName}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3" id="img">
                            <Form.Label>Teacher Image<span>*</span></Form.Label> <br />

                            {!img ?
                                <>
                                    <label htmlFor="teacherImg" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="teacherImg"
                                        style={{ display: "none" }}
                                        onChange={(event) => setFileEvent(event)}
                                    />
                                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                                    {error.img && <p className="errMsg">{error.img}</p>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile m-1">
                                        <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => setImg('')} />
                                    </Card>
                                </div>
                            }

                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Qualification<span className='errMsg'>*</span></Form.Label>
                            <Form.Control placeholder="Enter qualification" id="qualification" value={qualification}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) && e.target.value.trim()) {
                                        setQualification(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setQualification(e.target.value);
                                    }
                                }} />

                            {error.qualification && <p className="errMsg">{error.qualification}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label><span className='errMsg'>*</span>Date of Birth</Form.Label>
                            <Form.Control max={new Date().toISOString().split('T')[0]}
                                type='date' id="dob" value={dob} onChange={(e) => {
                                    console.log(e.target.value)
                                    setDob(e.target.value)
                                }} />
                            {error.dob && <p className="errMsg">{error.dob}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Category<span>*</span></Form.Label>
                            <Select
                                isMulti={true}
                                id="courseCategory"
                                value={courseCategory}
                                onChange={setCourseCategory}
                                options={categoryOptions}
                                placeholder="Select Course Category"
                            />
                            {error.courseCategory && <p className="errMsg">{error.courseCategory}</p>}
                        </Form.Group>
                    </Col>


                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Summary<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                id="summary"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={summary}
                                onChange={(itm) => { setSummary(itm) }}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.summary && <p className="errMsg">{error.summary}</p>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-5'>
                    {
                        createLoader ? <center><Spinner variant='primary' /></center> :
                            <>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                                    <Button className='primaryBtn w-auto mx-auto' onClick={() => { updateTeacher() }}>
                                        <Icon.PersonAdd className='me-2' />Edit Teacher
                                    </Button>
                                </div>
                            </>

                    }
                </Row>
            </Container>

            {
                fileEvent &&
                <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
                    directory="TeacherImages" onFileUpload={handleReactS3UltraRes} />
            }
        </div>
    )
}

export default EditTeacher;