import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Modal, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import Select from 'react-select';
import ReactS3 from '../../../Utils/ReactS3';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import Pagination from '../../Common/Pagination';
import Lottie from 'react-lottie';
import imageUploading from '../../../Assets/ImageUploading.json';
import { useLocation, useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';


const CourseTopic = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { courseId, courseLessonId, courseTitleLang, lessonTitleLang } = location?.state

    const [searchField, setSearchField] = useState("");
    const [loader, setLoader] = useState(false);
    const [createLoader, setCreateLoader] = useState(false);
    const [uploading, setUploading] = useState('');
    const [topicData, setTopicData] = useState([]);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [publishCourseTopicId, setPublishCourseTopicId] = useState(null);

    // Unpublish video variables
    const [isUnpublish, setIsUnpublish] = useState(false);
    const [unpublishId, setUnpublishId] = useState("");


    const [showAdd, setShowAdd] = useState(false);
    const [delId, setDelId] = useState('');
    const [showDel, setShowDel] = useState(false);


    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();

    // Function to handle opening the publish modal
    const openPublishModal = (courseTopicId) => {
        setPublishCourseTopicId(courseTopicId);
        setIsPublishModalOpen(true);
    };

    // Function to handle closing the publish modal
    const closePublishModal = () => {
        setIsPublishModalOpen(false);
    };

    const handlePublishModalCancel = () => {
        setIsPublishModalOpen(false);
    };

    // Function to handle opening the unpublish modal
    const openUnpublishModal = (courseTopicId) => {
        setUnpublishId(courseTopicId);
        setIsUnpublish(true);
    };

    // Function to handle cancelling the unpublish modal
    const closeUnpublishModal = () => {
        setIsUnpublish(false);
    };


    useEffect(() => {
        getAllCourseTopic()
    }, [docPerPage, pageNo])

    // for get all course lesson data
    const getAllCourseTopic = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getTopicByCourseIdAndLessonIdD', {
            method: 'POST',
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
            body: JSON.stringify({
                courseLessonId: courseLessonId,
                documentsPerPage: docPerPage,
                title: searchField ? searchField : "",
                page: pageNo
            })
        })
            .then((res) => res.json())
            .then((response) => {
                if (response) {
                    setTopicData(response);
                    setNoOfPages(response?.noOfPages);
                }
                setLoader(false);
            }).catch((err) => {
                console.log("Err while getting users", err);
                setLoader(false);
            })
    }

    // for custome hook call
    const dependencies = [searchField ? searchField : ""];
    useDebouncedApiCall(getAllCourseTopic, dependencies);

    // for deleter courseLesson
    const deleteCourseTopic = async () => {
        setCreateLoader(true);

        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/courseTopic/deleteCourseTopic', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({ courseTopicId: delId })
            });
            const response = await result.json();
            if (response.message === 'Data deleted succussfully') {
                toast.success('CourseTopic Deleted Succussfully');
                setShowDel(false);
                setDelId('');
                setCreateLoader(false);
                getAllCourseTopic();
            }
            else {
                toast.error('Failed to delete, try again');
                setShowDel(false);
                setDelId('');
                setCreateLoader(false);
            }
        } catch (Err) {
            console.log("Err while deleting article", Err);
            setCreateLoader(false);
        }
    }


    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    // for toggle
    const handlePublishAction = async (courseTopicId, isPublish) => {
        try {
            setIsPublishModalOpen(false);
            setIsUnpublish(false);

            const result = await fetch(process.env.REACT_APP_BASE_URL + "/courseTopic/publishCourseTopics", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    courseTopicId: courseTopicId,
                    isPublish: isPublish
                }),
            });

            const response = await result.json();
            if (response) {
                getAllCourseTopic();
            } else {
                console.log("Error toggling publish status");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle confirming publish modal
    const handlePublishModalConfirm = async () => {
        await handlePublishAction(publishCourseTopicId, true);
    };

    // Function to handle confirming unpublish modal
    const handleUnpublishConfirm = async () => {
        await handlePublishAction(unpublishId, false);
    };

    return (
        <div className='Course'>
            <PublishModel
                isOpen={isPublishModalOpen}
                onClose={handlePublishModalCancel}
                onConfirm={handlePublishModalConfirm}
                title="Course topic"
                apiLoader={loader}
            />
            <UnPublishModel
                isOpen={isUnpublish}
                onClose={closeUnpublishModal}
                onConfirm={handleUnpublishConfirm}
                title="Course topic"
                apiLoader={loader}
            />
            <ToastContainer />
            <Container>
                <Row className='justify-content-end'>
                    <Col md={12} className='d-flex justify-content-between my-auto mt-3 mt-md-0'>
                        <Button className="backBtn" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />
                            Back
                        </Button>
                    </Col>
                </Row>

                <Row className='justify-content-between mt-2 mb-2'>
                    <Col md={4} className="mb-2 mb-sm-0">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search here"
                            name="search"
                            value={searchField}
                            onChange={(e) => {
                                if (e.target.value.trim()) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                } else if (e.target.value.length === 0) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                }
                            }}
                        ></input>
                    </Col>

                    <Col md={6} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
                        <Button className='primaryBtn' onClick={() => navigate("/dashboard/addtopic", { state: { courseId: courseId, courseLessonId: courseLessonId, courseTitleLang: courseTitleLang, lessonTitleLang: lessonTitleLang } })}>
                            <Icon.JournalPlus className='me-2' size={16} />Add Topic
                        </Button>
                    </Col>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Course Topic
                </h3>


                <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
                        <tr><th>Course Title :</th> <td>{courseTitleLang?.mr}</td> </tr>
                        <tr><th>Lesson Title :</th> <td>{lessonTitleLang?.mr}</td> </tr>

                        <table className='mt-2'>
                            <thead>
                                <th>Sr.</th>
                                <th>Image</th>
                                <th>Video</th>
                                <th >Topic Title</th>
                                <th >Topic Header</th>
                                {/* <th>noOfSession</th> */}
                                <th>Duration</th>
                                {/* <th>Content Type</th> */}
                                <th>Priority</th>
                                <th>Publish</th>
                                <th>Action</th>
                            </thead>
                            {loader ? (
                                <Skeleton rows={10} cols={11} key={Math.random() * 999999999} />
                            ) :
                                (
                                    topicData?.data !== null && topicData?.data !== undefined && topicData?.data?.length > 0 ? (
                                        topicData?.data?.map((itm, index) => {
                                            const { imageURL, videoURL, courseTitle, titleHeader, courseLessonTitle, title, titleLang, titleHeaderLang, duration, content, courseTopicId, srNo } = itm;
                                            return (
                                                <tr key={index}>
                                                    <td>{pageNo !== 1 ? (
                                                        <>
                                                            {' '}
                                                            {index + 1 + docPerPage * (pageNo - 1)}
                                                        </>
                                                    ) : (
                                                        <>{index + 1}</>
                                                    )}</td>
                                                    <td key={index} className='d-flex flex-column'>
                                                        {imageURL && (
                                                            <img src={itm?.imageURL} alt={`Course ${index + 1} Img`} className='mb-3' width={40} />
                                                        )}
                                                    </td>
                                                    <td>{videoURL?.length === 0 ?
                                                        (<Icon.SlashCircle size={35} color='red' />) :
                                                        <div className='d-flex flex-column'>{

                                                            <div key={index} className="mb-3">
                                                                <a target="_blank" href={videoURL}>
                                                                    <Icon.PlayBtnFill style={{ width: 40, height: 40 }} />
                                                                </a>
                                                            </div>

                                                        }
                                                        </div>}
                                                    </td>
                                                    <td style={{ width: '200px' }}>{titleLang?.mr}</td>
                                                    <td style={{ width: '200px' }}>{titleHeaderLang?.mr}</td>
                                                    <td>{duration ? duration : "-"}</td>
                                                    {/* <td>{content ? content.map((itm) => itm.type) : "-"}</td> */}

                                                    <td>{srNo}</td>
                                                    <td style={{ width: '120px' }}>
                                                        {itm.isPublish ? (
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#8FBC8F",
                                                                    borderRadius: "8px",
                                                                    padding: "4px 8px",
                                                                    color: "white",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => openUnpublishModal(itm.courseTopicId)}
                                                            >
                                                                Published
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="switch"
                                                                onClick={() => openPublishModal(itm.courseTopicId)}
                                                            >
                                                                <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                                                                <span className="slider round" onClick={() => openPublishModal(itm.courseTopicId)}></span>
                                                            </div>
                                                        )}

                                                    </td>

                                                    <td className="d-flex">
                                                        <img src={View} alt="View" className="icon me-3"
                                                            onClick={() => navigate("/dashboard/viewcoursetopic", { state: { rowData: itm } })} />

                                                        <img src={Edit} alt="Edit" className="icon me-3" onClick={() => navigate("/dashboard/edittopic", { state: { rowData: itm, courseId: courseId, courseLessonId: courseLessonId, courseTitleLang: courseTitleLang, lessonTitleLang: lessonTitleLang } })}
                                                        />

                                                        <img src={Delete} alt="Delete" className='icon'
                                                            onClick={() => { setShowDel(true); setDelId(courseTopicId); }} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <p className='noDataFound'>No Data Found</p>
                                    )
                                )
                            }

                        </table>
                    </div>
                </div>

                <Row>
                    <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                        <h6 className='text-start mb-0'>
                            Total - <strong>{topicData?.count}</strong>
                        </h6>
                    </Col>
                    <Col md={8} className='d-flex justify-content-end'>
                        <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                            onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
                    </Col>
                </Row>


                {/* ------------------------------ DELETE Course Lesson Modal ----------------------------------------------------------- */}
                <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => { setShowDel(false); setDelId(''); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className='mb-0'>
                                <Icon.JournalX size={20} className='me-2' />Delete CourseTopic
                            </h5>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='text-center'>
                        <div data-aos='zoom-in'>
                            <Icon.XCircleFill color='red' width={80} height={80} />
                            <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this course topic?</h4>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            createLoader ? <Spinner variant='primary' /> :
                                <>
                                    <Button className='primaryBtn me-3' onClick={deleteCourseTopic}>
                                        <Icon.HandThumbsUp className='me-1' />Yes</Button>
                                    <Button className='secondaryBtn' onClick={() => { setShowDel(false); setDelId(''); }}>
                                        <Icon.HandThumbsDown className='me-1' />No</Button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>


                {/* for toggle model */}
                <Modal
                    size="md"
                    show={isPublishModalOpen}
                    onHide={closePublishModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Publish</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to publish this Course Topic?
                    </Modal.Body>
                    <Modal.Footer>
                        {loader ? (
                            <Spinner animation="border" variant="primary" />
                        ) : (
                            <>
                                <Button className='primaryBtn me-3' onClick={handlePublishModalConfirm} >
                                    <Icon.HandThumbsUp className='me-1' />Yes</Button>
                                <Button className='secondaryBtn' onClick={handlePublishModalCancel} >
                                    <Icon.HandThumbsDown className='me-1' />No</Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>

            </Container>

        </div>
    )
}


export default CourseTopic;