import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Guru from './Guru';
import GuruQuote from './GuruQuote';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from "react-redux";

const GuruAndQuotes = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    // const [key, setKey] = useState(userState?.data?.user?.userType === "superadmin" ? "guru" : "guruQuote");
    const [key, setKey] = useState("guru");

    return (
        <div className='GuruAndQuotes outletPadding'>

            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <Nav variant="pills" className="flex-row justify-content-center mx-auto">
                    <Nav.Item>
                        <Nav.Link eventKey="guru">
                            <Icon.Person className='me-2' />Guru List</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="guruQuote">
                            <Icon.ChatQuote className='me-2' />Guru Quote</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="guru">
                        <Guru />
                    </Tab.Pane>
                    <Tab.Pane eventKey="guruQuote">
                        <GuruQuote />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>


        </div>
    )
}

export default GuruAndQuotes;