import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  Modal,
  Form
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Common/Pagination";
import Skeleton from "../../Common/Skeleton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import videoLogo from "../../../Assets/video-icon.svg";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import NoImage from '../../../Assets/NoImage.svg';
import ClearData from "../../Common/ClearData";
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey, setImgVerifyReport } from "../../Common/ActiveKeySlice";
import PublishModel from "../../Common/Model/PublishModel";
import UnPublishModel from "../../Common/Model/UnPublishModel";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";

const Video = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useSelector((state) => state?.cmsLogin?.userData?.data?.token);

  // Filter variables
  const [searchField, setSearchField] = useState("");
  const [selectedTags, setSelectedTags] = useState("");
  const [selectedContentType, setSelectedContentType] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  // States for Pagination
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState(0);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishContentId, setPublishContentId] = useState(null);
  const [apiLoader, setApiLoader] = useState(false);

  // Delete Article variables
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // State for content list and filtered content list
  const [contentList, setContentList] = useState([]);
  const [loader, setLoader] = useState(false);

  // Define state variables for managing filter modal visibility
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);

  // Function to open filter modal
  const openFilterModal = () => {
    setFilterModalOpen(true);
  };

  // Function to close filter modal
  const closeFilterModal = () => {
    setFilterModalOpen(false);
  };

  // Function to handle filter submission
  const handleFilterSubmit = () => {
    setPageNo(1);
    closeFilterModal();
    getVideoList();
  };

  // Function to reset filters
  const resetFilters = () => {
    setSelectedTags("");
    setSelectedContentType("");
    setPageNo(1);
    getVideoList();
  };

  useEffect(() => {
    if (contentList?.data) {
      const tags = [];
      const contentTypes = [];

      // Set the dropdown options state variables
      setTagsOptions(tags);
      setContentTypeOptions(contentTypes);
    }
  }, [contentList]);

  // Function to handle opening the publish modal
  const openPublishModal = (contentId) => {
    setPublishContentId(contentId);
    setIsPublishModalOpen(true);
  };


  // Function to handle cancelling the publish modal
  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (contentId) => {
    setUnpublishId(contentId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };

  // Function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() =>{
    getVideoList()
  },[pageNo, docPerPage])

  // Get all video list
  const getVideoList = async () => {
    try {
      setLoader(true);
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleFilter", {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: JSON.stringify({
          type: "Video",
          title: searchField,
          tags: selectedTags,
          contentType: selectedContentType,
          documentsPerPage: docPerPage,
          page: pageNo
        })
      });
      
      const response = await result.json();
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));
        dispatch(setImgVerifyReport(null));
        localStorage.removeItem('persist:root');
        navigate('/');
      } else if (response.status === 200) {
        setContentList(response);
        setNoOfPages(response?.noOfPages);
        setLoader(false);
      } else {
        setContentList([]);
        setLoader(false);
      }
    } catch (err) {
      console.log("Error while getting video list", err);
      setLoader(false);
    }
  };
  
  // for debounding
  const dependencies = [ searchField, selectedTags, selectedContentType];
  useDebouncedApiCall(getVideoList, dependencies);

  // Delete API ------
  const deleteContent = async () => {
    try {
      await fetch(process.env.REACT_APP_BASE_URL + "/content/deleteContent", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({
          contentId: deleteId,
        }),
      });
      setIsDelete(false);
      getVideoList();
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish or unpublish modal
  const handlePublishAction = async (contentId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/content/publishContent", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({
          contentId: contentId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getVideoList();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async (status) => {
    await handlePublishAction(publishContentId, status);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async (status) => {
    await handlePublishAction(unpublishId, status);
  };

  return (
    <div className="outletPadding">
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="video"
        apiLoader={apiLoader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="video"
        apiLoader={apiLoader}
      />
      {/* Add Content and Filter menu */}
      <Row className="justify-content-between mt-3 mt-md-0">
        <Col md={4} className="mb-2 mb-sm-0 d-flex justify-content-start align-items-center">
          <input
            className="form-control"
            type="text"
            placeholder="Search here"
            name="search"
            value={searchField}
            
            onChange={(e) => {
              if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
            }}
          />
          {/* <Button className="primaryBtn ms-2 p-2" onClick={openFilterModal}>
            <Icon.Funnel className='me-2' size={17} />
            Filter
          </Button> */}
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button className="primaryBtn" onClick={() => navigate("/dashboard/add-content")}>
            <Icon.PlusCircle className="me-2" size={15} />
            Add Video
          </Button>
        </Col>
      </Row>

      {/* Display video list */}
      <div className="outer-wrapper mx-auto mt-4">
        <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
          <table>
            {/* Table header */}
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Title</th>
                <th>Image URL</th>
                <th>Video URL</th>
                <th>Tags</th>
                <th>Content Type</th>
                <th>Publish</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Table body */}
              {loader ? (
                <Skeleton rows={10} cols={8} />
              ) : contentList?.data?.length > 0 ? (
                contentList?.data?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ width: "18vw" }}>{item?.titleLang?.mr}</td>
                    <td>
                      <a target="_blank" href={item?.imgURL}>
                        <img src={item?.imgURL} style={{ width: 60, height: 60 }} alt="Image" />
                      </a>
                    </td>
                    <td>
                      {
                        item?.videoURL ? <a target="_blank" href={item?.videoURL}>
                          <img src={videoLogo} alt="Video" style={{ width: 60, height: 60 }} />
                        </a> : <img src={NoImage} alt="No Video" width={60} />}
                    </td>
                    <td style={{ width: '250px' }}>{item?.tags?.join(', ')}</td>
                    <td>{item?.contentType}</td>
                    <td>
                      {item.isPublish ? (
                        <div
                          style={{
                            backgroundColor: "#8FBC8F",
                            borderRadius: "8px",
                            padding: "4px 8px",
                            color: "white",
                            textAlign: "center",
                            cursor: "pointer"
                          }}
                          onClick={() => openUnpublishModal(item.contentId)}
                        >
                          Published
                        </div>
                      ) : (
                        <div
                          className="switch"
                          onClick={() => openPublishModal(item.contentId)}
                        >
                          <input type="checkbox" checked={item.publish} onChange={() => { }} />
                          <span className="slider round" onClick={() => openPublishModal(item.contentId)}></span>
                        </div>
                      )}

                    </td>
                    <td className="d-flex">
                      <img src={View} alt="View" className="icon me-3"
                        onClick={() => navigate("/dashboard/viewcontentlist", {
                          state: { contentData: item }
                        })
                        } />

                      <img src={Edit} alt="Edit" className="icon me-3"
                        onClick={() => navigate("/dashboard/editcontentlist", {
                          state: item
                        })
                        }
                      />

                      <img src={Delete} alt="Delete" className='icon'
                        onClick={() => {
                          setIsDelete(true);
                          setDeleteId(item?.contentId);
                        }} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <Row>
        <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
          <h6 className='text-start mb-0'>
            <Icon.People className='me-2' />Total - <strong>{contentList?.count}</strong>
          </h6>
        </Col>
        <Col md={8} className='d-flex justify-content-end'>
          <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
            onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
        </Col>
      </Row>

      {/* Delete Content Modal */}
      <Modal
        size="md"
        show={isDelete}
        onHide={() => setIsDelete(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h5 className="mb-0">
            <Icon.TrashFill size={20} className="me-2" />
            Delete Video
          </h5>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <div data-aos='zoom-in'>
            <Icon.XCircleFill color='red' width={80} height={80} />
            <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this Video ?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            apiLoader ? <Spinner variant='primary' /> :
              <>
                <Button className='primaryBtn me-3' onClick={() => deleteContent()} >
                  <Icon.HandThumbsUp className='me-1' />Yes</Button>
                <Button className='secondaryBtn' onClick={() => setIsDelete(false)} >
                  <Icon.HandThumbsDown className='me-1' />No</Button>
              </>
          }
        </Modal.Footer>
      </Modal>

      {/* Filter Modal */}
      <Modal
        size="md"
        show={filterModalOpen}
        onHide={closeFilterModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* Tags filter dropdown */}
          <Form.Label>Tags</Form.Label>
          <select
            className="form-control mb-3"
            value={selectedTags}
            onChange={(e) => setSelectedTags(e.target.value)}
          >
            <option value="">Select Tags</option>
            {/* Map and render options for tags */}
            {tagsOptions.map((tag, index) => (
              <option key={index} value={tag.value}>{tag.label}</option>
            ))}
          </select>

          {/* Content Type filter dropdown */}
          <Form.Label> Content Type</Form.Label>
          <select
            id="contentTypeFilter"
            className="form-control mb-3"
            value={selectedContentType}
            onChange={(e) => setSelectedContentType(e.target.value)}
          >
            <option value="">Select Content Type</option>
            {/* Map and render unique options for content types */}
            {[...new Set(contentTypeOptions.map(contentType => contentType.value))].map((contentTypeValue, index) => {
              const contentType = contentTypeOptions.find(option => option.value === contentTypeValue);
              return (
                <option key={index} value={contentType.value}>{contentType.label}</option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primaryBtn" onClick={handleFilterSubmit}>Apply</Button>
          <Button className="secondaryBtn" onClick={resetFilters}>Reset</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Video;
