import React, { useState } from 'react';
import { Col, Form, Row, Button, Spinner, Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as Icon from "react-bootstrap-icons";
import ReactS3 from '../../../Utils/ReactS3';
import ReactQuill from "react-quill";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';

const AddCategory = () => {
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);

    const [error, setErrors] = useState({});
    const [categoryEn, setCategoryEn] = useState("")
    const [categoryMr, setCategoryMr] = useState("")
    const [categoryHn, setCategoryHn] = useState("")
    const [iconUrl, setIconUrl] = useState("")
    const [shortDescription, setShortDescription] = useState("")
    const [categoryType, setCategoryType] = useState("")
    const [loader, setLoader] = useState(false)
    const [fileEvent, setFileEvent] = useState('');


    const categoryTypes = [
        {
            label: "Course",
            value: "course",
        },
        {
            label: "Event",
            value: "event",
        },
        {
            label: "Product",
            value: "product",
        },
        {
            label: "Feed",
            value: "feed",
        },
        {
            label: "Article",
            value: "article",
        },
        {
            label: "Blog",
            value: "blog",
        },
        {
            label: "Video",
            value: "video",
        },
        {
            label: "Recipe",
            value: "recipe",
        },
        {
            label: "Guru",
            value: "guru",
        },
    ]

    const handleReactS3UltraRes = (url) => {
        setIconUrl(url);
    }

    const emptyState = () => {
        setCategoryEn("")
        setCategoryHn("")
        setCategoryMr("")
        setCategoryType("")
        setIconUrl("")
        setShortDescription("")
    }

    const validate = () => {
        let newErrors = {};

        if (!categoryEn) {
            newErrors.categoryEn = "*Category Name is Required";
        }

        if (!categoryMr) {
            newErrors.categoryMr = "*Category Name in Marathi is Required";
        }

        if (!iconUrl) {
            newErrors.iconUrl = "*Icon is Required"
        }

        if (!categoryType) {
            newErrors.categoryType = "*Category Type is Required";
        }
        if (!shortDescription) {
            newErrors.shortDescription = "*Short Description is Required";
        }

        // if (!priorityNo) {
        //   newErrors.priorityNo = "*Priority Number is Required";
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const addCategory = async () => {
        const isValid = validate();
        if (isValid) {
            let payload = {
                categoryName: categoryEn,
                categoryNameLang: {
                    en: categoryEn,
                    hin: categoryHn,
                    mr: categoryMr
                },
                iconURL: iconUrl,
                shortDescription: shortDescription,
                categoryType: categoryType.value,
                priortyNo: 100
            }

            await fetch(process.env.REACT_APP_BASE_URL + "/category/createCat", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "Cat Created Successfully") {
                        toast.success("Category Created Successfully !");
                        navigate("/dashboard/category")
                        emptyState();
                    } else if (response.message === "Cat already exist") {
                        toast.error("Category already exists.");
                    }
                }).catch((error) => {
                    console.log("error", error);
                })
        }
    }

    return (
        <>
            <div className="outletPadding">
                <Container>
                    <Row>
                        <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />Back
                        </Button>
                    </Row>

                    <h3 className='text-center d-flex justify-content-center align-items-center'>
                        <Icon.Clipboard2Plus size={20} className='me-2' />Add Category
                    </h3>
                    <Row className='mt-4'>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Category Name in English<span>*</span></Form.Label>
                                <Form.Control
                                    value={categoryEn}
                                    placeholder="Enter category"
                                    onChange={(e) => {
                                        // /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                                        if (!e.target.value.match(/[\u0900-\u097F]/)
                                            && e.target.value.trim()) {
                                            setCategoryEn(e.target.value);
                                        } else if (e.target.value.length === 0) {
                                            setCategoryEn(e.target.value);
                                        }
                                    }}
                                />
                                {error.categoryEn && <p className="error">{error.categoryEn}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Category Name in Marathi<span>*</span></Form.Label>
                                <Form.Control
                                    value={categoryMr}
                                    placeholder="श्रेणी प्रविष्ट करा"
                                    onChange={(e) => {
                                        const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                        if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                            setCategoryMr(e.target.value);
                                        } else if (e.target.value.length === 0) {
                                            setCategoryMr(e.target.value);
                                        }
                                    }}
                                />
                                {error.categoryMr && <p className="error">{error.categoryMr}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={4} className='off'>
                            <Form.Group className="mb-3">
                                <Form.Label>Category Name in Hindi</Form.Label>
                                <Form.Control
                                    value={categoryHn}
                                    placeholder="श्रेणी दर्ज करें"
                                    onChange={(e) => {
                                        const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                        if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                            setCategoryHn(e.target.value);
                                        } else if (e.target.value.length === 0) {
                                            setCategoryHn(e.target.value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Content Type<span>*</span></Form.Label>
                                <Select
                                    value={categoryType}
                                    options={categoryTypes}
                                    onChange={(option) => setCategoryType(option)}
                                />
                                {error.categoryType && <p className="error">{error.categoryType}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className="mb-3" id="img">
                                <Form.Label>Activity Image<span>*</span></Form.Label> <br />
                                {!iconUrl ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => setFileEvent(event)} />
                                        <p id='imgNote'>Recommended image resolution - 1:1</p>
                                        {error.iconUrl && <p className="errMsg">{error.iconUrl}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <img src={iconUrl} style={{ width: 60, height: 60 }} className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setIconUrl('')} />
                                        </Card>
                                    </div>
                                }
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Short Description<span>*</span></Form.Label>
                                <ReactQuill
                                    style={{ height: 150 }}
                                    theme="snow"
                                    modules={quillModules}
                                    formats={quillFormats}
                                    value={shortDescription}
                                    onChange={setShortDescription}
                                />
                                <br />
                                <br />
                                {error.shortDescription && <p className="error">{error.shortDescription}</p>}
                            </Form.Group>
                        </Col>
                    </Row>

                    {loader ? (<Spinner variant="primary" />) : (
                        <>
                            <div style={{ justifyContent: "center", alignItems: "center", display: "flex", marginBottom: "5%" }}>
                                <Button className="primaryBtn" onClick={addCategory}>
                                    <Icon.PlusCircle className="me-1" />Add category
                                </Button>
                            </div>
                        </>
                    )}

                </Container>
                {
                    fileEvent &&
                    <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
                        directory="image" onFileUpload={handleReactS3UltraRes} />
                }
            </div>
        </>
    )
}

export default AddCategory
