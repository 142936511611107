import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Common/Pagination';
import { useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import ClearData from '../../Common/ClearData';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey, setImgVerifyReport } from '../../Common/ActiveKeySlice';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';

const Activities = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishActivityId, setPublishActivityId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");


  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(20);
  const [noOfPages, setNoOfPages] = useState();


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }


  // Function to handle opening the publish modal
  const openPublishModal = (activityId) => {
    setPublishActivityId(activityId);
    setIsPublishModalOpen(true);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (activityId) => {
    setUnpublishId(activityId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };


  // Function to handle closing the publish modal
  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  useEffect(() => {
    getAllActivities()
  }, [docPerPage, pageNo])

  // ------- Get All Activities ----------------------
  const getAllActivities = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          title: "",
          type: "",
          startTime: "",
          endTime: "",
          tags: [],
          type: "Activity",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      if (responseData.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));
        dispatch(setImgVerifyReport(null));
        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(responseData);
        setNoOfPages(responseData?.noOfPages);
        setLoader(false);
      }

    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);
    }
  }

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllActivities, dependencies);


  const deleteActivity = async () => {
    setCreateLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/activity/deleteActivity', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({ activityId: delId })
      });
      const response = await result.json();
      if (response.message === 'Data deleted succussfully') {
        toast.success('Activity Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setCreateLoader(false);
        getAllActivities();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setCreateLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting article", Err);
      setCreateLoader(false);
    }
  }


  // for toggle
  const handlePublishAction = async (activityId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/activity/publishActivity", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          activityId: activityId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getAllActivities();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishActivityId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };



  return (
    <div className='Activities outletPadding'>
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Activities"
        apiLoader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Activities"
        apiLoader={loader}
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>

          <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => navigate('/dashboard/add-activity')}>
              <Icon.ClipboardPlus className='me-2' size={16} />Add Activity
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Image</th>
                <th>Title</th>
                <th>Tags</th>
                <th>Duration</th>
                <th>Start Time</th>
                <th>Point</th>
                <th>Type</th>
                {/* <th>Priority</th> */}
                <th>Publish</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={10} cols={10} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { imageURL, title, tags, timeInMin, activityDayTime, point, type, titleLang, activityId } = itm
                    return (

                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>
                            {' '}
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </>
                        ) : (
                          <>{index + 1}</>
                        )}</td>
                        <td><img src={imageURL} alt="" width={40} /></td>
                        <td style={{ width: '250px' }}>{titleLang?.mr}</td>
                        <td style={{ width: '250px' }}>{tags?.map((row) => (row)).join(', ')}</td>
                        <td>{timeInMin}</td>
                        <td>{activityDayTime}</td>
                        <td>{point}</td>
                        <td>{type}</td>
                        <td style={{ width: '120px' }}>
                          {itm.isPublish ? (
                            <div
                              style={{
                                backgroundColor: "#8FBC8F",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={() => openUnpublishModal(itm.activityId)}
                            >
                              Published
                            </div>
                          ) : (
                            <div
                              className="switch"
                              onClick={() => openPublishModal(itm.activityId)}
                            >
                              <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                              <span className="slider round" onClick={() => openPublishModal(itm.activityId)}></span>
                            </div>
                          )}

                        </td>
                        <td className="d-flex">
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/view-activities", {
                                state: {
                                  activities: itm,
                                },
                              })} />

                          <img src={Edit} alt="Edit" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/edit-activity", {
                                state: itm

                              })}
                          />

                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); setDelId(activityId); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
              <Icon.People className='me-2' />Total - <strong>{data?.count}</strong>
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>



        {/* ------------------------------ DELETE Activity Modal ----------------------------------------------------------- */}
        <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => { setShowDel(false); setDelId(''); }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='mb-0'>
                <Icon.XCircleFill size={20} className='me-2' />Delete Activity
              </h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className='text-center'>
            <div data-aos='zoom-in'>
              <Icon.XCircleFill color='red' width={80} height={80} />
              <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this activity ?</h4>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {
              createLoader ? <Spinner variant='primary' /> :
                <>
                  <Button className='primaryBtn me-3' onClick={deleteActivity}>
                    <Icon.HandThumbsUp className='me-1' />Yes</Button>
                  <Button className='secondaryBtn' onClick={() => { setShowDel(false); setDelId(''); }}>
                    <Icon.HandThumbsDown className='me-1' />No</Button>
                </>
            }
          </Modal.Footer>
        </Modal>

        {/* for toggle model */}
        <Modal
          size="md"
          show={isPublishModalOpen}
          onHide={closePublishModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Publish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to publish this Activity?
          </Modal.Body>
          <Modal.Footer>
            {loader ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                <Button className='primaryBtn me-3' onClick={handlePublishModalConfirm} >
                  <Icon.HandThumbsUp className='me-1' />Yes</Button>
                <Button className='secondaryBtn' onClick={handlePublishModalCancel} >
                  <Icon.HandThumbsDown className='me-1' />No</Button>
              </>
            )}
          </Modal.Footer>
        </Modal>



      </Container>

    </div>
  )
}

export default Activities