import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import PanchangaImg from '../../Assets/Panchanga.svg';
import { Link } from 'react-router-dom';
import { setActiveKey, setImgVerifyReport } from './ActiveKeySlice';
import { useNavigate } from "react-router-dom";
import { setLogout } from '../Auth/LoginSlice';
import ClearData from './ClearData';


const Home = () => {

  const userState = useSelector(state => state?.cmsLogin?.userData?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false);
  const [cards, setCards] = useState([]);

  var today = new Date();
  var curHr = today.getHours();

  const cardsData = [
    {
      title: "Panchang",
      count: 'panchang',
      activeData: { activeKey: 1, subActiveKey: 1.1, activeTab: 'Panchang' },
      route: "/dashboard",
      gradient: "linear-gradient(to right, #CBFFA1, #C5FF94, #C3FF83)",
      color: '#468700',
      image: PanchangaImg
    },
    {
      title: "Guru Quote",
      count: 'guruQuote',
      gradient: "linear-gradient(to right,#BBFFFF,  #A3FFFF, #9AFFFF)",
      color: '#006971',
      activeData: { activeKey: 12, subActiveKey: 12, activeTab: 'Guru And Quotes' },
      route: "/dashboard/guru-and-quote",
      image: PanchangaImg
    },
    {
      title: "Video",
      count: 'content',
      gradient: "linear-gradient(to right, #FFA996, #FF9D83, #FF9472)",
      color: '#8a0e00',
      activeData: { activeKey: 14, subActiveKey: 14, activeTab: 'Video List' },
      route: "/dashboard/content-list",
      image: PanchangaImg
    },
    {
      title: "Activity",
      count: 'activity',
      gradient: "linear-gradient(to right, #F1ACFF, #ED92FF, #E67AFC)",
      color: '#62006e',
      activeData: { activeKey: 8, subActiveKey: 8, activeTab: 'Activities' },
      route: "/dashboard/activities",
      image: PanchangaImg
    },
    {
      title: "Product",
      count: 'shopee',
      gradient: "linear-gradient(to right, #CBFFA1, #C5FF94, #C3FF83)",
      color: '#468700',
      activeData: { activeKey: 9, subActiveKey: 9, activeTab: 'Product' },
      route: "/dashboard/shoppee",
      image: PanchangaImg
    },
    {
      title: "Article",
      count: 'article',
      gradient: "linear-gradient(to right,#BBFFFF,  #A3FFFF, #9AFFFF)",
      color: '#006971',
      activeData: { activeKey: 6, subActiveKey: 6, activeTab: 'Article List' },
      route: "/dashboard/articleList",
      image: PanchangaImg
    },
    {
      title: "Event",
      count: 'Event',
      gradient: "linear-gradient(to right, #FFA996, #FF9D83, #FF9472)",
      color: '#8a0e00',
      activeData: { activeKey: 7, subActiveKey: 7, activeTab: 'Event List' },
      route: "/dashboard/eventList",
      image: PanchangaImg
    },
    {
      title: "Course",
      count: 'course',
      gradient: "linear-gradient(to right, #F1ACFF, #ED92FF, #E67AFC)",
      color: '#62006e',
      activeData: { activeKey: 10, subActiveKey: 10, activeTab: 'Course' },
      route: "/dashboard/course",
      image: PanchangaImg
    }
  ];


  useEffect(() => {
    getCounts();
  }, [])

  const getCounts = async () => {
    setLoader(true);
    try {
      const res = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getCounts',
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${userState?.token}`
          },
        });
      const response = await res.json();
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        // return <ClearData />
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));
        dispatch(setImgVerifyReport(null));
        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setCards(response?.data);
        setLoader(false);
      }


    } catch (Err) {
      console.log("Err while getting counts", Err.message);
      setLoader(false);
    }
  }


  // for navigation
  const handleCardClick = (row) => {
    dispatch(
      setActiveKey({
        activeKey: row?.activeData?.activeKey,
        subActiveKey: row?.activeData?.subActiveKey,
        activeTab: row?.activeData?.activeTab,
      })
    );
    navigate(row?.route);
  };


  return (
    <div className='Home outletPadding'>
      <Container>

        <Row className='d-flex justify-content-between  align-items-center mb-4'>
          <Col md={4}>
            <h6 className='mb-1'>Hello{userState?.userType_name}, {curHr < 12 ? <>Good Morning<Icon.BrightnessHighFill className='ms-2 me-1' /></>
              : curHr < 18 ? <>Good Afternoon<Icon.BrightnessAltHighFill className='ms-2 me-1' size={20} /></> :
                <>Good Evening<Icon.MoonStars className='ms-2 me-1' /></>}
            </h6>
            <h3 className='mb-0'>Welcome to dashboard</h3>
          </Col>
        </Row>


        <Row className='mt-4'>
          {

            cardsData?.map((row, index) => {
              return (
                <Col key={index} xs={12} md={4} xl={3} data-aos="fade-up" data-aos-delay={100 * (index + 1)} className='mb-5'>
                  <Card onClick={() => handleCardClick(row)}>
                    <Card.Body style={{ background: row?.gradient }}>
                      {loader ? <Spinner animation="grow" variant="secondary" className='mb-2' /> :
                        <h4 style={{ color: row?.color }}>{cards[row?.count]}</h4>
                      }
                      <h5>{row?.title}</h5>
                    </Card.Body>
                    <div className="card-image" style={{ backgroundImage: `url(${row?.image})` }}></div>
                  </Card>
                </Col>
              )
            })
          }
        </Row>


        {/* {
              loader ? <Skeleton rows={6} Col={6}/> : 
                <Row className='mt-5'>
                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Panchanga</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.panchang}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Guru Quote</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.guruQuote}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Content</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.content}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Activity</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.activity}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Shopee</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.shopee}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Article</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.article}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Event</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.Event}</h5>                     
                    </div>
                  </Col>

                  <Col md={4} className='mb-3'>
                    <div style={{border:"1px solid black",padding:"15px", borderRadius:"20px"}}>
                      <h4 style={{textAlign:"center"}}>Course</h4>
                      <h5 style={{textAlign:"center"}}>{counts?.course}</h5>                     
                    </div>
                  </Col>
                </Row>
            } */}


      </Container>
    </div >
  )
}

export default Home;