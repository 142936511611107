import { React, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Skeleton from '../../Common/Skeleton';
import moment from 'moment';
import Pagination from '../../Common/Pagination';
import View from '../../../Assets/View.svg';
import { useNavigate } from 'react-router-dom';

const NotSubscriptionUser = () => {
  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const navigate = useNavigate()

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  const [loader, setLoader] = useState(false)
  const [unSubscriptionUsers, setUnSubscriptionUsers] = useState([])
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    getSubscriptionUsers()
  }, [docPerPage, pageNo, searchField ? searchField : ""])

  const getSubscriptionUsers = async () => {
    setLoader(true)
    await fetch(process.env.REACT_APP_BASE_URL + '/cms/getUnsubscribedUsers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
      body: JSON.stringify({
        name: searchField ? searchField : "",
        documentsPerPage: docPerPage,
        page: pageNo
      })
    })
      .then((response) => response.json())
      .then((res) => {
        setUnSubscriptionUsers(res?.data)
        setNoOfPages(res?.data?.noOfPages);
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        console.log("error", error);
      })
  }

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  return (
    <div className='mt-5'>
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4}>
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            />
          </Col>

          {/* <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
          <Button className='primaryBtn' onClick={() => setShowAdd(true)}>
            <Icon.PersonAdd className='me-2' size={15} />Add User
          </Button>
        </Col> */}
        </Row>

        <Row className='mt-3'>
          <h5>Total Count : <span>{unSubscriptionUsers?.count}</span></h5>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Date of Birth</th>
              </thead>
              {
                loader ? <Skeleton rows={8} cols={8} /> :
                  unSubscriptionUsers?.data !== null && unSubscriptionUsers?.data !== undefined && unSubscriptionUsers?.data?.length > 0 ? unSubscriptionUsers?.data?.map((itm, index) => {
                    const { fullName, mobile, dob } = itm
                    return (
                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                        <td>{fullName}</td>
                        <td>{mobile}</td>
                        <td>{moment(dob).format("DD-MM-YYYY")}</td>
                        <td className="d-flex">
                          {/* <img src={View} alt="View" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/dashboard/view-notsubscription-user", {
                                state: {
                                  user: itm,
                                },
                              })} /> */}
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
              {/* <Icon.People className='me-2' />Total - <strong>{data?.count}</strong> */}
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NotSubscriptionUser
