import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Modal, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';


const EditCourseLesson = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { rowData, courseId, courseTitleLang } = location?.state

    const [createLoader, setCreateLoader] = useState(false);
    const [srno, setSrNo] = useState(rowData?.srNo || '');
    const [title, setTitle] = useState(rowData?.titleLang?.en || '');
    const [titleMr, setTitleMr] = useState(rowData?.titleLang?.mr || '');
    const [titleHn, setTitleHn] = useState(rowData?.titleLang?.mr || '' || '');
    const [titleHeaderEn, setTitleHeaderEn] = useState(rowData?.titleHeaderLang?.en || '');
    const [titleHeaderMr, setTitleHeaderMr] = useState(rowData?.titleHeaderLang?.mr || '');
    const [description, setDescription] = useState(rowData?.descriptionLang?.en || '');
    const [descriptionMr, setDescriptionMr] = useState(rowData?.descriptionLang?.mr || '');
    const [descriptionHn, setDescriptionHn] = useState(rowData?.descriptionLang?.hin || '');
    const [shortdescription, setShortDescription] = useState(rowData?.shortDescriptionLang?.en);
    const [shortdescriptionMr, setShortDescriptionMr] = useState(rowData?.shortDescriptionLang?.mr);
    const [shortdescriptionHn, setShortDescriptionHn] = useState(rowData?.shortDescriptionLang?.hn);
    const [error, setErrors] = useState({});

    const createValidate = () => {
        let newErrors = {};

        if (!srno) {
            newErrors.srNo = "*Lesson number is required";
        }
        if (!title) {
            newErrors.title = "*Title in english is required";
        }
        if (!titleMr) {
            newErrors.titleMr = "*Title in Marathi is required";
        }
        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }

        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }

        if (!description.trim() || description.trim() === '<p><br></p>') {
            newErrors.description = "*Description in english is required";
        }
        if (!descriptionMr.trim() || descriptionMr.trim() === '<p><br></p>') {
            newErrors.descriptionMr = "*Description in Marathi is required";
        }
        if (!shortdescription.trim() || shortdescription.trim() === '<p><br></p>') {
            newErrors.shortdescription = "*Short Description in english is Required";
        }

        if (!shortdescriptionMr.trim() || shortdescriptionMr.trim() === '<p><br></p>') {
            newErrors.shortdescriptionMr = "* Short Description in Marathi is Required"
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const editCourseLesson = async () => {
        const isValid = createValidate();

        if (isValid) {
            setCreateLoader(true);

            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL + '/courseLesson/updateCourseLesson', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userState.data.token}`
                    },
                    body: JSON.stringify({
                        courseId: courseId,
                        courseLessonId: rowData?.courseLessonId,
                        courseTitle: rowData?.courseTitle,
                        courseTitleLang: {
                            en: courseTitleLang.en ? courseTitleLang.en : "",
                            mr: rowData?.courseTitle ? rowData?.courseTitle : '',
                            hin: courseTitleLang.hin ? courseTitleLang.hin : ''
                        },
                        title: titleMr,
                        titleLang: { en: title, mr: titleMr, hin: titleHn },
                        titleHeader: titleHeaderMr,
                        titleHeaderLang: { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderMr },
                        description: descriptionMr,
                        descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
                        shortDescription: shortdescriptionMr,
                        shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                        srNo: srno,
                        createdBy: userState?.data?.user?.name
                    })
                });

                const data = await response.json();

                if (response.ok) {
                    if (data.message === "Updated Successfully") {
                        toast.success("Updated Successfully");
                        navigate("/dashboard/courselesson", { state: { courseId: data?.data?.courseId, courseTitleLang: data?.data?.courseTitle } });
                    }
                } else {
                    throw new Error(data.message || 'Failed to update course lesson');
                }
            } catch (error) {
                console.error("Error While Updating CourseLesson", error);
                toast.error("Failed to update course lesson");
            } finally {
                setCreateLoader(false);
            }
        }
    };



    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Edit Lesson
                </h3>
                <Row className='mt-4' >
                    <Col md={3} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control value={rowData?.courseTitle} disabled />
                        </Form.Group>
                    </Col>

                    {courseTitleLang?.mr && <Col md={3}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title in Marathi</Form.Label>
                            <Form.Control value={courseTitleLang?.mr} disabled />
                        </Form.Group>
                    </Col>}

                    {courseTitleLang?.hin &&
                        <Col md={3} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Course Title in Hindi</Form.Label>
                                <Form.Control value={courseTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>}

                    <Col md={1}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lesson Number<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Sr.No"
                                value={srno}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setSrNo(e.target.value);
                                    }
                                }}
                            />
                            {error.srNo && <p className="errMsg">{error.srNo}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title in english" id="" value={title} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitle(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitle(e.target.value);
                                }
                            }} />
                            {error.title && <p className="errMsg">{error.title}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>


                    {/* 
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Lesson Images<span>*</span></Form.Label> <br />
                            <>
                                {
                                    uploading === "courseImages" ? <Lottie options={defaultOptions} className='mx-auto' width={120} /> :
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />
                                            Upload Image
                                        </label>
                                }
                                <input
                                    type="file"
                                    accept="image/jpeg, image/png, image/gif"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={(event) => uploadImage(event, "courseImages")}
                                />
                                {error.courseImages && <div className="errMsg">{error.courseImages}</div>}
                                <br />
                                {courseImages.length > 0 && courseImages !== null && courseImages !== undefined ? (
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        {courseImages.map((row, index) => {
                                            return (
                                                <Card className="uploadedFile m-1">
                                                    <img src={row} style={{ width: 60, height: 60 }} className='mx-auto' />
                                                    <Icon.XCircleFill className='removeIcon' size={18}
                                                        onClick={() => remove(index, "courseImages")} />

                                                </Card>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </>
                        </Form.Group>
                    </Col> */}

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescription}
                                onChange={(e) => setShortDescription(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} >
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionMr}
                                onChange={(e) => setShortDescriptionMr(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group className="mb-5">
                            <Form.Label>Short Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionHn}
                                onChange={(e) => setShortDescriptionHn(e)}
                            />
                            <br />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Full Description in English<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={description}
                                onChange={(itm) => { setDescription(itm) }}
                            />
                            <br />
                            <br />
                            {error.description && <p className="errMsg">{error.description}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='mb-3'>
                        <Form.Group >
                            <Form.Label>Full Description in Marathi<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionMr}
                                onChange={(itm) => { setDescriptionMr(itm) }}
                            />
                            <br />
                            <br />
                            {error.descriptionMr && <p className="errMsg">{error.descriptionMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group >
                            <Form.Label>Full Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionHn}
                                onChange={(itm) => { setDescriptionHn(itm) }}
                            />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                            <Button className='primaryBtn mx-auto w-auto' onClick={() => editCourseLesson()}>
                                <Icon.JournalPlus className='me-2' />Edit Lesson
                            </Button>
                        </div>
                }
            </Container>
        </div>
    )
}

export default EditCourseLesson
