import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCategories } from "../../Common/Categories";
import imageUploading from "../../../Assets/ImageUploading.json"
import ReactS3Ultra from "../../../Utils/ReactS3Ultra";
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';
import CloudeFlareVideoUpload from "../../../Utils/CloudeFlareVideoUpload";
window.Buffer = window.Buffer || require("buffer").Buffer;


const AddVideo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userState = useSelector((state) => state?.cmsLogin?.userData?.data?.user);
    const token = useSelector((state) => state?.cmsLogin?.userData?.data?.token);
    const getData = useSelector(state => state?.categories?.categoryList?.data)

    const [allTeachers, setAllTeachers] = useState([]);
    const [author, setAuthor] = useState(userState?.fullName);
    //Add Content variables
    const [apiLoader, setApiLoader] = useState(false);
    // Error variables
    const [error, setErrors] = useState({});

    const [titleEn, setTitleEn] = useState('');
    const [titleMr, setTitleMr] = useState('');
    const [titleHn, setTitleHn] = useState('');
    const [category, setCategory] = useState("")
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionMr, setDescriptionMr] = useState("");
    const [descriptionHn, setDescriptionHn] = useState("");
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [tags, setTags] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [videotype, setVideoType] = useState("")
    const [type, setType] = useState("")
    const [fileEventVideoImg, setFileEventVideoImg] = useState('');
    const [VideoImage, setVideoImage] = useState('');
    const [Video, SetVideo] = useState('');
    // Dropdown variables
    const [TagList, setTagList] = useState([]);
    const [tagCategoryList, setTagCategoriesList] = useState([]);

    useEffect(() => {
        getTagCategoryList();
        dispatch(getAllCategories({ payload: { categoryType: "feed" }, token: token }));
        getAllTeacher();
    }, []);

    const getAllTeacher = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTeacher', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({
                    courseTeacherId: '',
                    courseCategories: ''
                })
            })
            const response = await res.json();
            let arr = [];
            response.data.map((item) => {
                arr.push({
                    ...item,
                    label: item.firstName + " " + item.lastName,
                    value: item.courseTeacherId,
                });
            });
            setAllTeachers(arr);
        }
        catch (Err) {
            console.log("Err while getting all teacher", Err);
        }
    }

    const getContentCategory = getData?.data?.length > 0 && getData?.data !== null && getData?.data !== undefined ? getData.data.map(itm => ({ label: itm?.categoryName, value: itm?.categoryName })) : [];

    useEffect(() => {
        if (selectedCategory !== "") {
            getTagNameList();
        }
    }, [selectedCategory]);

    const handleChange = (selected) => {
        setTags(selected);
    };

    const handlerVideoTypeChange = (selectedOption) => {
        setVideoType(selectedOption)
    }

    // for get content type
    const videoTypeOptions = [
        { value: 'video', label: 'Video' },
        { value: 'recipe_video', label: 'Recipe Video' }
    ];

    // for get  type
    const TypeOptions = [
        { value: true, label: 'Landscape' },
        { value: false, label: 'Portrait' }
    ];


    // for tagcategorylist
    const getTagCategoryList = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTagCat", {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.status === 200) {
                    let arr = [];
                    response.data.map((item) => {
                        arr.push({
                            ...item,
                            label: item.tagCategoryName,
                            value: item.tagCategoryName,
                        });
                    });
                    setTagCategoriesList(arr);
                } else {
                    setTagCategoriesList([]);
                }
            }).catch((error) => {
                console.log("Error:", error)
            })
    };

    // for tagname kist
    const getTagNameList = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + `/cms/getAllTags?tagCatId=${selectedCategory.tagCategoryId}`,
            {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            }
        )
            .then((res) => res.json())
            .then((response) => {
                if (response?.status === 200) {
                    let arr = [];
                    response.data.map((item) => {
                        arr.push({
                            ...item,
                            label: item.tag,
                            value: item.tag,
                        });
                    });
                    setTagList(arr);
                } else {
                    setTagList([]);
                }
            }).catch((error) => {
                console.log("Error:", error)
            })
    };

    // for validation
    const validate = () => {
        let newErrors = {};

        if (!titleEn) {
            newErrors.titleEn = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*मराठीत शीर्षक आवश्यक आहे";
        }

        //  if (!titleHn) {
        //     newErrors.titleHn = "*शीर्षक हिन्दी में आवश्यक है";
        // }

        if (!VideoImage) {
            newErrors.VideoImage = "*Please upload at least one image";
        }

        if (!Video) {
            newErrors.Video = "*Please upload at least one video";
        }

        if (!category) {
            newErrors.category = "*Please select content category";
        }

        if (!selectedCategory) {
            newErrors.selectedCategory = "*Please Select Tag category";
        }

        if (tags.length === 0) {
            newErrors.tags = "*At least Select one Tag for Content";
        }
        if (videotype.length === 0) {
            newErrors.videotype = "*At least Select one type for Content";
        }
        if (type.length === 0) {
            newErrors.type = "*At least Select one video type for Content";
        }

        if (!descriptionEn) {
            newErrors.descriptionEn = "*Description in english is Required";
        }

        if (!descriptionMr) {
            newErrors.descriptionMr = "*Description in marathi is Required";
        }

        //  if (!descriptionHn) {
        //     newErrors.descriptionMr = "*Description in hindi is Required";
        // }


        if (!shortdescription) {
            newErrors.shortdescription = "* Short Description in english is Required";
        }

        if (!shortdescriptionMr) {
            newErrors.shortdescriptionMr = "* Short Description in marathi is Required"
        }

        setErrors(newErrors);
        if (newErrors) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    };

    // for add video
    const AddVideo = async (status) => {
        const isvalid = validate();

        if (isvalid) {
            setApiLoader(true);
            let payload = {
                contentType: videotype?.value,
                title: titleMr,
                titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                description: descriptionMr,
                descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
                shortDescription: shortdescriptionMr,
                shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                imgURL: VideoImage?.join(", "),
                imgURLLang: { en: VideoImage?.join(", "), mr: VideoImage?.join(", "), hin: "" },
                videoURL: Video?.join(", "),
                videoURLLang: { en: Video?.join(", "), mr: Video?.join(", "), hin: "" },
                categories: selectedCategory.label,
                tagsCategory: selectedCategory?.label,
                courseTeacherId: author,
                priorityNo: 100,
                tags: tags.map((item) => item.label),
                isHome: true,
                createdById: userState.name,
                contentCategory: category?.label,
                isPublish: status,
                isLandscape: type?.value,
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/content/createContent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(payload),
                });

                if (response) {
                    const responseData = await response.json();
                    if (responseData.message === 'Content Created Successfully') {
                        toast.success('Content Created Successfully');
                        navigate("/dashboard/content-list");
                        setApiLoader(false);

                    }
                    else if (responseData.message === "Content already exist") {
                        toast.error('Content already exist');
                        setApiLoader(false);
                    }
                    else {
                        throw new Error(responseData.message || 'Failed to create content');
                    }
                } else {
                    throw new Error('Network response was not ok.');
                }
            } catch (error) {
                console.error("Error while creating content:", error);
                setApiLoader(false);
            }
        }
    }

    const remove = (index, directory) => {
        let arr = [];

        switch (directory) {
            case "VideoImage":
                arr = [...VideoImage];
                arr.splice(index, 1);
                setVideoImage(arr);
                break;
            case "Video":
                arr = [...Video];
                arr.splice(index, 1);
                SetVideo(arr);
                break;
        }
    };

    //React Select
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const handleReactS3UltraVideoImg = (url) => {
        if (url) {
            let arr = [];
            arr = [...VideoImage];
            arr.push(url);
            setVideoImage(arr);
        }
    }



    const uploadOnCloudFlare = async (data) => {
        let arr = [];
        arr = [...Video];
        arr.push(data?.preview);
        SetVideo(arr);
    }




    return (
        <div className="AddVideo outletPadding">
            <ToastContainer />
            <Container>

                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center'>
                    <Icon.PlayBtn size={24} className='me-2' />Add Video
                </h3>


                <Row className="mt-4">
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control
                                id="titleEn"
                                placeholder="Enter Title"
                                value={titleEn}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control
                                id="titleMr"
                                placeholder="शीर्षक प्रविष्ट करा"
                                value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }}

                            />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className="off">
                        <Form.Group className="mb-3">
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control
                                id="titleHn"
                                placeholder="शीर्षक दर्ज करें"
                                value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" id="VideoImage">
                            <Form.Label>Video Image<span>*</span></Form.Label> <br />
                            {VideoImage?.length === 0 ?
                                <>
                                    <label htmlFor="fileInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />
                                        Upload Image
                                    </label>

                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            setFileEventVideoImg(event);
                                        }} />
                                    <p id='imgNote'>Recommended image resolution - 240*240 px</p>
                                    {error.VideoImage && <p className="errMsg">{error.VideoImage}</p>}
                                </>
                                :

                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    {VideoImage.map((row, index) => {
                                        return (
                                            <Card className="uploadedFile my-1 me-3">
                                                <img src={row} style={{ width: 60, height: 60 }} className='mx-auto' />
                                                <Icon.XCircleFill className='removeIcon' size={18}
                                                    onClick={() => remove(index, "VideoImage")} />
                                            </Card>
                                        );
                                    })}
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" id="Video">
                            <Form.Label>Video<span>*</span></Form.Label> <br />
                            {
                                Video?.length === 0 ?
                                    <>
                                        <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} />
                                        {error.Video && <p className="errMsg">{error.Video}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        {Video?.map((row, index) => {
                                            return (
                                                <Card className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                                    <a target="_blank" href={row}>
                                                        <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                                    </a>
                                                    <Icon.XCircleFill className='removeIcon' size={18}
                                                        onClick={() => remove(index, "Video")} />
                                                </Card>
                                            );
                                        })}
                                    </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Feed Category<span>*</span></Form.Label>
                            <Select
                                id="category"
                                value={category}
                                options={getContentCategory}
                                onChange={(option) => setCategory(option)}
                            />
                            {error.category && <p className="errMsg">{error.category}</p>}
                        </Form.Group>
                    </Col>


                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Type<span>*</span></Form.Label>
                            <Select
                                value={videotype}
                                onChange={handlerVideoTypeChange}
                                options={videoTypeOptions}
                            />
                            {error.videotype && (
                                <p className="errMsg">{error.videotype}</p>
                            )}
                        </Form.Group>
                    </Col>


                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tag Category<span>*</span></Form.Label>
                            <Select
                                id="selectedCategory"
                                isMulti={false}
                                options={tagCategoryList}
                                value={selectedCategory}
                                onChange={(option) => {
                                    setSelectedCategory(option);
                                }}
                            />
                            {error.selectedCategory && (
                                <p className="errMsg">{error.selectedCategory}</p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Add Tags Name<span>*</span></Form.Label>
                            <ReactSelect
                                id="tags"
                                isDisabled={selectedCategory === ""}
                                options={TagList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={handleChange}
                                allowSelectAll={true}
                                value={tags}
                            />
                            {error.tags && <p className="errMsg">{error.tags}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Video Type<span>*</span></Form.Label>
                            <Select
                                value={type}
                                onChange={(option) => setType(option)}
                                options={TypeOptions}
                            />
                            {error.type && (
                                <p className="errMsg">{error.type}</p>
                            )}
                        </Form.Group>
                    </Col>


                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescription}
                                onChange={(e) => setShortDescription(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} >
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionMr}
                                onChange={(e) => setShortDescriptionMr(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group className="mb-5">
                            <Form.Label>Short Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionHn}
                                onChange={(e) => setShortDescriptionHn(e)}
                            />
                            <br />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                id="descriptionEn"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionEn}
                                onChange={setDescriptionEn}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.descriptionEn && (
                                <p className="errMsg">{error.descriptionEn}</p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                id="descriptionMr"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionMr}
                                onChange={setDescriptionMr}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.descriptionMr && (<p className="errMsg">{error.descriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className="off">
                        <Form.Group className="mb-3">
                            <Form.Label>Full Description in Hindi</Form.Label>
                            <ReactQuill
                                id="descriptionHn"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionHn}
                                onChange={setDescriptionHn}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.descriptionHn && (<p className="errMsg">{error.descriptionHn}</p>)}
                        </Form.Group>
                    </Col>
                </Row>


                {apiLoader ? (
                    <Spinner variant="primary" />
                ) : (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>

                        <Button className="primaryBtn me-4" onClick={() => AddVideo(false)}>
                            <Icon.Save className="me-1" />
                            Save
                        </Button>

                        <Button className="primaryBtn" onClick={() => AddVideo(true)}>
                            <Icon.JournalArrowUp className="me-1" />
                            Save & Publish
                        </Button>
                    </div>
                )}
            </Container>

            {
                fileEventVideoImg &&
                <ReactS3Ultra fileEvent={fileEventVideoImg} fileType={process.env.REACT_APP_IMAGE}
                    directory="VideoImage" onFileUpload={handleReactS3UltraVideoImg} />
            }

            {/* {
                fileEventVideo &&
                <ReactS3Ultra fileEvent={fileEventVideo} fileType={process.env.REACT_APP_VIDEO}
                    directory="Video" onFileUpload={handleReactS3UltraVideo} />
            } */}

        </div>
    );
};

export default AddVideo;
