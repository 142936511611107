import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Modal, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import imageUploading from "../../../Assets/ImageUploading.json";
import { useSelector } from 'react-redux';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';


const AddLesson = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { courseId, courseLessonId, courseTitleLang } = location?.state


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imageUploading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const [s3Data, setS3Data] = useState({
        accessKey: "AKIA4GWLDQ2LFZGE32BJ",
        secretKey: "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",
        bucket: "familyguide",
        region: "ap-south-1",
    });


    const [createLoader, setCreateLoader] = useState(false);
    const [uploading, setUploading] = useState('');

    const [title, setTitle] = useState('');
    const [titleMr, setTitleMr] = useState('');
    const [titleHn, setTitleHn] = useState('');
    const [titleHeaderMr, setTitleHeaderMr] = useState('');
    const [titleHeaderEn, setTitleHeaderEn] = useState('');
    const [courseImages, setCourseImages] = useState('');
    const [duration, setDuration] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionMr, setDescriptionMr] = useState('');
    const [descriptionHn, setDescriptionHn] = useState('');
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [srno, setSrNo] = useState('');
    const [error, setErrors] = useState({});


    const createValidate = () => {
        let newErrors = {};

        if (!srno) {
            newErrors.srNo = "*Sr.No is required";
        }
        if (!title) {
            newErrors.title = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*Title in Marathi is required";
        }

        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }

        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }

        if (!description) {
            newErrors.description = "*Description in English is required";
        }
        if (!descriptionMr) {
            newErrors.descriptionMr = "*Description in Marathi is required";
        }
        if (!shortdescription) {
            newErrors.shortdescription = "* Short Description in English is Required";
        }

        if (!shortdescriptionMr) {
            newErrors.shortdescriptionMr = "* Short Description in Marathi is Required"
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    // for create course lesson model
    const createCourseLesson = async (status) => {
        const isValid = createValidate();
        if (isValid) {
            setCreateLoader(true);

            await fetch(process.env.REACT_APP_BASE_URL + '/courseLesson/createCourseLesson', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    courseId: courseId,
                    courseTitle: courseTitleLang,
                    courseTitleLang: {
                        en: courseTitleLang.en ? courseTitleLang.en : "",
                        mr: courseTitleLang ? courseTitleLang : '',
                        hin: courseTitleLang.hin ? courseTitleLang.hin : ''
                    },
                    title: titleMr,
                    titleLang: { en: title, mr: titleMr, hin: titleHn },
                    titleHeader: titleHeaderMr,
                    titleHeaderLang: { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderMr },
                    description: descriptionMr,
                    descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
                    shortDescription: shortdescriptionMr,
                    shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                    srNo: srno,
                    duration: duration,
                    imageURL: courseImages,
                    createdBy: userState?.data?.user?.name,
                    isPublish: status
                })
            })
                .then((res) => res.json())
                .then((response) => {

                    if (response.message === "Course Lesson Created Successfully") {
                        toast.success("CourseLesson Added Successfully");
                        navigate("/dashboard/courselesson", { state: { courseId: response?.data?.courseId, courseTitleLang: response?.data?.courseTitle } })
                        setCreateLoader(false);
                    }
                    else if (response.message === "CourseLesson already exist") {
                        toast.error("This CourseLesson already exist");
                        setCreateLoader(false);
                    }
                })
                .catch((Err) => {
                    console.log("Error While Creating CourseLesson", Err);
                    setCreateLoader(false);
                })
        }
    }



    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Add Lesson
                </h3>
                <Row className='mt-4'>
                    <Col md={3} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control value={courseTitleLang?.en} disabled />
                        </Form.Group>
                    </Col>

                    {courseTitleLang && <Col md={3}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title in Marathi</Form.Label>
                            <Form.Control value={courseTitleLang} disabled />
                        </Form.Group>
                    </Col>}

                    {courseTitleLang?.hin &&
                        <Col md={3} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Course Title in Hindi</Form.Label>
                                <Form.Control value={courseTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>}

                    <Col md={1}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Sr.No<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Sr.No"
                                value={srno}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setSrNo(e.target.value);
                                    }
                                }}
                            />
                            {error.srNo && <p className="errMsg">{error.srNo}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title in english" id="" value={title} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitle(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitle(e.target.value);
                                }
                            }} />
                            {error.title && <p className="errMsg">{error.title}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescription}
                                onChange={(e) => setShortDescription(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} >
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionMr}
                                onChange={(e) => setShortDescriptionMr(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group className="mb-5">
                            <Form.Label>Short Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionHn}
                                onChange={(e) => setShortDescriptionHn(e)}
                            />
                            <br />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                    <Col md={12} >
                        <Form.Group className='mb-3'>
                            <Form.Label>Full Description in English<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={description}
                                onChange={(itm) => { setDescription(itm) }}
                            />
                            <br />
                            <br />
                            {error.description && <p className="errMsg">{error.description}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='mb-3'>
                        <Form.Group >
                            <Form.Label>Full Description in Marathi<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionMr}
                                onChange={(itm) => { setDescriptionMr(itm) }}
                            />
                            <br />
                            <br />
                            {error.descriptionMr && <p className="errMsg">{error.descriptionMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group >
                            <Form.Label>Full Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionHn}
                                onChange={(itm) => { setDescriptionHn(itm) }}
                            />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                                <Button className="primaryBtn me-4" onClick={() => createCourseLesson(false)}>
                                    <Icon.Save className="me-1" />
                                    Save
                                </Button>

                                <Button className="primaryBtn" onClick={() => createCourseLesson(true)}>
                                    <Icon.JournalArrowUp className="me-1" />
                                    Save & Publish
                                </Button>
                            </div>
                        </>

                }
            </Container>
        </div>
    )
}

export default AddLesson
