import React, { useState } from "react";
import { Button, Card, Col, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import * as tus from "tus-js-client";
import * as Icon from "react-bootstrap-icons";

const CloudeFlareVideoUpload = ({  onFileUpload, onFileRemove}) => {

  const [videoFile, setVideoFile] = useState([]);
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);

  const videoUpload = (event) => {
    setLoader(true);

    const file = event.target.files[0];
    const size = file.size;

    var mediaId = null;

    const options = {
      endpoint:
        "https://api.cloudflare.com/client/v4/accounts/58db9bdcf02c34029bb6f6ee22884a1c/stream",
      headers: {
        Authorization: "Bearer HBmSpPaezVQdgGAwzF85HcRLKiauKNTlHgz3C-uQ",
      },
      chunkSize: 5 * 1024 * 1024, // 5MB chunk size
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        name: file.name,
        filetype: file.type,
      },
      uploadSize: size,
      onError: function (error) {
        console.error("Upload error:", error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setProgress(percentage);
        console.log("Upload progress:", percentage + "%");
      },
      onSuccess: function () {
        fetchVideo(mediaId);
        // Additional logic after successful upload
      },
      onAfterResponse: function (req, res) {
        return new Promise((resolve) => {
          const mediaIdHeader = res.getHeader("stream-media-id");
          if (mediaIdHeader) {
            mediaId = mediaIdHeader;
          }
          resolve();
        });
      },
    };

    if (file.type.split('/')[0] !== "video") {
      toast.error("Please upload video file");
    }
    else {
      const upload = new tus.Upload(file, options);
      upload.start();
    }

  };

  const UploadProgressBar = ({ }) => {
    return (
      <div className="upload-progress">
        <p style={{ fontSize: '13px', marginBottom: '4px' }}>Uploading...</p>
        <div className="progress-circle">
          <ProgressBar
            variant="custom"
            now={progress}
            label={`${Math.round(progress)}%`}
            className="circular-progress-bar"
          />
        </div>
      </div>
    );
  };

  const fetchVideo = async (mediaId) => {
    // Replace with the mediaId obtained from tus-js-client upload
    const apiUrl = `https://api.cloudflare.com/client/v4/accounts/58db9bdcf02c34029bb6f6ee22884a1c/stream/${mediaId}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: "Bearer HBmSpPaezVQdgGAwzF85HcRLKiauKNTlHgz3C-uQ", // Replace with your Cloudflare API token
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch video information");
      }

      const data = await response.json();
      // Assuming the video URL is available in the response
      const videoUrl = data.result.preview;
      console.log("data", data.result);
      let arr = [];
      arr = [...videoFile];
      arr.push(data?.result);
      setVideoFile(arr);
      onFileUpload(data.result);


      // You need to return this VideoFile to your main component

      setTimeout(() => {
        setLoader(false);
      }, 3000);

    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };


  const remove = (index) => {
    let arr = [];
    arr = [...videoFile];
    arr.splice(index, 1);
    setVideoFile(arr);
    onFileRemove(index);
  };


  return (
    <>
      {loader ?
        <UploadProgressBar progress={progress} /> :
        <label htmlFor="uploadVideo" id="uploadBtn">
          <Icon.CloudArrowUp size={20} className="me-2" /> Upload Video
        </label>
      }

      <input type="file" accept="video/*" id="uploadVideo" style={{ display: "none" }}
        onChange={(event) => { videoUpload(event); }} />


    </>
  );
};

export default CloudeFlareVideoUpload;
