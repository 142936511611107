import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Lottie from 'react-lottie';
import { Container, Row, Col, Button, Form, Modal, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import ReactS3 from "../../../Utils/ReactS3";
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCategories } from '../../Common/Categories';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';


const AddCourse = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const getData = useSelector(state => state?.categories?.categoryList?.data);

    const [error, setErrors] = useState({});
    // usestate
    const [titleEn, setTitleEn] = useState('');
    const [titleMr, setTitleMr] = useState('');
    const [titleHn, setTitleHn] = useState('');
    const [titleHeaderEn, setTitleHeaderEn] = useState('');
    const [titleHeaderMr, setTitleHeaderMr] = useState('');
    const [courseImages, setCourseImages] = useState("");
    const [bannerImages, setBannerImages] = useState("");
    const [lessons, setLessons] = useState('');
    const [duration, setDuration] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionMr, setDescriptionMr] = useState('');
    const [descriptionHn, setDescriptionHn] = useState('');
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [createLoader, setCreateLoader] = useState(false);
    const [category, setCategory] = useState("");
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState([]);
    const [fileEventBanner, setFileEventBanner] = useState('');
    const [fileEvent, setFileEvent] = useState('');
    const [coursetype, setCourseType] = useState('')

    // for get course type
    const courseTypeOption = [
        { value: "course", label: "Course" },
        { value: "agnihotra", label: "Agnihotra" }]


    const handleReactS3UltraRes = (url) => {
        setCourseImages(url);
    }

    const handleReactS3UltraResBanner = (url) => {
        setBannerImages(url)
    }

    // for category list
    const getContentCategory = getData?.data?.length > 0 &&
        getData?.data !== null &&
        getData?.data !== undefined ?
        getData.data.map(itm =>
            ({ label: itm?.categoryName, value: itm?.categoryName })) : [];

    useEffect(() => {
        dispatch(getAllCategories({ payload: { categoryType: "course" }, token: userState?.data?.token }));
    }, []);

    useEffect(() => {
        getCourseTeacher();
    }, [])


    // for get all teacher
    const getCourseTeacher = async () => {

        await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTeacher", {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.data?.token}` },
            body: JSON.stringify({ courseCategories: category?.label })
        })
            .then((res) => res.json())
            .then((response) => {

                if (response && response.data) {
                    const uniqueTeachers = response.data.map(teacher => ({
                        label: `${teacher.firstName} ${teacher.lastName}`,
                        value: teacher.courseTeacherId

                    }));
                    setTeachers(uniqueTeachers);

                } else {
                    setTeachers([]);
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    };


    // for validation
    const createValidate = () => {
        let newErrors = {};

        if (!titleEn) {
            newErrors.titleEn = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*Title in marathi is required";
        }

        //  if (!titleHn) {
        //     newErrors.titleHn = "*Title in hindi is required";
        // }

        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }
        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }

        if (!bannerImages) {
            newErrors.bannerImages = "*Select Atleast one image";
        }
        if (!courseImages) {
            newErrors.courseImages = "*Please upload at least Banner Images";
        }

        if (!lessons) {
            newErrors.lessons = "*No of lessons is required";
        }

        if (!duration) {
            newErrors.duration = "*duration in min is required";
        }

        if (!category) {
            newErrors.category = "*Please Course Select Category";
        }

        if (selectedTeacher.length === 0) {
            newErrors.author = "*Author name is required";
        }

        if (!coursetype) {
            newErrors.coursetype = "*Select Atleast onecourse type";
        }

        if (!description.trim() || description.trim() === '<p><br></p>') {
            newErrors.description = "*Description in english is required";
        }

        if (!descriptionMr.trim() || descriptionMr.trim() === '<p><br></p>') {
            newErrors.descriptionMr = "*Description in Marathi is required";
        }

        if (!shortdescription.trim() || shortdescription.trim() === '<p><br></p>') {
            newErrors.shortdescription = "* Short Description in English is Required";
        }

        if (!shortdescriptionMr.trim() || shortdescriptionMr.trim() === '<p><br></p>') {
            newErrors.shortdescriptionMr = "* Short Description in Marathi is Required"
        }
        setErrors(newErrors);
        if (newErrors) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    }

    // add course
    const createCourse = async (status) => {
        const isValid = createValidate();
        const authorNames = selectedTeacher ? selectedTeacher.map(teacher => teacher.label) : [];
        if (isValid) {
            setCreateLoader(true);
            await fetch(process.env.REACT_APP_BASE_URL + '/course/createCourse', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    title: titleMr,
                    titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                    titleHeader: titleHeaderMr,
                    titleHeaderLang: { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderMr },
                    imageURL: courseImages,
                    bigImageURL: bannerImages,
                    author: authorNames,
                    noOfLesson: lessons,
                    duration: duration,
                    typeOfCourse: "course",
                    categories: category?.label,
                    description: descriptionMr,
                    descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
                    shortDescription: shortdescriptionMr,
                    shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                    teacherId: selectedTeacher?.map((ele) => ele.value),
                    createdBy: userState?.data?.user?.name,
                    isPublish: status,
                    typeOfCourse: coursetype?.value
                })
            }).then((res) => res.json()).then((response) => {
                if (response.message === "Course Created Successfully") {
                    toast.success("Course Added Successfully");
                    setCreateLoader(false);
                    navigate("/dashboard/course")
                }
                else if (response.message === "Course already exist") {
                    toast.error("This course already exist");
                    setCreateLoader(false);
                }
            }).catch((Err) => {
                console.log("Error While Creating Course", Err);
                setCreateLoader(false);
            })
        }
    }


    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>

                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Add Course
                </h3>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title" id="titleEn" value={titleEn} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitleEn(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleEn(e.target.value);
                                }
                            }} />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="titleMr" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="titleHn" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                            {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" id="img">
                            <Form.Label>Image<span>*</span></Form.Label> <br />
                            {!bannerImages ?
                                <>
                                    <label htmlFor="bannerInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="bannerInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => setFileEventBanner(event)} />
                                    <p id='imgNote'>Recommanded image resolution - 4:3</p>
                                    {error.bannerImages && <div className="errMsg">{error.bannerImages}</div>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile m-1">
                                        <img src={bannerImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => setBannerImages('')} />
                                    </Card>
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Banner Image<span>*</span></Form.Label> <br />
                            <>
                                {!courseImages ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => setFileEvent(event)} />
                                        <p id='imgNote'>Recommanded image resolution -320*256px(2:1)</p>
                                        {error.courseImages && <p className="error">{error.courseImages}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <img src={courseImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setCourseImages('')} />
                                        </Card>
                                    </div>
                                }
                            </>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>No Of Lessons<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter no of lessons" id="lessons" value={lessons} onChange={(e) => {
                                if (e.target.value.match(/^[0-9]+$/)) {
                                    setLessons(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setLessons(e.target.value);
                                }
                            }} />
                            {error.lessons && <p className="errMsg">{error.lessons}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Duration (Min)<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter duration in Min" id="duration" value={duration} onChange={(e) => {
                                if (e.target.value.match(/^[0-9]+$/)) {
                                    setDuration(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setDuration(e.target.value);
                                }
                            }} />
                            {error.duration && <p className="errMsg">{error.duration}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Category<span>*</span></Form.Label>
                            <Select
                                id="category"
                                value={category}
                                onChange={(option) => { setCategory(option); setSelectedTeacher('') }}
                                options={getContentCategory}
                                placeholder="Select Feed Category"
                            />
                            {error.category && <p className="errMsg">{error.category}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Author<span>*</span></Form.Label>
                            <Select
                                id="author"
                                isDisabled={!category}
                                isMulti={true}
                                value={selectedTeacher}
                                onChange={(option) => setSelectedTeacher(option)}
                                options={teachers}
                                placeholder="Select Author"
                            />
                            {error.author && <p className="errMsg">{error.author}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Course Type<span>*</span></Form.Label>
                            <Select
                                value={coursetype}
                                onChange={(option) => setCourseType(option)}
                                options={courseTypeOption}
                            />
                            {error.coursetype && (
                                <p className="errMsg">{error.coursetype}</p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescription}
                                onChange={(e) => setShortDescription(e)}
                            />
                            <br />
                            <br />

                            {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionMr}
                                onChange={(e) => setShortDescriptionMr(e)}
                            />
                            <br />
                            <br />

                            {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group className="mb-5">
                            <Form.Label>Short Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionHn}
                                onChange={(e) => setShortDescriptionHn(e)}
                            />
                            <br />
                            <br />

                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Full Description in English<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={description}
                                onChange={(itm) => { setDescription(itm) }}
                            />
                            <br />
                            <br />
                            {error.description && <p className="errMsg">{error.description}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='mb-3'>
                        <Form.Group >
                            <Form.Label>Full Description in Marathi<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionMr}
                                onChange={(itm) => { setDescriptionMr(itm) }}
                            />
                            <br />
                            <br />
                            {error.descriptionMr && <p className="errMsg">{error.descriptionMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group >
                            <Form.Label>Full Description in Hindi<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionHn}
                                onChange={(itm) => { setDescriptionHn(itm) }}
                            />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>
                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                                <Button className="primaryBtn me-4" onClick={() => createCourse(false)}>
                                    <Icon.Save className="me-1" />
                                    Save
                                </Button>

                                <Button className="primaryBtn" onClick={() => createCourse(true)}>
                                    <Icon.JournalArrowUp className="me-1" />
                                    Save & Publish
                                </Button>
                            </div>
                        </>

                }
            </Container>

            {
                fileEvent &&
                <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
                    directory="courseImages" onFileUpload={handleReactS3UltraRes} type="bannerImages" />
            }


            {
                fileEventBanner &&
                <ReactS3Ultra fileEvent={fileEventBanner} fileType={process.env.REACT_APP_IMAGE}
                    directory="banner" onFileUpload={handleReactS3UltraResBanner} type="courseBigImage" />
            }
        </div>
    )
}

export default AddCourse
