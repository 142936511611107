import React, { useState, useEffect } from 'react';

// Custom hook for debouncing API calls
function useDebouncedApiCall(apiCall, dependencies, delay = 2000) {
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (!initialLoad) {
            const delayDebounce = setTimeout(() => {
                apiCall();
            }, delay);

            return () => clearTimeout(delayDebounce);
        } else {
            apiCall();
            setInitialLoad(false);
        }
    }, dependencies);

    return initialLoad;
}

export default useDebouncedApiCall;