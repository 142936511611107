import React, { useEffect, useState } from "react";
import {
  Container, Row, Col,
  Form,
  Button,
  Spinner,
  Modal,
  InputGroup,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Common/Pagination";
import Skeleton from "../../Common/Skeleton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import ClearData from "../../Common/ClearData";
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey, setImgVerifyReport } from "../../Common/ActiveKeySlice";
import PublishModel from "../../Common/Model/PublishModel";
import UnPublishModel from "../../Common/Model/UnPublishModel";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";

const ArticleList = () => {
  const navigate = useNavigate();

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const dispatch = useDispatch()

  //List variables
  const [articleList, setArticleList] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [searchField, setSearchField] = useState("");

  // Delete Article variables
  const [isdelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [apiLoader, setApiLoader] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishArticleId, setPublishArticleId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState();

  // Function to handle opening the publish modal
  const openPublishModal = (articleId) => {
    setPublishArticleId(articleId);
    setIsPublishModalOpen(true);
  };

  // Function to handle closing the publish modal
  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };
  // Function to handle opening the unpublish modal
  const openUnpublishModal = (articleId) => {
    setUnpublishId(articleId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };



  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  useEffect(() => {
    getArticleList()
  }, [docPerPage, pageNo])

  // get article data
  const getArticleList = async () => {
    setListLoader(true);

    await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleFilter", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        documentsPerPage: docPerPage,
        page: pageNo,
        type: "Article",
        title: searchField ? searchField : "",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Authorization failed / Forbidden") {
          setListLoader(true);
          dispatch(setLogout(null));
          dispatch(setActiveKey(null));
          dispatch(setImgVerifyReport(null));
          localStorage.removeItem('persist:root');
          navigate('/');
        } else if (response.status === 200) {
          setArticleList(response);
          setNoOfPages(response?.noOfPages);
        } else {
          setArticleList([]);
        }
        setListLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setListLoader(false);
      });
  };

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getArticleList, dependencies);

  // delete API
  const deleteArticle = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/article/deleteArticle", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        articleId: deleteId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Data deleted succussfully") {
          toast.success("Article Deleted Successfully !")
          setIsDelete(false);
          getArticleList();
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for toggle
  const handlePublishAction = async (articleId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/article/publishArticle", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          articleId: articleId,
          isPublish: isPublish,
        }),
      });

      const response = await result.json();
      if (response) {
        getArticleList();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishArticleId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };


  return (
    <div className="outletPadding">
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="article"
        apiLoader={apiLoader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="article"
        apiLoader={apiLoader}
      />
      <Container>
        {/* Add Article and Filter menu */}
        <Row className="justify-content-between">
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }

              }}
            ></input>
          </Col>

          <Col
            md={2}
            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
          >
            <Button className="primaryBtn" onClick={() => navigate("/dashboard/add-article")}>
              <Icon.PlusCircle className="me-2" size={15} />
              Add Article
            </Button>
          </Col>
        </Row>

        {/* Article List Table */}
        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Title</th>
                <th>Tags</th>
                <th>Feed Category</th>
                <th>Publish</th>
                <th>Action</th>
              </thead>
              {listLoader ? (
                <Skeleton rows={10} cols={6} />
              ) : articleList?.data !== undefined && articleList?.data !== null && articleList?.data?.length > 0 ? (
                articleList?.data?.map((itm, index) => {
                  return (
                    <tr key={index}>
                      <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                      <td style={{ width: "18vw" }}>{itm?.titleLang?.mr}</td>
                      <td style={{ width: "20vw" }}>{itm?.tags.toString()}</td>
                      <td>{itm?.contentCategory}</td>
                      <td>
                        {itm?.isPublish ? (
                          <div
                            style={{
                              backgroundColor: "#8FBC8F",
                              borderRadius: "8px",
                              padding: "4px 8px",
                              color: "white",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                            onClick={() => openUnpublishModal(itm.articleId)}
                          >
                            Published
                          </div>
                        ) : (
                          <div
                            className="switch"
                            onClick={() => openPublishModal(itm.articleId)}
                          >
                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                            <span className="slider round" onClick={() => openPublishModal(itm?.articleId)}></span>
                          </div>
                        )}

                      </td>

                      <td className="d-flex">
                        <img src={View} alt="View" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/view-article", {
                              state: {
                                article: itm,
                              },
                            })
                          } />

                        <img src={Edit} alt="Edit" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/edit-article", {
                              state: {
                                article: itm,
                              },
                            })
                          }
                        />

                        <img src={Delete} alt="Delete" className='icon'
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(itm?.articleId);
                          }} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>
        </div>

        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>Total - <strong>{articleList?.count}</strong>
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>
      </Container>

      {/* for toggle model */}
      <Modal
        size="md"
        show={isPublishModalOpen}
        onHide={closePublishModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Publish</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to publish this Article?
        </Modal.Body>
        <Modal.Footer>
          {apiLoader ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button className='primaryBtn me-3' onClick={handlePublishModalConfirm} >
                <Icon.HandThumbsUp className='me-1' />Yes</Button>
              <Button className='secondaryBtn' onClick={handlePublishModalCancel} >
                <Icon.HandThumbsDown className='me-1' />No</Button>
            </>
          )}
        </Modal.Footer>
      </Modal>


      {/* Delete Article */}
      <Modal
        size="md"
        show={isdelete}
        onHide={() => setIsDelete(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h5 className="mb-0">
            <Icon.TrashFill size={20} className="me-2" />
            Delete Article
          </h5>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <div data-aos='zoom-in'>
            <Icon.XCircleFill color='red' width={80} height={80} />
            <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this article ?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            apiLoader ? <Spinner variant='primary' /> :
              <>
                <Button className='primaryBtn me-3' onClick={() => deleteArticle()} >
                  <Icon.HandThumbsUp className='me-1' />Yes</Button>
                <Button className='secondaryBtn' onClick={() => setIsDelete(false)} >
                  <Icon.HandThumbsDown className='me-1' />No</Button>
              </>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ArticleList;
