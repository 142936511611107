import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Modal, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import ReactS3 from '../../../Utils/ReactS3';
import ReactQuill from 'react-quill';
import Lottie from 'react-lottie';
import imageUploading from "../../../Assets/ImageUploading.json";
import { useSelector } from 'react-redux';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';
import CloudeFlareVideoUpload from '../../../Utils/CloudeFlareVideoUpload';

const EditTopic = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const location = useLocation()
    const navigate = useNavigate()
    const { rowData, courseId, courseLessonId, courseTitleLang, lessonTitleLang } = location?.state
    const initialType = rowData?.content[0]?.type || "";
    const initialUrl = rowData?.content[0]?.url?.[0] || "";

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imageUploading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const [s3Data, setS3Data] = useState({
        accessKey: "AKIA4GWLDQ2LFZGE32BJ",
        secretKey: "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",
        bucket: "familyguide",
        region: "ap-south-1",
    });

    const [createLoader, setCreateLoader] = useState(false);
    const [uploading, setUploading] = useState('');
    const [titleHeaderMr, setTitleHeaderMr] = useState(rowData.titleHeaderLang.mr || '');
    const [titleHeaderEn, setTitleHeaderEn] = useState(rowData.titleHeaderLang.en || '');
    const [titleEn, setTitleEn] = useState(rowData.titleLang?.en || '');
    const [titleMr, setTitleMr] = useState(rowData.titleLang?.mr || '');
    const [titleHn, setTitleHn] = useState(rowData.titleLang?.hin || '');
    const [topicImages, settopicImages] = useState(rowData?.imageURL ? rowData?.imageURL : '');
    const [topicVideos, settopicVideos] = useState(rowData?.videoURL ? rowData?.videoURL : '');
    const [duration, setDuration] = useState(rowData.duration || '');
    const [description, setDescription] = useState(rowData.descriptionLang?.en || '');
    const [descriptionMr, setDescriptionMr] = useState(rowData.descriptionLang?.mr || '');
    const [descriptionHn, setDescriptionHn] = useState(rowData.descriptionLang?.hin || '');
    const [shortdescription, setShortDescription] = useState(rowData?.shortDescriptionLang?.en);
    const [shortdescriptionMr, setShortDescriptionMr] = useState(rowData?.shortDescriptionLang?.mr);
    const [shortdescriptionHn, setShortDescriptionHn] = useState(rowData?.shortDescriptionLang?.hn);
    const [srno, setSrNo] = useState(rowData.srNo || '');
    const [selectedOption, setSelectedOption] = useState({ value: initialType, label: initialType });
    const [selectedFile, setSelectedFile] = useState(initialUrl);
    const [type, setType] = useState(rowData?.isLandscape ? 'Landscape' : 'Portrait');

    const [error, setErrors] = useState({});

    const [fileEventVideoImg, setFileEventVideoImg] = useState('');

    // for content type
    const options = [
        { value: 'pdf', label: 'PDF' },
        { value: 'doc', label: 'Docs' }
    ];




    // for upload images ,videos,files
    const uploadImage = async (e, directory) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            try {
                setUploading(directory);
                const uploadedFile = await ReactS3(file, s3Data, directory);

                switch (directory) {
                    case "topicImages":
                        settopicImages(uploadedFile);
                        break;
                    case "topicVideos":
                        settopicVideos(uploadedFile);
                        break;
                    case "topicFiles":
                        setSelectedFile(uploadedFile);
                        break;
                    default:
                        console.error("Invalid directory:", directory);
                        break;
                }

                e.target.value = null;
            } catch (error) {
                console.error("Error while uploading file", error);
            } finally {
                setUploading('');
            }
        }
    };

    // for remove images ,videos,files
    const remove = (directory) => {
        switch (directory) {
            case "topicImages":
                settopicImages(null);
                break;
            case "topicVideos":
                settopicVideos(null);
                break;
            case "topicFiles":
                setSelectedFile(null);
                break;
            default:
                console.error("Invalid directory:", directory);
                break;
        }
    };


    // for get  type
    const TypeOptions = [
        { value: true, label: 'Landscape' },
        { value: false, label: 'Portrait' }
    ];

    // for validation
    const createValidate = () => {
        let newErrors = {};
        if (!srno) {
            newErrors.srNo = "*Sr.No is required";
        }
        if (!titleEn) {
            newErrors.titleEn = "*Title in English is required";
        }
        if (!titleMr) {
            newErrors.titleMr = "*Title in Marathi is required";
        }
        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }
        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }
        if (!topicImages) {
            newErrors.topicImages = "*Please upload at least one image";
        }
        if (!topicVideos) {
            newErrors.topicVideos = "*Please upload at least one video";
        }
        if (!duration) {
            newErrors.duration = "*duration in min is required";
        }
        if (type.length === 0) {
            newErrors.type = "*At least Select one video type for topic";
        }
        if (!selectedOption) {
            newErrors.selectedOption = "*Please select at least one content Type"
        }
        // if (!selectedFile) {
        //     newErrors.selectedFile = "*Please select at least one FIle"
        // }
        if (!description.trim() || description.trim() === '<p><br></p>') {
            newErrors.description = "*Description in English is required";
        }
        if (!descriptionMr.trim() || descriptionMr.trim() === '<p><br></p>') {
            newErrors.descriptionMr = "*Description in Marathi is required";
        }

        if (!shortdescription.trim() || shortdescription.trim() === '<p><br></p>') {
            newErrors.shortdescription = "* Short Description in English is Required";
        }

        if (!shortdescriptionMr.trim() || shortdescriptionMr.trim() === '<p><br></p>') {
            newErrors.shortdescriptionMr = "* Short Description in Marathi is Required"
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    // for create course lesson model
    const editCourseTopic = async () => {
        const isValid = createValidate();

        if (isValid) {
            setCreateLoader(true);

            await fetch(process.env.REACT_APP_BASE_URL + '/courseTopic/updateCourseTopic', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    "courseTopicId": rowData?.courseTopicId,
                    "courseId": courseId,
                    "courseTitle": courseTitleLang?.mr,
                    "courseTitleLang": {
                        "mr": courseTitleLang?.mr ? courseTitleLang?.mr : '',
                        "hin": courseTitleLang?.hin ? courseTitleLang?.hin : '',
                        "en": courseTitleLang?.en ? courseTitleLang?.en : ''
                    },
                    "courseLessonId": courseLessonId,
                    "courseLessonTitle": lessonTitleLang?.mr,
                    "courseLessonTitleLang": {
                        "mr": lessonTitleLang?.mr ? lessonTitleLang?.mr : '',
                        "hin": lessonTitleLang?.hin ? lessonTitleLang?.hin : '',
                        "en": lessonTitleLang?.en ? lessonTitleLang?.en : ''
                    },
                    "title": titleMr,
                    "titleLang": { en: titleEn, mr: titleMr, hin: titleHn },
                    "titleHeader": titleHeaderMr,
                    "titleHeaderLang": { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderMr },
                    "description": descriptionMr,
                    "descriptionLang": { en: description, mr: descriptionMr, hin: descriptionHn },
                    "shortDescription": shortdescriptionMr,
                    "shortDescriptionLang": { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                    "srNo": srno,
                    "duration": duration,
                    "imageURL": topicImages,
                    "imageURLLang": { mr: topicImages, hin: '', en: topicImages },
                    "videoURL": topicVideos,
                    "videoURLLang": { mr: topicVideos, hin: '', en: topicVideos },
                    "content": [{ type: selectedOption?.value, url: selectedFile }],
                    "createdBy": userState?.data?.user?.name,
                    "isLandscape": type === 'Landscape'
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "Updated Successfully") {
                        toast.success("Updated Successfully");
                        navigate("/dashboard/coursetopic", { state: { courseId: response?.data?.courseId, courseLessonId: response?.data?.courseLessonId, courseTitleLang: response?.data?.courseTitleLang, lessonTitleLang: response?.data?.courseLessonTitleLang } })
                        setCreateLoader(false);
                    }

                })
                .catch((Err) => {
                    console.log("Error While Creating CourseLesson", Err);
                    setCreateLoader(false);
                })
        }
    }



    const handleReactS3UltraVideoImg = (url) => {
        if (url) {
            settopicImages(url);
        }
    }

    // const handleReactS3UltraVideo = (url) => {
    //     if (url) {
    //         let arr = [];
    //         arr = [...topicVideos];
    //         arr.push(url);
    //         settopicVideos(arr);
    //         setUploadingVideo(false);
    //     }
    //     setUploadingVideo(false);
    // }

    const uploadOnCloudFlare = async (data) => {
        settopicVideos(data?.preview);
    }



    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Edit Topic
                </h3>
                <Row className='mt-4'>

                    <Col md={6} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control value={courseTitleLang?.en} disabled />
                        </Form.Group>
                    </Col>

                    {courseTitleLang?.mr && <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title in Marathi</Form.Label>
                            <Form.Control value={courseTitleLang?.mr} disabled />
                        </Form.Group>
                    </Col>}

                    {courseTitleLang?.hin &&
                        <Col md={6} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Course Title in Hindi</Form.Label>
                                <Form.Control value={courseTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>}



                    <Col md={6} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lesson Title </Form.Label>
                            <Form.Control value={lessonTitleLang?.en} disabled />

                        </Form.Group>
                    </Col>

                    {lessonTitleLang?.mr &&
                        <Col md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Lesson Title in Marathi</Form.Label>
                                <Form.Control value={lessonTitleLang?.mr} disabled />
                            </Form.Group>
                        </Col>}

                    {lessonTitleLang?.hin &&
                        <Col md={6} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Lesson Title in Hindi</Form.Label>
                                <Form.Control value={lessonTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>}

                    <Col md={2}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lesson number<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Lesson number"
                                value={srno}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setSrNo(e.target.value);
                                    }
                                }}
                            />
                            {error.srNo && <p className="errMsg">{error.srNo}</p>}
                        </Form.Group>
                    </Col>

                    {/* <Col md={5}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="" value={title} onChange={(e) => {
                                if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitle(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitle(e.target.value);
                                }
                            }} />
                            {error.title && <p className="errMsg">{error.title}</p>}
                        </Form.Group>
                    </Col> */}

                    <Col md={5} >
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title" id="" value={titleEn} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitleEn(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleEn(e.target.value);
                                }
                            }} />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={5}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={5} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>


                    <Col md={5}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Topic Images<span>*</span></Form.Label> <br />
                            {
                                topicImages?.length === 0 ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />
                                            Upload Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => setFileEventVideoImg(event)}
                                        />
                                        <p id='imgNote'>Recommended image resolution - 1:1</p>
                                        {error.topicImages && <p className="errMsg">{error.topicImages}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <img src={topicImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => settopicImages("")} />

                                        </Card>
                                    </div>
                            }

                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Topic Videos<span>*</span></Form.Label> <br />
                            {!topicVideos ?
                                <>
                                    <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} />
                                    {error.topicVideos && <p className="errMsg">{error.topicVideos}</p>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="d-flex uploadedFile justify-content-center align-items-center">
                                        <a target="_blank" href={topicVideos}>
                                            <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                        </a>
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => settopicVideos('')} />
                                    </Card>
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Video Type<span>*</span></Form.Label>
                            <Select
                                value={{ value: rowData?.isLandscape, label: type }}
                                onChange={(option) => setType(option.label)}
                                options={TypeOptions}
                            />
                            {error.type && (
                                <p className="errMsg">{error.type}</p>
                            )}
                        </Form.Group>
                    </Col>


                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Duration (Min)<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter duration in Min" value={duration} onChange={(e) => {
                                if (e.target.value.match(/^[0-9]+$/)) {
                                    setDuration(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setDuration(e.target.value);
                                }
                            }} />
                            {error.duration && <p className="errMsg">{error.duration}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Content</Form.Label>
                            <Select
                                value={selectedOption}
                                onChange={(option) => {
                                    setSelectedOption(option); setSelectedFile(null);
                                }}
                                options={options}
                            />
                        </Form.Group>
                        {/* {error.selectedOption && <p className="errMsg">{error.selectedOption}</p>} */}
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Upload {selectedOption ? selectedOption?.label : 'File'}</Form.Label> <br />
                            <>
                                {
                                    uploading === "topicFiles" ? <Lottie options={defaultOptions} className='mx-auto' width={120} /> :
                                        <label htmlFor="topicFiles" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />
                                            Upload File
                                        </label>
                                }
                                <input
                                    type="file"
                                    accept={selectedOption ? `.${selectedOption.value}` : ''}
                                    id="topicFiles"
                                    style={{ display: "none" }}
                                    onChange={(event) => uploadImage(event, "topicFiles")}
                                    disabled={!selectedOption}
                                />
                                {error.selectedFile && <p className="errMsg">{error.selectedFile}</p>}
                                <br />
                                {selectedFile && (
                                    <div className="d-flex align-items-center">

                                        {selectedOption?.value === 'pdf' ? (
                                            <Card className="d-flex uploadedFile justify-content-center align-items-center m-1">
                                                <a href={selectedFile} target="_blank" className="me-2">
                                                    <Icon.FilePdf size={18} />
                                                </a>
                                                <Icon.XCircleFill className='removeIcon' size={18} onClick={() => remove("topicFiles")} />
                                            </Card>

                                        ) : (
                                            <Card className="d-flex uploadedFile justify-content-center align-items-center m-1">
                                                <a href={selectedFile} target="_blank" className="me-2">
                                                    <Icon.FileEarmarkText size={18} />
                                                </a>
                                                <Icon.XCircleFill className='removeIcon' size={18} onClick={() => remove("topicFiles")} />
                                            </Card>

                                        )}
                                        {/* <Icon.XCircleFill className='removeIcon' size={18} onClick={() => setSelectedFile(null)} /> */}
                                    </div>
                                )}
                            </>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescription}
                                onChange={(e) => setShortDescription(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} >
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionMr}
                                onChange={(e) => setShortDescriptionMr(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group className="mb-5">
                            <Form.Label>Short Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionHn}
                                onChange={(e) => setShortDescriptionHn(e)}
                            />
                            <br />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>


                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Full Description in English<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={description}
                                onChange={(itm) => { setDescription(itm) }}
                            />
                            <br />
                            <br />
                            {error.description && <p className="errMsg">{error.description}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='mb-3'>
                        <Form.Group >
                            <Form.Label>Full Description in Marathi<span className='errMsg'>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionMr}
                                onChange={(itm) => { setDescriptionMr(itm) }}
                            />
                            <br />
                            <br />
                            {error.descriptionMr && <p className="errMsg">{error.descriptionMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group >
                            <Form.Label>Full Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionHn}
                                onChange={(itm) => { setDescriptionHn(itm) }}
                            />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                                <Button className='primaryBtn mx-auto w-auto' onClick={() => editCourseTopic()}>
                                    <Icon.JournalPlus className='me-2' />Edit Topic
                                </Button>
                            </div>
                        </>

                }
            </Container>

            {
                fileEventVideoImg &&
                <ReactS3Ultra fileEvent={fileEventVideoImg} fileType={process.env.REACT_APP_IMAGE}
                    directory="topicImages" onFileUpload={handleReactS3UltraVideoImg} />
            }

            {/* {
                fileEventVideo &&
                <ReactS3Ultra fileEvent={fileEventVideo} fileType={process.env.REACT_APP_VIDEO}
                    directory="topicVideos" onFileUpload={handleReactS3UltraVideo} />
            } */}
        </div>
    )
}

export default EditTopic
