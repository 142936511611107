import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";

const ViewEventList = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const event = location.state.event;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Calendar2Week className="me-1" /> Event Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    English Name
                  </th>
                  <td>{event?.event_titleLang?.en ? event?.event_titleLang?.en : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Marathi Name
                  </th>
                  <td>{event?.event_titleLang?.mr ? event?.event_titleLang?.mr : "-"}</td>
                </tr>

                <tr className="off">
                  <th>
                    <Icon.Code className="me-2" />
                    Hindi Name
                  </th>
                  <td>{event?.event_titleLang?.hin ? event?.event_titleLang?.hin : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Video Link
                  </th>
                  <td>{event?.event_youtube_video ? event?.event_youtube_video : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Venue
                  </th>
                  <td>{event?.event_venue ? event?.event_venue : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    Type
                  </th>
                  <td>{event?.event_type ? event?.event_type : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Via
                  </th>
                  <td>{event?.event_via ? event?.event_via : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Start Date
                  </th>
                  <td>{event?.event_date ? moment(event.event_date).format('DD MMMM YYYY') : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    End Date
                  </th>
                  <td>{event?.event_end_date ? moment(event.event_end_date).format('DD MMMM YYYY') : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Start Time
                  </th>
                  <td>{event?.event_start_time ? event?.event_start_time : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    End Time
                  </th>
                  <td>{event?.event_end_time ? event?.event_end_time : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Is Registration Over
                  </th>
                  <td>{event?.is_registration_over === true ? "Yes" : "No" ? event?.is_registration_over === true ? "Yes" : "No" : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    Is Postpone
                  </th>
                  <td>{event?.isDatePostpone === true ? "Yes" : "No" ? event?.isDatePostpone === true ? "Yes" : "No" : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Start Time
                  </th>
                  <td>{event?.event_start_time ? event?.event_start_time : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    End Time
                  </th>
                  <td>{event?.event_end_time ? event?.event_end_time : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Id Paid
                  </th>
                  <td>{event?.is_paid === true ? "Yes" : "No" ? event?.is_paid === true ? "Yes" : "No" : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    Discount
                  </th>
                  <td>{event?.event_discount ? event?.event_discount : "0"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Address Line 1
                  </th>
                  <td>{event?.event_address ? event?.event_address : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    Address Line 2
                  </th>
                  <td>{event?.eevent_address2 ? event?.eevent_address2 : "-"}</td>
                </tr>

                {/* <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Lattitude
                  </th>
                  <td>{event?.event_latitude ? event?.event_latitude : "-"}</td>

                  <th>
                    <Icon.Code className="me-2" />
                    Logitude
                  </th>
                  <td>{event?.event_longitude ? event?.event_longitude : "-"}</td>
                </tr> */}

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td>
                    {event?.event_descriptioLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event?.event_descriptioLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td>
                    {event?.event_descriptioLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event?.event_descriptioLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Full Description in English
                  </th>
                  <td>
                    {event?.event_full_descriptionLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event?.event_full_descriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Full Description in Marathi
                  </th>
                  <td>
                    {event?.event_full_descriptionLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event?.event_full_descriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewEventList;
