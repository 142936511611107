import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllCategories } from "../../Common/Categories";
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';
import ReactS3Ultra from "../../../Utils/ReactS3Ultra";
import CloudeFlareVideoUpload from "../../../Utils/CloudeFlareVideoUpload";
window.Buffer = window.Buffer || require("buffer").Buffer;

const EditVideos = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state?.cmsLogin?.userData?.data?.user);
    const token = useSelector((state) => state?.cmsLogin?.userData?.data?.token);
    const getData = useSelector(state => state?.categories?.categoryList?.data)
    const location = useLocation();
    const videoData = location.state;


    const [allTeachers, setAllTeachers] = useState([]);
    const [author, setAuthor] = useState(userState?.fullName);
    //Add Content variables
    const [apiLoader, setApiLoader] = useState(false);
    // Error variables
    const [error, setErrors] = useState({});

    const [titleEn, setTitleEn] = useState(videoData.titleLang.en);
    const [titleMr, setTitleMr] = useState(videoData.titleLang.mr);
    const [titleHn, setTitleHn] = useState(videoData.titleLang.hin);

    const [descriptionEn, setDescriptionEn] = useState(videoData.descriptionLang.en);
    const [descriptionMr, setDescriptionMr] = useState(videoData.descriptionLang.mr);
    const [descriptionHn, setDescriptionHn] = useState(videoData.descriptionLang.hin);
    const [shortdescription, setShortDescription] = useState(videoData?.shortDescriptionLang?.en);
    const [shortdescriptionMr, setShortDescriptionMr] = useState(videoData?.shortDescriptionLang?.mr);
    const [shortdescriptionHn, setShortDescriptionHn] = useState(videoData?.shortDescriptionLang?.hn);
    const [tags, setTags] = useState(
        videoData?.tags?.map((tag) => ({ label: tag, value: tag })) || []
    );
    const [category, setCategory] = useState({ label: videoData?.contentCategory, value: videoData?.contentCategory })

    const [selectedCategory, setSelectedCategory] = useState(videoData?.tagsCategory?.map((itm) => ({
        label: itm,
        value: itm
    }))) || [];


    const [VideoImage, setVideoImage] = useState(videoData?.imgURL || []);
    const [Video, SetVideo] = useState(videoData?.videoURL || []);
    const [fileEventVideoImg, setFileEventVideoImg] = useState('');
    const [videotype, setVideoType] = useState({ label: videoData?.contentType, value: videoData?.contentType })
    const [type, setType] = useState(videoData.isLandscape ? 'Landscape' : 'Portrait');

    // Dropdown variables
    const [TagList, setTagList] = useState(videoData?.tags || []);
    const [tagCategoryList, setTagCategoriesList] = useState([]);


    useEffect(() => {
        getTagCategoryList();
        dispatch(getAllCategories({ payload: { categoryType: "feed" }, token: token }));
        getAllTeacher();

    }, []);
    const getAllTeacher = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTeacher', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({
                    courseTeacherId: '',
                    courseCategories: ''
                })
            })
            const response = await res.json();
            let arr = [];
            response.data.map((item) => {
                arr.push({
                    ...item,
                    label: item.firstName + " " + item.lastName,
                    value: item.courseTeacherId,
                });
            });
            setAllTeachers(arr);
        }
        catch (Err) {
            console.log("Err while getting all teacher", Err);
        }
    }
    const getContentCategory = getData?.data?.length > 0 && getData?.data !== null && getData?.data !== undefined ? getData.data.map(itm => ({ label: itm?.categoryName, value: itm?.categoryName })) : [];



    useEffect(() => {
        if (selectedCategory !== "") {
            getTagNameList();
        }
    }, [selectedCategory]);

    const handleChange = (selected) => {
        setTags(selected);
    };


    // for get  type
    const TypeOptions = [
        { value: true, label: 'Landscape' },
        { value: false, label: 'Portrait' }
    ];


    // for tagcategorylist
    const getTagCategoryList = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTagCat", {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.status === 200) {
                    let arr = [];
                    response.data.map((item) => {
                        arr.push({
                            ...item,
                            label: item.tagCategoryName,
                            value: item.tagCategoryName,
                        });
                    });
                    setTagCategoriesList(arr);
                } else {
                    setTagCategoriesList([]);
                }
            }).catch((error) => {
                console.log("Error:", error)
            })
    };
    const getTagNameList = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + `/cms/getAllTags?tagCatId=${selectedCategory?.tagCategoryId}`,
            {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            }
        )
            .then((res) => res.json())
            .then((response) => {
                if (response?.status === 200) {
                    let arr = [];
                    response.data.map((item) => {
                        arr.push({
                            ...item,
                            label: item.tag,
                            value: item.tag,
                        });
                    });
                    setTagList(arr);
                } else {
                    setTagList([]);
                }
            }).catch((error) => {
                console.log("Error:", error)
            })
    };

    // for validation
    const validate = () => {
        let newErrors = {};

        if (!titleEn) {
            newErrors.titleEn = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*मराठीत शीर्षक आवश्यक आहे";
        }

        //  if (!titleHn) {
        //     newErrors.titleHn = "*शीर्षक हिन्दी में आवश्यक है";
        // }

        if (!VideoImage) {
            newErrors.VideoImage = "*Please upload at least one image";
        }

        if (!Video) {
            newErrors.Video = "*Please upload at least one video";
        }

        if (!category) {
            newErrors.category = "*Please select content category";
        }

        // else if (!author) {
        //     newErrors.author = "*Please select content author";
        // }

        if (!selectedCategory) {
            newErrors.selectedCategory = "*Please Select Tag category";
        }

        if (tags.length === 0) {
            newErrors.tags = "*At least Select one Tag for Content";
        }

        if (type.length === 0) {
            newErrors.type = "*At least Select one video type for Content";
        }

        if (!descriptionEn.trim() || descriptionEn.trim() === '<p><br></p>') {
            newErrors.descriptionEn = "*Description in english is Required";
        }

        if (!descriptionMr.trim() || descriptionMr.trim() === '<p><br></p>') {
            newErrors.descriptionMr = "*Description in marathi is Required";
        }

        //  if (!descriptionHn) {
        //     newErrors.descriptionMr = "*Description in hindi is Required";
        // }

        if (!shortdescription.trim() || shortdescription.trim() === '<p><br></p>') {
            newErrors.shortdescription = "* Short Description is Required";
        }

        if (!shortdescriptionMr.trim() || shortdescriptionMr.trim() === '<p><br></p>') {
            newErrors.shortdescriptionMr = "* Short Description in Marathi is Required"
        }

        setErrors(newErrors);
        if (newErrors) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    };

    // for add video
    const EditVideo = async () => {
        const isvalid = validate();
        if (isvalid) {
            setApiLoader(true);
            let payload = {
                contentId: videoData?.contentId,
                contentType: videotype?.value,
                title: titleMr,
                titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                description: descriptionMr,
                descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
                shortDescription: shortdescriptionMr,
                shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                imgURL: VideoImage,
                imgURLLang: { en: VideoImage, mr: VideoImage, hin: "" },
                videoURL: Video,
                videoURLLang: { en: Video, mr: Video, hin: "" },
                categories: selectedCategory,
                courseTeacherId: author,
                priorityNo: 100,
                tags: tags.map((item) => item.label),
                isHome: true,
                contentCategory: category?.label,
                tagsCategory: selectedCategory?.label,
                createdById: userState.name,
                isLandscape: type === 'Landscape'
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/content/updateContent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(payload),
                });

                if (response) {
                    const responseData = await response.json();
                    if (responseData.message === 'Updated Successfully') {
                        toast.success('Updated Successfully');
                        navigate("/dashboard/content-list");
                        setApiLoader(false);

                    }
                    else if (responseData.message === "Content already exist") {
                        toast.error('Content already exist');
                        setApiLoader(false);
                    }
                    else {
                        throw new Error(responseData.message || 'Failed to update content');
                        setApiLoader(false);
                    }
                } else {
                    throw new Error('Network response was not ok.');
                    setApiLoader(false);
                }
            } catch (error) {
                console.error("Error while creating content:", error);
                setApiLoader(false);
            }
        }
    }

    const handleReactS3UltraVideoImg = (url) => {
        if (url) {
            setVideoImage(url);
        }
    }

    const uploadOnCloudFlare = async (data) => {
        SetVideo(data?.preview);
    }

    // for remove images ,videos,files
    const remove = (directory) => {
        switch (directory) {
            case "VideoImage":
                setVideoImage(null);
                break;
            case "video":
                SetVideo(null);
                break;
            default:
                console.error("Invalid directory:", directory);
                break;
        }
    };


    //React Select
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };



    return (
        <div className="AddVideo outletPadding">
            <ToastContainer />
            <Container>

                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center'>
                    <Icon.PlayBtn size={24} className='me-2' />Update Video
                </h3>


                <Row className="mt-4" >
                    <Col md={6} >
                        <Form.Group className="mb-3">
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control
                                id="titleEn"
                                placeholder="Enter Title"
                                value={titleEn}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control
                                id="titleMr"
                                placeholder="शीर्षक प्रविष्ट करा"
                                value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }}

                            />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className="off">
                        <Form.Group className="mb-3">
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control
                                id="titleHn"
                                placeholder="शीर्षक दर्ज करें"
                                value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Topic Image<span>*</span></Form.Label> <br />
                            {!VideoImage ?
                                <>
                                    <label htmlFor="fileInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />
                                        Upload Image
                                    </label>

                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            setFileEventVideoImg(event);
                                        }} />
                                    <p id='imgNote'>Recommended image resolution - 240*240 px</p>
                                    {error.VideoImage && <p className="errMsg">{error.VideoImage}</p>}
                                </>
                                :

                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile my-1 me-3">
                                        <img src={VideoImage} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => remove("VideoImage")} />
                                    </Card>
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Topic Video<span>*</span></Form.Label> <br />
                            {
                                !Video ?
                                    <>
                                        <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} />
                                        {error.Video && <p className="errMsg">{error.Video}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                            <a target="_blank" href={Video}>
                                                <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                            </a>
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => remove("video")} />
                                        </Card>
                                    </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Feed Category<span>*</span></Form.Label>
                            <Select
                                id="category"
                                value={category}
                                options={getContentCategory}
                                onChange={(option) => setCategory(option)}
                            />
                            {error.category && <p className="errMsg">{error.category}</p>}
                        </Form.Group>
                    </Col>
                    {/*

                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Author<span>*</span></Form.Label>
                            <Select
                                id="author"
                                isMulti={false}
                                options={allTeachers}
                                value={author}
                                onChange={(option) => {
                                    setAuthor(option);
                                }}
                            />
                            {error.author && (
                                <div className="errMsg">{error.author}</div>
                            )}
                        </Form.Group>
                    </Col> */}


                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tag Category<span>*</span></Form.Label>
                            <Select
                                id="selectedCategory"
                                isMulti={false}
                                options={tagCategoryList}
                                value={selectedCategory}
                                onChange={(option) => {
                                    setSelectedCategory(option);
                                    setTags([])
                                }}
                            />
                            {error.selectedCategory && (
                                <p className="errMsg">{error.selectedCategory}</p>
                            )}
                        </Form.Group>
                    </Col>


                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Add Tags Name<span>*</span></Form.Label>
                            <ReactSelect
                                id="tags"
                                isDisabled={selectedCategory === ""}
                                options={TagList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={handleChange}
                                allowSelectAll={true}
                                value={tags}
                            />
                            {error.tags && <p className="errMsg">{error.tags}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Video Type<span>*</span></Form.Label>
                            <Select
                                value={{ value: videoData?.isLandscape, label: type }}
                                onChange={(option) => setType(option.label)}
                                options={TypeOptions}
                            />
                            {error.type && (
                                <p className="errMsg">{error.type}</p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescription}
                                onChange={(e) => setShortDescription(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescription && (<p className="error">{error.shortdescription}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} >
                        <Form.Group className="mb-3">
                            <Form.Label>Short Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionMr}
                                onChange={(e) => setShortDescriptionMr(e)}
                            />
                            <br />
                            <br />
                            <br />
                            {error.shortdescriptionMr && (<p className="error">{error.shortdescriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className='off'>
                        <Form.Group className="mb-5">
                            <Form.Label>Short Description in Hindi</Form.Label>
                            <ReactQuill
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={shortdescriptionHn}
                                onChange={(e) => setShortDescriptionHn(e)}
                            />
                            <br />
                            <br />
                            <br />
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Description in English<span>*</span></Form.Label>
                            <ReactQuill
                                id="descriptionEn"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionEn}
                                onChange={setDescriptionEn}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.descriptionEn && (
                                <p className="errMsg">{error.descriptionEn}</p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Description in Marathi<span>*</span></Form.Label>
                            <ReactQuill
                                id="descriptionMr"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionMr}
                                onChange={setDescriptionMr}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.descriptionMr && (<p className="errMsg">{error.descriptionMr}</p>)}
                        </Form.Group>
                    </Col>

                    <Col md={12} className="off">
                        <Form.Group className="mb-3">
                            <Form.Label>Full Description in Hindi</Form.Label>
                            <ReactQuill
                                id="descriptionHn"
                                style={{ height: 150 }}
                                theme="snow"
                                modules={quillModules}
                                formats={quillFormats}
                                value={descriptionHn}
                                onChange={setDescriptionHn}
                            />
                            <br />
                            <br />
                            <br className='d-block d-md-none' />
                            <br className='d-block d-md-none' />
                            {error.descriptionHn && (<p className="errMsg">{error.descriptionHn}</p>)}
                        </Form.Group>
                    </Col>

                </Row>

                {apiLoader ? (
                    <Spinner variant="primary" />
                ) : (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: '4%' }}>
                            <Button className="primaryBtn my-4 mx-auto" onClick={EditVideo}>
                                <Icon.PlusCircle className="me-1" />
                                Edit Video
                            </Button>
                        </div>
                    </>
                )}
            </Container>

            {
                fileEventVideoImg &&
                <ReactS3Ultra fileEvent={fileEventVideoImg} fileType={process.env.REACT_APP_IMAGE}
                    directory="VideoImage" onFileUpload={handleReactS3UltraVideoImg} />
            }

        </div>
    );

}



export default EditVideos;




