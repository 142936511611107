import React from "react";
import {
  Container,
  Row,
  Button,

} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const ViewArticle = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const article = location.state.article;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.PostcardHeartFill className="me-1" /> Article Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in English
                  </th>
                  <td>{article?.title ? article?.title : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in Marathi
                  </th>
                  <td>{article?.titleLang?.mr ? article?.titleLang?.mr : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Source
                  </th>
                  <td>{article?.source ? article?.source : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Tag Category
                  </th>
                  <td>{article?.tagsCategory ? article?.tagsCategory : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Feed Categories
                  </th>
                  <td>{article?.contentCategory ? article?.contentCategory : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Type
                  </th>
                  <td>{article?.type !== "undefined" && article?.type !== "null" ? article?.type : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Image URL
                  </th>
                  <td>{article?.imageURL ? article?.imageURL : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Shopee Data
                  </th>
                  <td>
                    {article?.shoppee?.length > 0 && article?.shoppee !== null && article?.shoppee !== undefined
                      ? article?.shoppee?.toString()
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Content Urls
                  </th>
                  <td>
                    {article?.contentURL && article?.contentURL.length > 0
                      ? article?.contentURL.toString()
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Tags
                  </th>
                  <td>
                    {article?.tags.length > 0 ? article?.tags.toString() : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td>
                    {article?.shortDescription ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: article?.shortDescriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td>
                    {article?.shortDescription ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: article?.shortDescriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>


                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Description in English
                  </th>
                  <td>
                    {article?.description ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: article?.descriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Description in Marathi
                  </th>
                  <td>
                    {article?.description ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: article?.descriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewArticle;
